// NOTE: This component uses the react-tooltip library. More information can be found at: https://github.com/wwayne/react-tooltip

import React, { Component } from "react"
import PropTypes from "prop-types"

import ReactTooltip from "react-tooltip"

class Tooltip extends Component {
  static propTypes = {
    content: PropTypes.func.isRequired,

    position: PropTypes.string
  }

  static defaultProps = {
    position: "right"
  }

  render() {
    const { content, position } = this.props

    return (
      <div className={"tooltip"}>
        <p className={"tooltip__icon"} data-tip={""} data-event={"click"}>
          ?
        </p>

        <ReactTooltip
          effect={"solid"}
          getContent={content}
          globalEventOff={"click"}
          place={position}
          type={"info"}
        />
      </div>
    )
  }
} // class Tooltip

export default Tooltip
