import React, { Component } from "react"
import PropTypes from "prop-types"
import {
  SERVICE_COMPLETED,
  SERVICE_APPROVED,
  SERVICE_AWAITING_APPROVAL,
  SERVICE_INITIATED,
  SERVICE_REJECTED,
  SERVICE_DELETED
} from "../../constants/statuses"
import moment from "moment"
import * as numberHelpers from "../../helpers/numberHelpers"
import { Table } from "semantic-ui-react"
import { Date_Format } from "../../constants/years"
import { isFleetMaintenanceHubCanada } from "../../helpers/affiliationHelpers"
import { withTranslation } from "react-i18next"

class MaintenanceHistoryRecordSummary extends Component {
  static propTypes = {
    record: PropTypes.object.isRequired
  }

  formattedDate(language) {
    const {
      record: { appointment_datetime = "" }
    } = this.props

    return isFleetMaintenanceHubCanada()
      ? moment(appointment_datetime).format(Date_Format.YYYY_MM_DD)
      : moment(appointment_datetime).format(Date_Format.MM_DD_YYYY)
  }

  formattedShopInfo() {
    const { shop, custom_shop_name } = this.props.record
    if (custom_shop_name) return custom_shop_name
    if (!shop) return ""

    return `${shop.name} ${shop.city}, ${shop.state}`
  }

  savedServices() {
    return this.props.record.order_services.filter(
      (os) => os.status === SERVICE_REJECTED || os.status === SERVICE_DELETED
    )
  }

  completedServices() {
    return this.props.record.order_services.filter((os) => {
      return (
        [SERVICE_APPROVED, SERVICE_AWAITING_APPROVAL, SERVICE_INITIATED, SERVICE_COMPLETED].indexOf(
          os.status
        ) !== -1
      )
    })
  }

  render() {
    const {
      vehicle,
      record,
      t,
      currentUser: { language = "" }
    } = this.props

    const completed = this.completedServices()
    const saved = this.savedServices()
    const total = completed
      .reduce((acc, r) => (acc += parseFloat(r.total ? r.total : 0)), 0)
      .toFixed(2)
    const isFleetShellCanada = isFleetMaintenanceHubCanada()

    return (
      <Table.Row>
        <Table.Cell style={{ width: "11%" }}>{this.formattedDate(language)}</Table.Cell>
        <Table.Cell singeLine>{this.formattedShopInfo()}</Table.Cell>
        <Table.Cell>
          {isFleetShellCanada
            ? numberHelpers.formatWithCommas(completed[0].service_kilometers)
            : numberHelpers.formatWithCommas(completed[0].service_miles)}
        </Table.Cell>
        <Table.Cell>{completed.map((service) => service.name).join(", ")}</Table.Cell>
        <Table.Cell>{saved.map((service) => service.name).join(", ")}</Table.Cell>
        <Table.Cell>{isFleetShellCanada ? `$${total} CAD` : `$${total}`}</Table.Cell>
      </Table.Row>
    )
  }
} // class MaintenanceHistoryRecord

export default withTranslation("common")(MaintenanceHistoryRecordSummary)
