export function agGridColumnSubsciptionDefs(
  defaultCellRenderer,
  viewPaymentMethodRenderer,
  t,
  subscriptionActivities
) {
  const widths = {
    verySmall: 125,
    small: 150,
    medium: 175,
    large: 200,
    veryLarge: 225
  }
  var fields = [
    {
      headerName: t("services:fleetName"),
      field: "fleet_name",
      width: widths.veryLarge,
      cellRendererFramework: defaultCellRenderer
    },
    {
      headerName: t("services:subscriptions"),
      field: "subscription",
      width: widths.veryLarge,
      cellRendererFramework: defaultCellRenderer
    },
    {
      headerName: t("services:subscriptionDate"),
      field: "subscription_date",
      width: widths.veryLarge,
      cellRendererFramework: defaultCellRenderer
    },
    {
      headerName: t("services:methodOfPayment"),
      field: "method_of_payment",
      width: widths.veryLarge,
      cellRendererFramework: viewPaymentMethodRenderer
    },
    {
      headerName: t("services:numberOfVehicles"),
      field: "number_of_vehicles",
      width: widths.veryLarge,
      cellRendererFramework: defaultCellRenderer
    },
    {
      headerName: t("services:amount"),
      field: "amount",
      width: widths.veryLarge,
      cellRendererFramework: defaultCellRenderer
    }
  ]

  return fields
}

export function agGridSubscriptionRowData(subscriptionActivities, language) {
  return subscriptionActivities.map((subscriptionActivity) => {
    return {
      fleet_name: subscriptionActivity.fleet_name,
      subscription: subscriptionActivity.number_of_vehicles || 0,
      subscription_date: subscriptionActivity.subscription_date,
      method_of_payment: subscriptionActivity.id,
      number_of_vehicles: subscriptionActivity.number_of_vehicles || 0,
      amount: subscriptionActivity.amount || 0.0
    }
  })
}

export const NA = "N/A"
