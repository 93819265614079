import React from "react"
import { connect } from "react-redux"
import { withTranslation } from "react-i18next"
import { Form, Input } from "semantic-ui-react"
import LoadingThrobber from "../shared/LoadingThrobber"
import { isEmpty, isNull } from "lodash"
import ReactSVG from "react-svg"
import { getStyles } from "../shared/StylesheetInjector"
import CustomNotification from "../shared/CustomNotification"
import { NOTIFICATION_TYPE } from "../../constants/notification"
import LoadingError from "../shared/LoadingError"

const styles = getStyles()

class WexPaymentList extends React.Component {
  componentDidMount() {
    const { dispatch, currentFleet } = this.props
    dispatch({
      type: "WEX_ACCOUNT_NUMBER_LOAD_SAGA",
      payload: { fleetId: currentFleet.id }
    })
  }

  constructor(props) {
    super(props)

    this.state = {
      formData: {
        wexAccountNumber: {
          name: "Wex Account Number",
          value: "",
          invalid: false,
          validators: ["_isPresent", "_isValidWEXAccountNumber"]
        }
      },
      isEditable: false
    }
  }

  onSubmitWexAccountNumber = async () => {
    this.setState({ isSubmitting: true })
    const { currentUser, dispatch, t, wexPaymentMethod } = this.props
    const { formData } = this.state
    const { fleet_id } = currentUser
    if (!this.wexAccountNumberUnchanged()) {
      this._validateFields(async () => {
        const response = await dispatch({
          type: "WEX_ACCOUNT_NUMBER_UPDATE_SAGA",
          payload: {
            formData: formData,
            fleetId: fleet_id,
            isNew: isNull(wexPaymentMethod),
            t: t
          },
          callback: this.afterRequest.bind(this)
        })
        this.setState({ isEditable: false })
      })
    } else {
      this.setState({ isEditable: false })
    }
  }

  afterRequest(status, data) {
    this.setState({ isSubmitting: false, isEditable: false })
    if (status === NOTIFICATION_TYPE.SUCCESS) {
      this.onRequestSuccess(data)
    } else {
      this.onRequestFailure(data)
    }
  }

  onRequestFailure(data) {
    const { type, title, message } = data
    const { t } = this.props
    return CustomNotification(type, title, message, t)
  }

  onRequestSuccess(data) {
    const { type, title, message } = data
    const { t } = this.props
    return CustomNotification(type, title, message, t)
  }

  onEditing = () => {
    const { wexPaymentMethod } = this.props
    this.setState({
      formData: {
        wexAccountNumber: {
          value: wexPaymentMethod || ""
        }
      },
      isEditable: true
    })
  }

  wexAccountNumberUnchanged = () => {
    const { wexPaymentMethod } = this.props
    const { formData } = this.state
    const { wexAccountNumber } = formData

    return wexAccountNumber.value === wexPaymentMethod
  }

  _isPresent = (value) => {
    return !!value
  }

  _isValidNumber = (value) => {
    return !!value
  }

  _isValidWEXAccountNumber = (value) => {
    return value && value.length == 13 ? true : false
  }

  _onNumberChange = (key, value) => {
    const numericRegex = /^\d*$/

    const isOnlyNumeric = numericRegex.test(value)

    if (isOnlyNumeric) {
      this.setState({
        formData: {
          ...this.state.formData,
          [key]: this._setAndValidateField(key, value)
        }
      })
    }
  }

  _setAndValidateField = (key, value) => {
    let field = this.state.formData[key]
    let validators = field.validators || []
    let invalid = validators.some((validator) => !this[validator](value))
    return { ...field, value, invalid }
  }

  _validateWexAccountNumber = () => {
    const { formData } = this.state
    const { t } = this.props
    const wexAccountNumber = formData.wexAccountNumber.value
    if (isEmpty(wexAccountNumber)) {
      CustomNotification(
        NOTIFICATION_TYPE.DANGER,
        t("errorLabel"),
        `${t("errorMessage")} ${t(`wexAccountNumberFieldKey`)}`,
        t
      )
      return false
    }
    if (!this._isValidWEXAccountNumber(wexAccountNumber)) {
      CustomNotification(NOTIFICATION_TYPE.DANGER, t("errorLabel"), t(`invalidWexAccountNumber`), t)
      return false
    }
    return true
  }

  _validateFields = (callback) => {
    if (!this._validateWexAccountNumber()) return
    let formData = {}
    let firstInvalidKey = null
    const { t } = this.props

    Object.keys(this.state.formData).forEach((key) => {
      let field = this.state.formData[key]
      formData[key] = field = this._setAndValidateField(key, field.value)
      if (!firstInvalidKey && field.invalid) firstInvalidKey = key
    })
    this.setState({ formData }, () => {
      if (firstInvalidKey)
        CustomNotification(
          NOTIFICATION_TYPE.DANGER,
          t("errorLabel"),
          `${t("errorMessage")} ${t(`${firstInvalidKey}FieldKey`)}`,
          t
        )
      else if (callback) callback()
    })
  }

  onCancel = () => {
    const { wexPaymentMethod } = this.props
    this.setState({
      isEditable: false,
      formData: {
        wexAccountNumber: {
          value: wexPaymentMethod
        }
      }
    })
  }

  wexPaymentDetails = () => {
    const { t, wexPaymentMethod, fleet } = this.props
    const { formData, isEditable } = this.state
    const { wexAccountNumber } = formData
    const svgStyle = { width: 37, height: 37, color: styles.primaryColor }
    return (
      <div>
        <Form>
          <label style={{ width: "inherit", fontWeight: 600, color: "black" }}>
            {t("wexAccountNumberLable")}
          </label>
          <Form.Group inline>
            <Form.Field width={4}>
              {!isEditable ? (
                <Input
                  placeholder={t("wexAccountNumberPlaceholder")}
                  value={wexAccountNumber.value || wexPaymentMethod}
                  onClick={() => this.onEditing()}
                />
              ) : (
                <Input
                  onChange={(value) =>
                    this._onNumberChange("wexAccountNumber", value.target.value.replace(/[^0-9]/g))
                  }
                  placeholder={t("wexAccountNumberPlaceholder")}
                  value={wexAccountNumber.value}
                />
              )}
            </Form.Field>
            {!isEditable ? (
              <span onClick={() => this.onEditing()}>
                <ReactSVG svgStyle={svgStyle} src={`images/edit_circle.svg`} />
              </span>
            ) : (
              <>
                <span onClick={() => this.onSubmitWexAccountNumber()}>
                  <ReactSVG svgStyle={svgStyle} src={`images/check_circle.svg`} />
                </span>
                &nbsp; &nbsp;
                <span onClick={() => this.onCancel()}>
                  <ReactSVG svgStyle={svgStyle} src={`images/cancel_circle.svg`} />
                </span>
              </>
            )}
          </Form.Group>
        </Form>
      </div>
    )
  }
  render() {
    const { t, wexPaymentMethod, isLoading, isLoadingError } = this.props
    return (
      <>
        <LoadingThrobber visible={isLoading} noMargin />
        <LoadingError visible={!isLoading && isLoadingError} />
        {!isLoading && (
          <>
            {wexPaymentMethod ? (
              <p>
                <strong>{t("wexPaymentCardLabel")}</strong>
              </p>
            ) : (
              <p>
                <strong>{t("noWexPaymentMethod")}</strong>
              </p>
            )}
            {this.wexPaymentDetails()}
          </>
        )}
      </>
    )
  }
}

const mapStateToProps = (state) => {
  const currentUser = state.users.currentUser || {}
  const fleet = state.application.fleetSessionData || state.fleets.fleet
  return {
    isLoading: state.paymentMethods.isWEXLoading,
    wexPaymentMethod: state.paymentMethods.wexPaymentMethod,
    hasPaymentMethod: currentUser.has_payment_method,
    fleetPaymentProviders: currentUser.fleet_payment_providers,
    fleet: fleet,
    isLoadingError: state.application.isLoadingError
  }
}

export default connect(mapStateToProps)(withTranslation("payment")(WexPaymentList))
