import React, { Component, useState, useEffect } from "react"
import { Link } from "react-router-dom"
import { navigationPaths } from "../../constants/paths"
import * as agGridHelpers from "../../helpers/agGridHelpers"
import { Input, Grid, Button, Checkbox } from "semantic-ui-react"
import { useTranslation } from "react-i18next"
import { INITIATED_BY_USER, ACTIVE, APPROVAL, AWAITING_APPROVAL } from "../../constants/statuses"

export default function ServiceSearch({
  debouncedOnFilterChange,
  resetFiltering,
  orderFilter = "",
  isActivePage,
  handlechange
}) {
  const [orderStatuses, setOrderStatuses] = useState({
    isActiveOrderChecked: false,
    isApprovalOrderChecked: false,
    isApprovedOrderChecked: false
  })

  const { t } = useTranslation("services")

  useEffect(() => {
    if (orderFilter.includes(ACTIVE) || orderFilter.includes(INITIATED_BY_USER)) {
      setOrderStatuses({ isActiveOrderChecked: true })
    } else if (orderFilter.includes(AWAITING_APPROVAL)) {
      setOrderStatuses({ isApprovalOrderChecked: true })
    } else if (orderFilter.includes(APPROVAL)) {
      setOrderStatuses({ isApprovedOrderChecked: true })
    }
  }, [])

  return (
    <Grid columns="equal" className={"ag-theme-search"} stackable>
      <Grid.Column textAlign="left">
        <Input
          className="search"
          onChange={debouncedOnFilterChange}
          icon="search"
          placeholder={t("searchServicesLabel")}
        />
        &nbsp; &nbsp;
        {isActivePage && (
          <>
            &nbsp;
            <Checkbox
              label={t("Dashboard:Active Orders")}
              checked={orderStatuses.isActiveOrderChecked}
              onChange={(e, value) =>
                handlechange(e, [INITIATED_BY_USER, ACTIVE], setOrderStatuses, value)
              }
            />
            &nbsp;
            <Checkbox
              label={t("Dashboard:Needs Approval")}
              checked={orderStatuses.isApprovalOrderChecked}
              onChange={(e, value) => handlechange(e, [AWAITING_APPROVAL], setOrderStatuses, value)}
            />
            &nbsp;
            <Checkbox
              label={t("Dashboard:Approved")}
              checked={orderStatuses.isApprovedOrderChecked}
              onChange={(e, value) => handlechange(e, [APPROVAL], setOrderStatuses, value)}
            />
          </>
        )}
      </Grid.Column>
      <Grid.Column textAlign="right">
        {
          <Button className="white-button" onClick={() => resetFiltering(setOrderStatuses)}>
            {t("resetFiltersLabel")}
          </Button>
        }
      </Grid.Column>
    </Grid>
  )
}
