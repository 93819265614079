export const newUserTemplate = {
  id: null,
  customerNumber: null,
  firstName: "",
  lastName: "",
  name: "",
  email: "",
  cellPhone: "",
  fleet_id: null,
  notifications: [],
  payment_id: null,
  roles: [],
  vehicles: []
}

export const AUTO_APPROVE_USER_ID = 999999999999
export const ALL_VEHICLES_ID = 999999999999
export const autoApproveUser = {
  id: AUTO_APPROVE_USER_ID,
  label: "Auto-Approve",
  firstName: "Auto-Approve",
  lastName: ""
}

export const allVehiclesVehicle = {
  id: ALL_VEHICLES_ID,
  label: "allVehiclesLabel",
  make: "All",
  model: "Vehicles",
  year: ""
}

export const EN_LOCALE = "en"
export const FR_LOCALE = "fr"
export const CANDA_COUNTRY_CODE = "ca"
export const US_COUNTRY_CODE = "us"
export const PR_COUNTRY_CODE = "pr"
export const EN_CANDA_LOCALE = "en-CA"
export const FR_CANDA_LOCALE = "fr-CA"

export const BRAINTREE_FR_CANADA_LOCALE = "fr_CA"
export const BRAINTREE_EN_US_LOCALE = "en_US"

export const OBJECT = "object"
