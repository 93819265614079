import React, { Component } from "react"
import { connect } from "react-redux"
import {
  Button,
  Divider,
  Grid,
  Header,
  Icon,
  Image,
  Label,
  Loader,
  Modal,
  Segment
} from "semantic-ui-react"
import { withTranslation } from "react-i18next"
import GaugeChart from "react-gauge-chart"
import { isEmpty } from "lodash"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { IconAlertOctagon, IconBatteryCharging, IconCheck } from "@tabler/icons"
import TopViewCar from "../../assets/images/Img_top view Car.png"
import NormalTirePressure from "../../assets/images/Ic_Tyre normal pressure.png"
import LowHighTirePressure from "../../assets/images/Ic_Tyre Low-high pressure.png"
import Carousel, { CarouselItem } from "../shared/Carousel"
import { isFleetMaintenanceHubCanada } from "../../helpers/affiliationHelpers"
import ReactSVG from "react-svg"
import EngineSVG from "../../assets/images/engine.svg"
import moment from "moment"
import { Date_Format } from "../../constants/years"
import * as numberHelpers from "../../helpers/numberHelpers"
import { checkForActiveOrder } from "../../helpers/serviceHelpers"
import { Link } from "react-router-dom"
import { navigationPaths } from "../../constants/paths"

const EMPTY_DISPLAY = "- -"

const ReactGauge = ({ nrOfLevels, arcsLength }) => {
  return (
    <GaugeChart
      nrOfLevels={nrOfLevels}
      animate={false}
      textColor="black"
      colors={["#DBDBDB", "#C0C0C0", "#9A9A9A"]}
      percent={0}
      arcPadding={0}
      cornerRadius={0}
      formatTextValue={(value) => EMPTY_DISPLAY}
      needleBaseColor="none"
      needleColor="none"
      arcsLength={arcsLength}
    />
  )
}

const VerticleDivider = () => {
  return (
    <span
      style={{
        marginRight: "5px",
        marginLeft: "5px",
        height: "40px",
        borderLeft: "1px solid #DCDCDC"
      }}
    ></span>
  )
}

const DisplayEmpty = () => {
  return <div style={{ width: "50px", textAlign: "center" }}>{EMPTY_DISPLAY}</div>
}
class GeotabInfo extends Component {
  constructor(props) {
    super(props)
    this.state = {
      openFaultCodesModal: false
    }
  }

  showAllFaultCodes = (faultCodesData) => {
    const { openFaultCodesModal } = this.state
    const { t, vehicle, faultCodeService } = this.props
    return (
      <Modal
        size="tiny"
        closeIcon
        open={openFaultCodesModal}
        onClose={() => this.setState({ openFaultCodesModal: false })}
        onOpen={() => this.setState({ openFaultCodesModal: true })}
        trigger={<a style={{ cursor: "pointer" }}>{t("seeAllLbl")}</a>}
        closeOnDimmerClick={false}
      >
        <Modal.Header>
          <Header textAlign="center" style={{ fontSize: "20px" }}>
            {t("faultCodesLbl")}
            {!isEmpty(faultCodesData) && (
              <span style={{ margin: "0 5px" }}>({faultCodesData.length})</span>
            )}
          </Header>
        </Modal.Header>
        <Modal.Content scrolling>
          {faultCodesData &&
            faultCodesData.map((code, index) => {
              const { id, dateTime, diagnostic } = code
              const { name } = diagnostic
              return (
                <div key={index} className="fault-codes-list">
                  <Segment>
                    <div className="fault-codes-header">
                      <div className="fault-codes-item">
                        <span style={{ width: 32 }}>
                          {" "}
                          <ReactSVG src={EngineSVG} />{" "}
                        </span>
                        <span>{id.toUpperCase()}</span>
                      </div>
                      <div className="fault-codes-interval">
                        <span>{moment(dateTime).format(Date_Format.MM_DD_YY)} </span>
                      </div>
                    </div>
                    <p className="fault-codes-content">{name}</p>
                  </Segment>
                </div>
              )
            })}
        </Modal.Content>
        <Modal.Actions>
          <div>
            <Link
              onClick={(event) => checkForActiveOrder({ vehicle, event, t })}
              to={{
                pathname: navigationPaths.serviceRequestNew(),
                state: {
                  previousFormData: {
                    order_services: faultCodeService && faultCodeService,
                    vehicle_id: vehicle && vehicle.id,
                    vehicle: vehicle
                  }
                }
              }}
            >
              <Button fluid className="fault-codes-modal-button">
                {t("common:scheduleServiceLabel")}
              </Button>
            </Link>
          </div>
        </Modal.Actions>
      </Modal>
    )
  }
  fetchBatteryPercentages = (voltage) => {
    let batteryPercentage
    if (voltage) {
      if (voltage <= 10.5) {
        batteryPercentage = 0
      } else if (voltage > 10.5 && voltage <= 11.31) {
        batteryPercentage = 10
      } else if (voltage > 11.31 && voltage <= 11.58) {
        batteryPercentage = 20
      } else if (voltage > 11.58 && voltage <= 11.75) {
        batteryPercentage = 30
      } else if (voltage > 11.75 && voltage <= 11.9) {
        batteryPercentage = 40
      } else if (voltage > 11.9 && voltage <= 12.06) {
        batteryPercentage = 50
      } else if (voltage > 12.06 && voltage <= 12.2) {
        batteryPercentage = 60
      } else if (voltage > 12.2 && voltage <= 12.32) {
        batteryPercentage = 70
      } else if (voltage > 12.32 && voltage <= 12.42) {
        batteryPercentage = 80
      } else if (voltage > 12.42 && voltage <= 12.59) {
        batteryPercentage = 90
      } else if (voltage >= 12.6) {
        batteryPercentage = 100
      }
    }
    return batteryPercentage
  }

  formatDate = (timeStamp) => {
    const { t } = this.props
    const date = moment(timeStamp).format(Date_Format.MM_DD_YY)
    const time = moment(timeStamp).format(Date_Format.HH_MM_A)
    const dateTime = date + t("atLbl") + time
    return dateTime
  }
  render() {
    const {
      engineOilData,
      tirePressureAndTempData,
      batteryLifeData,
      faultCodesData,
      t,
      serviceSchedule,
      vehicle
    } = this.props

    let engineOilPercentage = null,
      batteryVolts = null,
      backgroundColor = "none",
      color = "none",
      batteryStatus = null,
      icon = null,
      tirePressureFrontLeft = null,
      tirePressureFrontRight = null,
      tirePressureRearLeft = null,
      tirePressureRearRight = null,
      tireTemperatureFrontLeft = null,
      tireTemperatureFrontRight = null,
      tireTemperatureRearLeft = null,
      tireTemperatureRearRight = null

    if (!isEmpty(tirePressureAndTempData)) {
      tirePressureFrontLeft = tirePressureAndTempData.DiagnosticTirePressureFrontLeftId
      tirePressureFrontRight = tirePressureAndTempData.DiagnosticTirePressureFrontRightId
      tirePressureRearLeft = tirePressureAndTempData.DiagnosticTirePressureRearLeftId
      tirePressureRearRight = tirePressureAndTempData.DiagnosticTirePressureRearRightId
      tireTemperatureFrontLeft = tirePressureAndTempData.DiagnosticTireTemperatureFrontLeftId
      tireTemperatureFrontRight = tirePressureAndTempData.DiagnosticTireTemperatureFrontRightId
      tireTemperatureRearLeft = tirePressureAndTempData.DiagnosticTireTemperatureRearLeftId
      tireTemperatureRearRight = tirePressureAndTempData.DiagnosticTireTemperatureRearRightId
    }

    if (!isEmpty(engineOilData)) {
      engineOilPercentage = engineOilData.vehicle_engine_oil_data
    }
    if (!isEmpty(batteryLifeData)) {
      batteryVolts = batteryLifeData.vehicle_battery_life_data
    }
    const batteryPercentage = this.fetchBatteryPercentages(batteryVolts)

    if (!isNaN(batteryPercentage)) {
      if (batteryPercentage <= 10) {
        batteryStatus = t("replaceLbl")
        backgroundColor = "#FDECE8"
        color = "#750D07"
        icon = <IconAlertOctagon />
      } else if (batteryPercentage > 10 && batteryPercentage <= 30) {
        batteryStatus = t("chargeLbl")
        backgroundColor = "#FDFBE8"
        color = "#786D08"
        icon = <IconBatteryCharging />
      } else {
        batteryStatus = t("goodLbl")
        backgroundColor = "#EFFDE8"
        color = "#4C5A44"
        icon = <IconCheck />
      }
    }
    const latestFaultCode =
      !isEmpty(faultCodesData) && faultCodesData.reduce((a, b) => (a.dateTime > b.dateTime ? a : b))
    return (
      <div className="geotab-container">
        <Header>{t("telematicsHeaderLbl")}</Header>
        <Grid celled="internally">
          <Grid.Column mobile={16} tablet={8} computer={5}>
            <Header>
              {t("faultCodesLbl")}
              {!isEmpty(faultCodesData) && (
                <span style={{ fontSize: "14px" }}>({faultCodesData.length})</span>
              )}
            </Header>
            <div>
              {faultCodesData && faultCodesData.length > 0 && (
                <div
                  style={{
                    position: "absolute",
                    top: 15,
                    right: 30,
                    textDecoration: "underline",
                    background: "F6F6F6"
                  }}
                  className="battery-health-labels"
                >
                  {this.showAllFaultCodes(faultCodesData)}
                </div>
              )}{" "}
              {!isEmpty(faultCodesData) ? (
                <>
                  <Carousel>
                    {faultCodesData &&
                      faultCodesData.slice(0, 10).map((code, index) => {
                        return <CarouselItem key={index}>{code}</CarouselItem>
                      })}
                  </Carousel>
                  <p className="fault-codes-last-update-section" style={{ textAlign: "center" }}>
                    <p>{t("lastUpdatedLbl")}</p>
                    <p>
                      {this.formatDate(
                        latestFaultCode.dateTime ? latestFaultCode.dateTime : new Date()
                      )}
                    </p>
                  </p>
                </>
              ) : (
                <div className="fault-code-section">{t("notAvailableLabel")}</div>
              )}
            </div>
          </Grid.Column>
          <Grid.Column textAlign="center" mobile={16} tablet={8} computer={3}>
            <Header style={{ color: "#05040C", fontWeight: 600 }}>{t("engineLifeLbl")}</Header>
            {!isEmpty(engineOilData) ? (
              isEmpty(engineOilData.errors) ? (
                !isNaN(engineOilPercentage) ? (
                  <>
                    <GaugeChart
                      nrOfLevels={1000}
                      animate={false}
                      hideText={true}
                      colors={["#CB7E68", "#F3D383", "#8BA27F"]}
                      percent={engineOilPercentage / 100}
                      arcPadding={0}
                      cornerRadius={0}
                    />
                    <div className="gauge-summary">
                      <p className="gauge-summary-text">
                        {engineOilData.vehicle_engine_oil_data}
                        {t("percentageLbl")}
                      </p>
                      <span>{t("oilChangeAtLbl")}</span>
                      <p style={{ fontSize: "16px", fontWeight: 500, color: "black" }}>
                        {isFleetMaintenanceHubCanada() ? (
                          <span>
                            {" "}
                            {serviceSchedule &&
                              numberHelpers.formatWithCommas(
                                serviceSchedule.interval_kilometers
                              )}{" "}
                          </span>
                        ) : (
                          <span>
                            {" "}
                            {serviceSchedule &&
                              numberHelpers.formatWithCommas(serviceSchedule.interval)}{" "}
                          </span>
                        )}
                        <span>
                          {serviceSchedule
                            ? isFleetMaintenanceHubCanada()
                              ? t("kilometersLbl")
                              : t("milesLbl")
                            : EMPTY_DISPLAY}
                        </span>
                      </p>
                      <p className="last-update-section">
                        <p>{t("lastUpdatedLbl")}</p>
                        <p>{this.formatDate(engineOilData.last_updated_at_geotab)}</p>
                      </p>
                    </div>
                  </>
                ) : (
                  <ReactGauge nrOfLevels={1000} />
                )
              ) : (
                <ReactGauge nrOfLevels={1000} />
              )
            ) : (
              <Loader active />
            )}
          </Grid.Column>
          <Grid.Column textAlign="center" mobile={16} tablet={8} computer={3}>
            <Header style={{ color: "#05040C", fontWeight: 600 }}>{t("batteryLifeLbl")}</Header>
            {!isEmpty(batteryLifeData) ? (
              isEmpty(batteryLifeData.errors) ? (
                !isNaN(batteryVolts) ? (
                  <>
                    <GaugeChart
                      nrOfLevels={3}
                      animate={false}
                      hideText={true}
                      arcsLength={[0.2, 0.3, 1]}
                      colors={["#CB7E68", "#F3D383", "#8BA27F"]}
                      percent={batteryPercentage / 100}
                      arcPadding={0}
                      cornerRadius={0}
                    />
                    <div className="gauge-summary">
                      <p className="gauge-summary-text">
                        {batteryLifeData.vehicle_battery_life_data}
                        {t("batteryVoltsUnitLbl")}
                      </p>
                      <div
                        className="battery-health-labels"
                        style={{ background: backgroundColor, color: color }}
                      >
                        {icon}
                        <span>{batteryStatus}</span>
                      </div>
                      <p className="last-update-section">
                        <p>{t("lastUpdatedLbl")}</p>
                        <p>{this.formatDate(batteryLifeData.last_updated_at_geotab)}</p>
                      </p>
                    </div>
                  </>
                ) : (
                  <ReactGauge nrOfLevels={3} arcsLength={[0.2, 0.3, 1]} />
                )
              ) : (
                <ReactGauge nrOfLevels={3} arcsLength={[0.2, 0.3, 1]} />
              )
            ) : (
              <Loader active />
            )}
          </Grid.Column>
          <Grid.Column textAlign="center" mobile={16} tablet={8} computer={4}>
            <Header>{t("tireAndTempLbl")}</Header>
            <div className="tire-pressure-section">
              <Grid columns={3}>
                <Grid.Row>
                  <Grid.Column textAlign="justified">
                    <div className="tire-pressure-left-section">
                      <div className="top-section">
                        <div className="tire-section-summary">
                          {tireTemperatureFrontLeft ? (
                            <div style={{ width: "50px" }}>{tireTemperatureFrontLeft}&deg;F</div>
                          ) : (
                            <div style={{ width: "50px", textAlign: "center" }}></div>
                          )}
                          {tireTemperatureFrontLeft ? (
                            <VerticleDivider />
                          ) : (
                            <span
                              style={{ marginRight: "5px", marginLeft: "5px", height: "40px" }}
                            ></span>
                          )}

                          {tirePressureFrontLeft ? (
                            <div className="tire-pressure-summary">
                              {tirePressureFrontLeft}
                              {/* <span>Normal</span> */}
                            </div>
                          ) : (
                            <DisplayEmpty />
                          )}
                        </div>
                      </div>
                      <div className="bottom-section">
                        <div className="tire-section-summary">
                          {tireTemperatureRearLeft ? (
                            <div style={{ width: "50px" }}>{tireTemperatureRearLeft}&deg;F</div>
                          ) : (
                            <div style={{ width: "50px", textAlign: "center" }}></div>
                          )}
                          {tireTemperatureRearLeft ? (
                            <VerticleDivider />
                          ) : (
                            <span
                              style={{ marginRight: "5px", marginLeft: "5px", height: "40px" }}
                            ></span>
                          )}
                          {tirePressureRearLeft ? (
                            <div className="tire-pressure-summary">
                              {tirePressureRearLeft}
                              {/* <span>Normal</span> */}
                            </div>
                          ) : (
                            <DisplayEmpty />
                          )}
                        </div>
                      </div>
                    </div>
                  </Grid.Column>
                  <Grid.Column textAlign="justified">
                    <div>
                      <div className="tire-pressure-image-section">
                        <div className="tire-pressure-left">
                          <Image className="tire-pressure-top-left" src={NormalTirePressure} />
                          <Image className="tire-pressure-bottom-left" src={NormalTirePressure} />
                        </div>
                        <div className="tire-pressure-right">
                          <Image className="tire-pressure-top-right" src={NormalTirePressure} />
                          <Image className="tire-pressure-bottom-right" src={NormalTirePressure} />
                        </div>
                        <Image className="top-view-car" src={TopViewCar} />
                      </div>
                    </div>
                  </Grid.Column>
                  <Grid.Column textAlign="justified">
                    <div className="tire-pressure-right-section">
                      <div className="top-section">
                        <div className="tire-section-summary">
                          {tirePressureFrontRight ? (
                            <div className="tire-pressure-summary">
                              {tirePressureFrontRight}
                              {/* <span>Normal</span> */}
                            </div>
                          ) : (
                            <DisplayEmpty />
                          )}
                          {tireTemperatureFrontRight ? (
                            <VerticleDivider />
                          ) : (
                            <span
                              style={{ marginRight: "5px", marginLeft: "5px", height: "40px" }}
                            ></span>
                          )}
                          {tireTemperatureFrontRight ? (
                            <div style={{ width: "50px" }}>{tireTemperatureFrontRight}&deg;F</div>
                          ) : (
                            <div style={{ width: "50px", textAlign: "center" }}></div>
                          )}
                        </div>
                      </div>
                      <div className="bottom-section">
                        <div className="tire-section-summary">
                          {tirePressureRearRight ? (
                            <div className="tire-pressure-summary">
                              {tirePressureRearRight}
                              {/* <span>Normal</span> */}
                            </div>
                          ) : (
                            <DisplayEmpty />
                          )}
                          {tireTemperatureRearRight ? (
                            <VerticleDivider />
                          ) : (
                            <span
                              style={{ marginRight: "5px", marginLeft: "5px", height: "40px" }}
                            ></span>
                          )}

                          {tireTemperatureRearRight ? (
                            <div style={{ width: "50px" }}>{tireTemperatureRearRight}&deg;F</div>
                          ) : (
                            <div style={{ width: "50px", textAlign: "center" }}></div>
                          )}
                        </div>
                      </div>
                    </div>
                  </Grid.Column>
                </Grid.Row>
              </Grid>
              {tirePressureAndTempData &&
                tirePressureAndTempData.last_updated_at_geotab &&
                tirePressureAndTempData.last_updated_at_geotab !== null && (
                  <p className="tire-pressure-last-update-section">
                    <p>{t("lastUpdatedLbl")}</p>
                    <p>{this.formatDate(tirePressureAndTempData.last_updated_at_geotab)}</p>
                  </p>
                )}
            </div>
          </Grid.Column>
        </Grid>
      </div>
    )
  }
} // class GeotabInfo

const mapStateToProps = (state) => ({
  language: state.users.currentUser && state.users.currentUser.language,
  userFirstName: state.users.currentUser && state.users.currentUser.firstName,
  userRoles: state.users.currentUser && state.users.currentUser.roles,
  states: state.users.states
})

export default connect(mapStateToProps)(withTranslation(["geotab", "common"])(GeotabInfo))
