import React from "react"
import { handleDownloadAll } from "../../helpers/documentHelpers"

class FileDownloader extends React.Component {
  render() {
    const { t, files } = this.props
    return (
      <div>
        <button type="button" className="download-all-btn" onClick={() => handleDownloadAll(files)}>
          {t("downloadAllLabel")}
        </button>
      </div>
    )
  }
}

export default FileDownloader
