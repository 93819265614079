import { call, put, takeEvery } from "redux-saga/effects"

import api from "../../utilities/api"
import * as apiErrorHelpers from "../../helpers/apiErrorHelpers"
import * as sessionHelpers from "../../helpers/sessionHelpers"
import { NOTIFICATION_TYPE } from "../../constants/notification"

export function* loadShopOrderActivityItems(action) {
  try {
    api.setAuthApi({
      Authorization: sessionHelpers.getApplicationCookie("authentication_token")
    })

    yield put({ type: "SHOP_ORDER_ACTIVITY_ITEMS_SET_LOADING" })
    yield put({ type: "SHOP_ORDER_ACTIVITY_ITEMS_LOAD_REQUESTED" })

    const request = {
      url: "/shop_order_activity_items",
      body: { shop_order_id: action.payload.shopOrderId }
    }

    const response = yield call(api.utility.get, api.path(request.url), { body: request.body })

    apiErrorHelpers.catchErrors(response.body)

    const items = response.body

    yield put({
      type: "SHOP_ORDER_ACTIVITY_ITEMS_LOAD_SUCCEEDED",
      payload: {
        items: items
      }
    })
    yield put({ type: "SHOP_ORDER_ACTIVITY_ITEMS_UNSET_LOADING" })

    return items
    ////////////////////////////
  } catch (errors) {
    // eslint-disable-next-line
    yield put({ type: "SHOP_ORDER_ACTIVITY_ITEMS_UNSET_LOADING" })

    errors = apiErrorHelpers.formatInternalErrors(errors)

    yield put({ type: "SHOP_ORDER_ACTIVITY_ITEMS_LOAD_FAILED" })

    apiErrorHelpers.consoleLog(errors)
  }
} // function* loadShopOrderActivityItems

export function* loadApproversNotes(action) {
  try {
    api.setAuthApi({
      Authorization: sessionHelpers.getApplicationCookie("authentication_token")
    })
    yield put({ type: "SHOP_ORDER_APPROVAL_NOTES_SET_LOADING" })
    yield put({ type: "APPROVERS_NOTES_LOAD_REQUESTED" })

    const request = {
      url: `/notes`,
      body: { shop_order_id: action.payload.shopOrderId }
    }

    const response = yield call(api.utility.get, api.path(request.url), {
      body: request.body
    })

    apiErrorHelpers.catchErrors(response.body)
    yield put({
      type: "APPROVERS_NOTES_LOAD_SUCCEEDED",
      payload: {
        approverNotes: response.body
      }
    })
    yield put({ type: "SHOP_ORDER_APPROVAL_NOTES_UNSET_LOADING" })
  } catch (errors) {
    yield put({ type: "SHOP_ORDER_APPROVAL_NOTES_UNSET_LOADING" })

    errors = apiErrorHelpers.formatInternalErrors(errors)

    yield put({ type: "SHOP_ORDER_APPROVAL_NOTES_LOAD_FAILURE" })

    apiErrorHelpers.consoleLog(errors)
  }
} // loadApproversNotes

export function* createApproversNote(action) {
  try {
    api.setAuthApi({
      Authorization: sessionHelpers.getApplicationCookie("authentication_token")
    })
    yield put({ type: "SHOP_ORDER_APPROVAL_NOTES_SET_LOADING" })
    yield put({ type: "SHOP_ORDER_APPROVERS_NOTE_CREATE_REQUESTED" })
    const { shopOrderId, shopId, approverNote } = action.payload

    const request = {
      url: `/notes`,
      body: {
        shop_order_id: shopOrderId,
        shop_id: shopId,
        title: approverNote,
        comment: approverNote
      }
    }

    const response = yield call(api.utility.post, api.path(request.url), {
      body: request.body
    })

    apiErrorHelpers.catchErrors(response.body)
    yield put({
      type: "APPROVERS_NOTES_CREATE_SUCCEEDED",
      payload: {
        approverNotes: response.body
      }
    })

    if (action.callback) {
      const afterRequestData = {
        title: action.payload.t("successLable"),
        message: action.payload.t("approverNoteAddedSuccess"),
        type: NOTIFICATION_TYPE.SUCCESS
      }

      yield call(action.callback, NOTIFICATION_TYPE.SUCCESS, afterRequestData)
    }

    yield put({ type: "SHOP_ORDER_APPROVAL_NOTES_UNSET_LOADING" })
  } catch (errors) {
    yield put({ type: "SHOP_ORDER_APPROVAL_NOTES_UNSET_LOADING" })

    errors = apiErrorHelpers.formatInternalErrors(errors)

    yield put({ type: "SHOP_ORDER_APPROVAL_NOTE_CREATE_FAILURE" })

    if (action.callback) {
      const afterRequestData = {
        title: action.payload.t("errorLabel"),
        message: apiErrorHelpers.toString(errors),
        type: NOTIFICATION_TYPE.DANGER
      }

      yield call(action.callback, "failure", afterRequestData)
    }
    apiErrorHelpers.consoleLog(errors)
  }
} // loadApproversNotes

export default function* shopOrderActivityItemSaga() {
  yield takeEvery("SHOP_ORDER_ACTIVITY_ITEMS_LOAD_SAGA", loadShopOrderActivityItems)
  yield takeEvery("SHOP_ORDER_APPROVERS_NOTES_LOAD_SAGA", loadApproversNotes)
  yield takeEvery("SHOP_ORDER_APPROVERS_NOTES_CREATE_SAGA", createApproversNote)
}
