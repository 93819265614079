import React, { Component } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { Link } from "react-router-dom"
import { navigationPaths } from "../../constants/paths"
import ApplicationLayout from "../../layouts/ApplicationLayout"
import BasicVehicleList from "../../components/vehicles/BasicVehicleList"
import { Grid } from "semantic-ui-react"
import Select from "../../elements/Select"
import { Header, Segment, Button, Checkbox as SematicCheckbox } from "semantic-ui-react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faArrowAltCircleRight } from "@fortawesome/pro-light-svg-icons"
import * as fleetHelpers from "../../helpers/fleetHelpers"
import * as vehicleHelpers from "../../helpers/vehicleHelpers"
import { withTranslation } from "react-i18next"
import Alert from "../../components/shared/Alert"
import { isFmcPath } from "../../helpers/pathHelpers"
import { isFMCUser } from "../../helpers/userRoleHelpers"
import { FLEET } from "../../constants/application"

class TransferVehicles extends Component {
  constructor(props) {
    super(props)
    this.state = {
      fleet_id_1: null,
      fleet_id_2: null,
      vehicle_id: null,
      vehicles1: [],
      vehicles2: [],
      searchVehicles: [],
      section: 0,
      selectedRows: [],
      alertMessage: "",
      alertType: "default",
      showGrid1: true,
      showGrid2: true,
      disabled: true
    }
  }

  componentDidMount() {
    this.loadAllFleets()
    this.loadBasicVehicles()
  }

  loadFleetWiseVehicles = async (fleet_id, section) => {
    await this.props.dispatch({
      type: "VEHICLES_LOAD_SAGA",
      payload: {
        options: {
          fleet_id: fleet_id
        }
      },
      callback: this.afterloadFleetWiseVehiclesSuccess
    })
  }

  loadVehicle = async (vehicle_id) => {
    const { vehicleId } = this.state

    this.props.dispatch({
      type: "VEHICLE_LOAD_SAGA",
      payload: {
        vehicleId: parseInt(vehicle_id),
        options: {
          search: true
        }
      },
      callback: this.afterloadVehicleSuccess
    })
  }

  loadBasicVehicles = async () => {
    await this.props.dispatch({
      type: "VEHICLES_BASIC_LOAD_SAGA",
      payload: {}
    })
  }

  loadAllFleets = () => {
    this.props.dispatch({
      type: "ALL_FLEETS_LOAD_SAGA",
      payload: {}
    })
  }

  transferVehicles = (vehicle_ids, old_fleet_ids, new_fleet_id) => {
    this.props.dispatch({
      type: "VEHICLE_TRANSFER_SAGA",
      payload: {
        options: {
          vehicle_ids: vehicle_ids,
          old_fleet_ids: old_fleet_ids,
          new_fleet_id: new_fleet_id
        }
      },
      callback: this.afterTransferVehiclesSuccess
    })
  }

  afterloadFleetWiseVehiclesSuccess = (response) => {
    const { section } = this.state
    if (section == 1) {
      this.setState({ vehicles1: response, showGrid1: true })
    } else if (section == 2) {
      this.setState({ vehicles2: response, showGrid2: true })
    }
  }

  afterloadVehicleSuccess = (response) => {
    let tempArray = []
    tempArray = [...tempArray, response]
    this.setState({ vehicles1: tempArray, showGrid1: true })
  }

  afterTransferVehiclesSuccess = (response) => {
    const { t } = this.props
    this.refresh()
    this.setState({ alertMessage: t("transferSuccessMsg"), alertType: "notice" })
  }

  onFleet1Change = (fleet_id) => {
    const { t } = this.props
    const { fleet_id_2 } = this.state
    this.setState({
      fleet_id_1: fleet_id,
      section: 1,
      vehicle_id: null,
      alertMessage: "",
      showGrid1: false
    })
    if (fleet_id_2 == fleet_id)
      this.setState({
        alertMessage: t("sameFleetSelectMsg"),
        alertType: "error"
      })
    else this.loadFleetWiseVehicles(fleet_id, 1)
  }

  onFleet2Change = (fleet_id) => {
    const { t } = this.props
    const { fleet_id_1 } = this.state
    if (fleet_id_1 == fleet_id)
      this.setState({
        alertMessage: t("sameFleetSelectMsg"),
        alertType: "error"
      })
    else {
      this.setState({ fleet_id_2: fleet_id, section: 2, alertMessage: "", showGrid2: false })
      this.loadFleetWiseVehicles(fleet_id, 2)
    }
  }

  onVehicleChange = (vehicle_id) => {
    this.setState({ vehicle_id: vehicle_id, fleet_id_1: null, alertMessage: "", showGrid1: false })
    this.loadVehicle(vehicle_id)
  }

  swapVehicles = () => {
    const { t } = this.props
    const { selectedRows, vehicles1, fleet_id_2, fleet_id_1 } = this.state
    if (selectedRows.length == 0 || (!fleet_id_2 && !fleet_id_1))
      this.setState({ alertMessage: t("missingFleetMsg"), alertType: "error" })
    else if (!fleet_id_2)
      this.setState({
        alertMessage: t("DestinationFLeetMissingMsg"),
        alertType: "error"
      })
    else {
      let result = []
      selectedRows.map((row) => result.push(row["data"]))
      let array1 = vehicles1.filter((ar) => !result.find((rm) => rm.id === ar.id))

      this.setState((prevState) => {
        return {
          vehicles2: [...prevState.vehicles2, ...result],
          vehicles1: array1,
          disabled: false
        }
      })
    }
  }

  onSelectionChanged = (event) => {
    this.setState({ selectedRows: event.api.getSelectedNodes() })
  }

  saveVehicles = () => {
    const { vehicles2, fleet_id_2 } = this.state
    let result = vehicles2.map((entry) => {
      return entry.id
    })

    let old_fleet_ids = vehicles2.map((entry) => {
      return entry.fleet_id
    })

    this.transferVehicles(result, old_fleet_ids, fleet_id_2)
  }

  refresh = () => {
    this.setState({
      vehicles1: [],
      vehicles2: [],
      vehicle_id: null,
      searchVehicles: [],
      section: 0,
      selectedRows: [],
      fleet_id_1: null,
      fleet_id_2: null,
      disabled: true,
      alertMessage: ""
    })
  }

  render() {
    const { users, dispatch, fleets, t, basicVehicles, currentUser, fleet, language } = this.props
    const {
      fleet_id_1,
      fleet_id_2,
      vehicles1,
      vehicles2,
      vehicle_id,
      alertType,
      alertMessage,
      showGrid1,
      showGrid2,
      disabled
    } = this.state
    return (
      <ApplicationLayout>
        {alertMessage ? <Alert message={alertMessage} type={alertType} t={t} /> : ""}
        <Grid columns="equal" stackable>
          <Grid.Column width={7} style={{ position: "inherit", alignSelf: "end" }}>
            <Header size="small" className={"dash-hd"}>
              {t("transferVehicles")}
            </Header>
            <Header size="small" className={"dash-hd"}>
              {t("sourceFleet")}
            </Header>
          </Grid.Column>

          <Grid.Column width={2}></Grid.Column>

          <Grid.Column width={7}>
            <Grid columns="equal" stackable>
              <Grid.Column width={8}>
                <Button secondary icon="refresh" onClick={() => this.refresh()} />
                <Header size="small" className={"dash-hd"}>
                  {t("destinationFleet")}
                </Header>
              </Grid.Column>
              <Grid.Column width={8} style={{ textAlign: "end" }}>
                <Button
                  secondary
                  content={t("save")}
                  onClick={() => this.saveVehicles()}
                  disabled={disabled}
                />
              </Grid.Column>
            </Grid>
          </Grid.Column>
        </Grid>

        <Grid columns="equal" stackable>
          <Grid.Column width={7}>
            <Segment className={"ag-theme-material"}>
              <Grid columns="equal" stackable>
                <Grid.Column width={7}>
                  {basicVehicles.length > 0 ? (
                    <Select
                      onChange={(event) => this.onVehicleChange(event.target.value)}
                      options={vehicleHelpers.selectBasicOptions(basicVehicles)}
                      placeholder={t("seachBydetails")}
                      defaultValue={vehicle_id}
                      value={vehicle_id}
                    />
                  ) : (
                    ""
                  )}
                </Grid.Column>
                <Grid.Column width={2} className="text-style">
                  {t("or")}
                </Grid.Column>
                <Grid.Column width={7}>
                  {fleets.length > 0 ? (
                    <Select
                      onChange={(event) => this.onFleet1Change(event.target.value)}
                      options={fleetHelpers.selectOptions(fleets)}
                      placeholder={t("seachByFleet")}
                      defaultValue={fleet_id_1}
                      value={fleet_id_1}
                    />
                  ) : (
                    ""
                  )}
                </Grid.Column>
              </Grid>
            </Segment>
          </Grid.Column>

          <Grid.Column width={2}></Grid.Column>

          <Grid.Column width={7}>
            <Segment className={"ag-theme-material"}>
              <Grid columns="equal" stackable>
                <Grid.Column width={7}></Grid.Column>
                <Grid.Column width={2} className="text-style"></Grid.Column>
                <Grid.Column width={7}>
                  {fleets.length > 0 ? (
                    <Select
                      onChange={(event) => this.onFleet2Change(event.target.value)}
                      options={fleetHelpers.selectOptions(fleets)}
                      placeholder={t("seachByFleet")}
                      defaultValue={fleet_id_2}
                      value={fleet_id_2}
                    />
                  ) : (
                    ""
                  )}
                </Grid.Column>
              </Grid>
            </Segment>
          </Grid.Column>
        </Grid>

        <Grid columns="equal" stackable>
          <Grid.Column width={7}>
            <BasicVehicleList
              users={users}
              vehicles={vehicles1}
              loadVehicles={this.componentDidMount.bind(this)}
              onDeleteVehicle={this.onDeleteVehicle}
              onDeactivateVehicle={this.onDeactivateVehicle}
              language={language}
              dispatch={dispatch}
              currentUser={currentUser}
              showMultiSelection={true}
              onSelectionChanged={this.onSelectionChanged}
              showGrid1={showGrid1}
            />
          </Grid.Column>
          <Grid.Column width={2} style={{ margin: "auto", textAlign: "center" }}>
            <FontAwesomeIcon
              icon={faArrowAltCircleRight}
              size="7x"
              onClick={this.swapVehicles}
              disabled={true}
            />
            <Header size="small" className={"dash-hd"}>
              {t("transferVehicles")}
            </Header>
          </Grid.Column>
          <Grid.Column width={7}>
            <BasicVehicleList
              users={users}
              vehicles={vehicles2}
              loadVehicles={this.componentDidMount.bind(this)}
              onDeleteVehicle={this.onDeleteVehicle}
              onDeactivateVehicle={this.onDeactivateVehicle}
              language={language}
              dispatch={dispatch}
              currentUser={currentUser}
              showMultiSelection={false}
              showGrid2={showGrid2}
            />
          </Grid.Column>
        </Grid>
      </ApplicationLayout>
    )
  }
} // class TransferVehicle

const mapStateToProps = (state, props) => {
  const { users } = state
  const user = state.application.userSessionData || (users && users.currentUser)
  const isFMC = isFMCUser(user)
  const isPathFMC = isFmcPath()
  return {
    isFleetMode: state.application.mode === FLEET || (isFMC && !isPathFMC),
    users: state.users.users,
    vehicles1: state.vehicles.vehicles1,
    vehicles2: state.vehicles.vehicles2,
    basicVehicles: state.vehicles.basicVehicles,
    vehicle: state.vehicles.vehicle,
    fleet: state.application.fleetSessionData || state.fleets.fleet,
    language:
      (state.application.userSessionData && state.application.userSessionData.language) ||
      (state.users.currentUser && state.users.currentUser.language),
    currentUser: user,
    fleets: state.fleets.all_fleets
  }
}

export default connect(mapStateToProps, null)(withTranslation("vehicleIndex")(TransferVehicles))
