import React, { Component } from "react"
import { connect } from "react-redux"
import PropTypes from "prop-types"

import SelectedService from "./SelectedService"

class SelectedServices extends Component {
  static propTypes = {
    onRemoval: PropTypes.func.isRequired,
    onServiceOptionChange: PropTypes.func.isRequired,
    services: PropTypes.array.isRequired
  }

  renderSelectedServices() {
    const {
      onRemoval,
      onServiceOptionChange,
      services,
      servicePrices,
      servicePricesLoading,
      vehicle_type_id
    } = this.props

    return services.map((service, index) => {
      const matchingPrice = servicePrices.find((price) => price.id === service.id)

      return (
        <SelectedService
          key={index}
          onRemoval={onRemoval}
          onServiceOptionChange={onServiceOptionChange}
          service={service}
          price={matchingPrice}
          servicePricesLoading={servicePricesLoading}
          vehicle_type_id={vehicle_type_id}
        />
      )
    })
  }

  render() {
    return <div className={"dash-approvals-detail-container"}>{this.renderSelectedServices()}</div>
  }
} // class SelectedServices

const mapStateToProps = (state) => {
  return {
    servicePrices: state.services.servicePrices || [],
    servicePricesLoading: state.services.servicePricesLoading
  }
}

export default connect(mapStateToProps)(SelectedServices)
