import * as stringHelpers from "./stringHelpers"
import { isFleetcor } from "./affiliationHelpers"
import { MD_HD_VEHICLE_TYPE_ID } from "../constants/application"
import i18n from "../utilities/i18n"

export function selectOptions(services, opts = {}) {
  let options = []
  let vehicle = opts.vehicle
  if (vehicle && vehicle.vehicle_type_id === MD_HD_VEHICLE_TYPE_ID) {
    options = opts.includeBlank
      ? [
          {
            value: null,
            label: i18n.t("common:mdHdServicesLabel")
          }
        ]
      : []
  }

  if (opts.semantic)
    services.forEach((service) => {
      options.push({
        value: service.id,
        text: service.name,
        key: `service-${service.id}`
      })
    })
  else
    services.forEach((service) => {
      options.push({
        label: service.name,
        value: service.id
      })
    })

  return options
}

export function preselectOilType(vehicle, options) {
  let selection
  if (!vehicle) return selection

  const vehicleOilType = vehicle.oil_type_name

  if (vehicleOilType) {
    const formattedOilType = stringHelpers.capitalCase(vehicleOilType)
    const oilTypeIndex = options.indexOf(formattedOilType)

    selection = options[oilTypeIndex]
  }

  return selection
}

export function checkForActiveOrder({ event, vehicle, t }) {
  const { active_order, is_active, fleetcor_enrolled } = vehicle || {}

  if (active_order) {
    event.preventDefault()
    alert(t("orderExistsAlert"))
  } else if (is_active == false) {
    event.preventDefault()
    alert(t("vehicleDeactivatedLabel"))
  } else if (isFleetcor() && fleetcor_enrolled == false) {
    event.preventDefault()
    alert(t("fleetcorVehicleDeactivateLabel"))
  }
}

export function formattedSelectedServices({ services, vehicle }) {
  return services.map((service) => formattedSelectedService({ service, vehicle }))
}

export function formattedSelectedService({ service, vehicle }) {
  // `service_id` is the id of the associated service definition.  alternatively can be just `id`
  let formattedService = {
    id: service.service_id || service.id,
    name: service.name || service.service_name,
    positionOptions: service.positions || [],
    support_medium_and_heavy_duty_vehicles: service.support_medium_and_heavy_duty_vehicles
  }

  // positions can come via the `service_options` or directly on the service
  const serviceOption = (service.service_options || [])[0]
  const positions = (serviceOption || service).positions || []

  if (positions.length > 0) {
    let preSelectedPosition

    if (service.name === "Oil Change") {
      preSelectedPosition = preselectOilType(vehicle, positions)
    }

    formattedService["positionOptions"] = positions
    formattedService["position"] = preSelectedPosition || ""
  }

  return formattedService
}
