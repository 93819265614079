import React, { Component, useState } from "react"
import PropTypes from "prop-types"
import { orderBy, isNumber } from "lodash"
import * as phoneNumberHelpers from "../../helpers/phoneNumberHelpers"
import {
  isOwnerManagerOrAdmin,
  checkUserRole,
  isOwnerAdminFMCOrFMCADMIN
} from "../../helpers/activeMaintenanceHelpers"
import { isFMCUser } from "../../helpers/userRoleHelpers"
import CollapsibleList from "../shared/CollapsibleList"
import { formattedName } from "../../helpers/vehicleHelpers"

import { Grid, Table, Image } from "semantic-ui-react"
import UserModal from "./UserModal"
import { FMC } from "../../constants/roles"
import { withTranslation } from "react-i18next"
import driverLicenseUpdatedIcon from "../../assets/images/driver_license_uploded.svg"
import customDocumentIcon from "../../assets/images/custom_document.svg"
import driverLicenseNotUpdatedIcon from "../../assets/images/driver_license_notupdated.svg"
import customDocumentNotUpdatedIcon from "../../assets/images/driver_insurance_notupdated.svg"
import deleteIcon from "../../assets/images/delete_icon.svg"
import editIcon from "../../assets/images/edit_icon.svg"
import { DOCUMENT_TYPE } from "../../constants/application"
import { NA } from "../../constants/vehicles"
import DocumentUploadModal from "../vehicles/DocumentUploadModal"
import { ON } from "../config/SplitClient"

const IconList = ({
  vehicles = [],
  icons,
  alwaysVisibleCount = 5,
  showIcons,
  docType,
  userRoles,
  states
}) => {
  const itemsCount = showIcons ? vehicles.length : alwaysVisibleCount
  const [openDocumentModal, setOpenDocumentModal] = useState(false)
  const [docuemnts, setDocuments] = useState([])

  const renderDocumentModal = () => {
    return (
      <DocumentUploadModal
        open={openDocumentModal}
        showOrHideModal={() => setOpenDocumentModal(false)}
        states={states}
        driverLicence={docuemnts}
        vehicleInsurance={docuemnts}
        registration={docuemnts}
        docType={docType}
        customDocuments={docuemnts}
        userRoles={userRoles}
        readOnly={true}
      />
    )
  }

  const handleIconClick = (e, glovebox_items) => {
    setOpenDocumentModal(true)
    if (docType === DOCUMENT_TYPE.OTHER) {
      setDocuments(glovebox_items.filter((item) => item.document_type === docType))
    } else {
      setDocuments(glovebox_items.find((item) => item.document_type === docType))
    }
  }

  return (
    <>
      {orderBy(vehicles, ["vehicle_name", "id"])
        .slice(0, itemsCount)
        .map((v, index) => {
          let name = v.year && v.make && v.model ? `${v.year} ${v.make} ${v.model}` : v.vin
          if (v.other_id) {
            name += ` (${v.other_id})`
          }
          let doc_count = 0
          let isDocumentPresent = false
          let docExpirationDays = null
          const { glovebox_items = [] } = v
          if (glovebox_items.length && docType) {
            if (docType === DOCUMENT_TYPE.OTHER) {
              const documents = glovebox_items.filter((item) => item.document_type === docType)
              doc_count = documents.length
              if (doc_count > 0) {
                isDocumentPresent = true
                const isExpired = documents.find(
                  (doc) => doc.expires_in_days !== null && doc.expires_in_days <= 60
                )
                if (isExpired) {
                  docExpirationDays = isNumber(isExpired.expires_in_days)
                    ? isExpired.expires_in_days
                    : NA
                } else {
                  docExpirationDays = NA
                }
              }
            } else {
              const document = glovebox_items.find((item) => item.document_type === docType)
              if (document) {
                docExpirationDays = isNumber(document.expires_in_days)
                  ? document.expires_in_days
                  : NA
                isDocumentPresent = true
              } else {
                docExpirationDays = NA
              }
            }
          }
          return (
            <div
              style={{
                margin: "10px",
                height: name && name.length > 51 ? "30px" : "20px",
                textAlign: "-moz-center"
              }}
              key={`collapsible-item-${index}`}
            >
              <Image
                src={
                  isDocumentPresent
                    ? docExpirationDays === NA
                      ? icons.activeIcon
                      : docExpirationDays > 60
                      ? icons.activeIcon
                      : icons.disabledIcon
                    : icons.activeIcon
                }
                onClick={isDocumentPresent ? (e) => handleIconClick(e, glovebox_items) : null}
                style={
                  isDocumentPresent
                    ? { cursor: "pointer" }
                    : { opacity: "0.5", cursor: "not-allowed" }
                }
                verticalAlign="middle"
              />
              {docType === DOCUMENT_TYPE.OTHER && doc_count > 0 ? (
                <span style={{ marginLeft: docExpirationDays < 60 ? "6px" : "10px" }}>
                  {doc_count}
                </span>
              ) : (
                <span style={{ marginLeft: "17px" }}> </span>
              )}
            </div>
          )
        })}
      {vehicles.length > alwaysVisibleCount && (
        <div style={{ margin: "10px" }} className="link">
          &nbsp;
        </div>
      )}
      {openDocumentModal ? renderDocumentModal() : null}
    </>
  )
}

class UserListItem extends Component {
  static propTypes = {
    user: PropTypes.object.isRequired
  }

  state = {
    showIcons: false
  }

  showDeleteButton() {
    const { user, currentUser } = this.props

    return (
      user &&
      currentUser &&
      user.id !== null &&
      user.id !== currentUser.id &&
      ((isOwnerManagerOrAdmin(currentUser) && checkUserRole(user)) ||
        (currentUser.roles != null && currentUser.roles.includes(FMC)))
    )
  }

  showDeactivateButton() {
    const { user, currentUser } = this.props

    return (
      user &&
      currentUser &&
      user.id !== null &&
      user.id !== currentUser.id &&
      isOwnerAdminFMCOrFMCADMIN(currentUser) &&
      checkUserRole(user)
    )
  }

  showAllIcons = (value) => {
    this.setState({ showIcons: value })
  }

  onDelete = () => {
    this.props.onDelete(this.props.user.id)
  }

  onDeactivate = () => {
    this.props.onDeactivate(this.props.user.id, this.props.user.is_active)
  }

  render() {
    const {
      user,
      loadUsers,
      onDelete,
      currentUser,
      isFMC,
      t,
      states,
      isGloveboxFeatureFlag
    } = this.props
    const userRoles = this.props.userRoles
    const { showIcons } = this.state
    return (
      <Table.Row
        style={{
          backgroundColor:
            window.location.href.indexOf("settings") > -1 && !user.is_active ? "#D3D3D3" : ""
        }}
      >
        <Table.Cell singleLine>{user.name}</Table.Cell>
        <Table.Cell>
          {user.roles.map((r) => (typeof r === "string" ? r : r.name)).join(", ")}
        </Table.Cell>
        <Table.Cell singleLine>{user.email}</Table.Cell>
        <Table.Cell singleLine>{phoneNumberHelpers.reformat(user.cellPhone)}</Table.Cell>
        {!isFMC && (
          <Table.Cell>
            <CollapsibleList
              items={
                user.vehicles &&
                user.vehicles
                  .filter((v1) => v1.fleet_id == currentUser.fleet_id)
                  .map((v) => formattedName(v))
              }
              t={t}
              showAllIcons={this.showAllIcons}
            />
          </Table.Cell>
        )}

        {isFMC && (
          <Table.Cell>
            <CollapsibleList
              items={(user.approval_fleets || []).map((fleet) => fleet.name)}
              t={t}
            />
          </Table.Cell>
        )}

        {!isFMC && isGloveboxFeatureFlag === ON && (
          <Table.Cell textAlign="center">
            <IconList
              vehicles={user.vehicles.filter((v1) => v1.fleet_id == currentUser.fleet_id)}
              icons={{
                activeIcon: driverLicenseUpdatedIcon,
                disabledIcon: driverLicenseNotUpdatedIcon
              }}
              showIcons={showIcons}
              docType={DOCUMENT_TYPE.DRIVER_LICENSE}
              userRoles={userRoles}
              states={states}
            />
          </Table.Cell>
        )}
        {!isFMC && isGloveboxFeatureFlag === ON && (
          <Table.Cell textAlign="center">
            <IconList
              vehicles={user.vehicles.filter((v1) => v1.fleet_id == currentUser.fleet_id)}
              icons={{ activeIcon: customDocumentIcon, disabledIcon: customDocumentNotUpdatedIcon }}
              showIcons={showIcons}
              docType={DOCUMENT_TYPE.VEHICLE_INSURANCE}
              userRoles={userRoles}
              states={states}
            />
          </Table.Cell>
        )}
        {!isFMC && isGloveboxFeatureFlag === ON && (
          <Table.Cell textAlign="center">
            <IconList
              vehicles={user.vehicles.filter((v1) => v1.fleet_id == currentUser.fleet_id)}
              icons={{ activeIcon: customDocumentIcon, disabledIcon: customDocumentNotUpdatedIcon }}
              showIcons={showIcons}
              docType={DOCUMENT_TYPE.REGISTRATION}
              userRoles={userRoles}
              states={states}
            />
          </Table.Cell>
        )}
        {!isFMC && isGloveboxFeatureFlag === ON && (
          <Table.Cell textAlign="center">
            <IconList
              vehicles={user.vehicles.filter((v1) => v1.fleet_id == currentUser.fleet_id)}
              icons={{ activeIcon: customDocumentIcon, disabledIcon: customDocumentNotUpdatedIcon }}
              showIcons={showIcons}
              docType={DOCUMENT_TYPE.OTHER}
              userRoles={userRoles}
              states={states}
            />
          </Table.Cell>
        )}
        <Table.Cell>
          <Grid columns={3}>
            <Grid.Column width={3}>
              {user.is_active && isOwnerManagerOrAdmin(currentUser) && (
                <UserModal
                  completionCallback={loadUsers}
                  user={user}
                  isFMC={isFMCUser(user)}
                  currentUser={currentUser}
                >
                  <Image
                    src={editIcon}
                    style={{ cursor: "pointer", marginTop: "-6px" }}
                    title={t("editLabel")}
                  />
                </UserModal>
              )}
            </Grid.Column>
            <Grid.Column width={3}>
              {this.showDeleteButton() && (
                <Image
                  src={deleteIcon}
                  style={{ cursor: "pointer" }}
                  onClick={this.onDelete}
                  title={t("deleteLabel")}
                />
              )}
            </Grid.Column>
            {window.location.href.indexOf("settings") > -1 ? (
              <Grid.Column width={6}>
                {this.showDeactivateButton() &&
                  (user.is_active === true ? (
                    <span className="link" onClick={this.onDeactivate}>
                      {t("deactivateLabel")}
                    </span>
                  ) : (
                    <span className="link" onClick={this.onDeactivate}>
                      {t("activateLabel")}
                    </span>
                  ))}
              </Grid.Column>
            ) : null}
          </Grid>
        </Table.Cell>
      </Table.Row>
    )
  }
} // class UserListItem

export default withTranslation("common")(UserListItem)
