import { isFleetMaintenanceHubCanada } from "../helpers/affiliationHelpers"
import * as numberHelpers from "../helpers/numberHelpers"
import * as vehicleHelpers from "../helpers/vehicleHelpers"

export function agGridColumnDefs(defaultCellRenderer, t, isFleetcor, vehicles, showMultiSelection) {
  const widths = {
    verySmall: 125,
    small: 150,
    medium: 175,
    large: 200,
    veryLarge: 225
  }
  var fields = [
    {
      headerName: t("vehicle:vinLabel"),
      field: "vin",
      width: widths.veryLarge,
      cellRendererFramework: defaultCellRenderer,
      headerCheckboxSelection: showMultiSelection ? true : false,
      headerCheckboxSelectionFilteredOnly: showMultiSelection ? true : false,
      checkboxSelection: showMultiSelection ? true : false
    },
    {
      headerName: t("dashboard:DeptNumber"),
      field: "department_number",
      width: widths.verySmall,
      cellRendererFramework: defaultCellRenderer
    },
    {
      headerName: isFleetcor ? "Vehicle Name" : t("otherIdLabel"),
      field: isFleetcor ? "vehicle_name" : "other_id",
      width: widths.verySmall,
      cellRendererFramework: defaultCellRenderer
    },
    {
      headerName: t("driversLabel"),
      field: "drivers",
      width: widths.verySmall,
      cellRendererFramework: defaultCellRenderer
    },
    {
      headerName: isFleetMaintenanceHubCanada() ? t("dashboard:kilometersLabel") : "Mileage",
      field: isFleetMaintenanceHubCanada() ? KILOMETERS : MILES,
      width: widths.verySmall,
      cellRendererFramework: defaultCellRenderer
    }
  ]
  return fields
}

export function agGridRowData(vehicles, users, language, isFleetcor) {
  if (isFleetcor) {
    return (vehicles.filter((v) => v.is_active == true) || []).map((vehicle) => {
      return {
        vin: vehicle.vin,
        vehicle_name: vehicle.name_of_vehicle,
        department_number: vehicle.department_number,
        drivers: vehicleHelpers.formattedDriverNames(vehicle.driver_names, language),
        [isFleetMaintenanceHubCanada() ? KILOMETERS : MILES]: numberHelpers.formatWithCommas(
          isFleetMaintenanceHubCanada() ? vehicle.kilometers : vehicle.miles
        ),
        // NOTE: The ID column does not get displayed because there is no mapping for it in the columnDefs, however, providing here makes it available to the row's data attribute.
        id: vehicle.id,
        fleet_id: vehicle.fleet_id,
        vehicle: vehicle
      }
    })
  } else {
    return (vehicles || []).map((vehicle) => {
      return {
        vin: vehicle.vin,
        other_id: vehicle.other_id,
        department_number: vehicle.department_number,
        drivers: vehicleHelpers.formattedDriverNames(vehicle.driver_names, language),
        [isFleetMaintenanceHubCanada() ? KILOMETERS : MILES]: numberHelpers.formatWithCommas(
          isFleetMaintenanceHubCanada() ? vehicle.kilometers : vehicle.miles
        ),
        cost_per_mile: vehicleHelpers.formattedCostPerMile(vehicle),
        // NOTE: The ID column does not get displayed because there is no mapping for it in the columnDefs, however, providing here makes it available to the row's data attribute.
        id: vehicle.id,
        fleet_id: vehicle.fleet_id,
        vehicle: vehicle
      }
    })
  }
}

export const KILOMETERS = "kilometers"
export const MILES = "miles"
export const NA = "N/A"
