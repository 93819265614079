export function agGridColumnDefs(defaultCellRenderer, viewCellRenderer, t, paymentActivities) {
  const widths = {
    verySmall: 125,
    small: 150,
    medium: 175,
    large: 200,
    veryLarge: 225
  }
  var fields = [
    {
      headerName: t("services:fleetName"),
      field: "fleet_name",
      width: widths.veryLarge,
      cellRendererFramework: defaultCellRenderer
    },
    {
      headerName: t("services:shopOrder"),
      field: "shop_order_id",
      width: widths.veryLarge,
      cellRendererFramework: defaultCellRenderer
    },
    {
      headerName: t("services:shopOrderDate"),
      field: "shop_order_date",
      width: widths.veryLarge,
      cellRendererFramework: defaultCellRenderer
    },
    {
      headerName: t("services:invoiceNumber"),
      field: "id",
      width: widths.veryLarge,
      cellRendererFramework: defaultCellRenderer
    },
    {
      headerName: t("services:amount"),
      field: "amount",
      width: widths.veryLarge,
      cellRendererFramework: defaultCellRenderer
    },
    {
      headerName: "",
      field: "view_details",
      width: widths.veryLarge,
      cellRendererFramework: viewCellRenderer
    }
  ]

  return fields
}

export function agGridRowData(paymentActivities, language) {
  return paymentActivities.map((paymentActivity) => {
    return {
      fleet_name: paymentActivity.fleet_name,
      shop_order_id: paymentActivity.shop_order_id,
      shop_order_date: paymentActivity.shop_order_date,
      id: paymentActivity.id,
      amount: paymentActivity.amount || 0,
      view_details: paymentActivity.id
    }
  })
}

export const NA = "N/A"
