import React, { Component } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { withTranslation } from "react-i18next"
import LoadingError from "../../components/shared/LoadingError"
import { Divider } from "semantic-ui-react"
import ReactSVG from "react-svg"

const RenderPaymentsOptions = ({ pages, onChange, t, activePage, fromApproval }) => {
  let svgStyle = { width: "50px", marginLeft: "25px" }
  return (
    <div className={fromApproval ? "payment-grid  approval-screen" : "payment-grid"}>
      {pages &&
        pages.map((page) => {
          return (
            <label
              key={page.name}
              className={fromApproval ? "payment-card  approval-screen" : "payment-card"}
              onClick={() => page.name !== activePage && onChange(page.name)}
            >
              <input
                name={`${t(page.label)}-payment`}
                className={fromApproval ? "payment-radio  approval-screen" : "payment-radio"}
                type="radio"
                checked={page.name === activePage}
                readOnly
              />
              <span
                className={fromApproval ? "payment-details  approval-screen" : "payment-details"}
              >
                <span>
                  <ReactSVG
                    src={`https://${window.location.hostname}/images/${page.name}-logo.svg`}
                    svgStyle={fromApproval ? svgStyle : {}}
                  />
                </span>
                <span className={fromApproval ? "payment-type approval-screen" : "payment-type"}>
                  {t(page.label)}
                </span>
              </span>
            </label>
          )
        })}
    </div>
  )
}

class PaymentsIndex extends Component {
  static propTypes = {
    isLoading: PropTypes.bool.isRequired,
    isLoadingError: PropTypes.bool.isRequired
  }

  constructor(props) {
    super(props)
    const { pages } = this.props
    this.state = {
      activePage: pages && pages.length === 1 ? pages[0].name : "",
      isSubmitting: false
    }
  }

  handlePageChange = async (page) => {
    const { fromApproval } = this.props
    if (fromApproval) await this.props.onChange(page)
    await this.setState({ activePage: page })
  }

  renderContent() {
    const { t, pages, onChange, fromApproval } = this.props
    const { activePage } = this.state
    const page = pages && pages.find((p) => p.name === activePage)
    return (
      <>
        <div className={fromApproval ? "payment-options approval-screen" : "payment-options"}>
          {pages && (
            <>
              <p>
                <strong>{t("payment:choosePaymentProviderLable")}</strong>
              </p>
              <RenderPaymentsOptions
                key="payment-option"
                onChange={this.handlePageChange}
                pages={pages}
                t={t}
                activePage={activePage}
                fromApproval={fromApproval}
              />
            </>
          )}
        </div>
        {!fromApproval && (
          <>
            <Divider />
            <div className="payment-options-content">
              {!fromApproval && pages && pages.length > 0 ? (
                activePage ? (
                  page.component()
                ) : (
                  <div className="no-content">{t("payment:noPaymentProviderSelected")}</div>
                )
              ) : (
                <div className="no-content">{t("payment:noPaymentProvidersAvailable")}</div>
              )}
            </div>
          </>
        )}
      </>
    )
  }

  render() {
    const { currentUser, isLoading, isLoadingError } = this.props
    return (
      <>
        {!isLoading && isLoadingError ? <LoadingError visible /> : this.renderContent(currentUser)}
      </>
    )
  }
}

const mapStateToProps = (state) => ({
  isLoading: state.application.isLoading,
  isLoadingError: state.application.isLoadingError,
  users: state.users.users,
  fleet: state.application.fleetSessionData || state.fleets.fleet,
  currentUser: state.application.userSessionData || state.users.currentUser,
  language:
    (state.application.userSessionData && state.application.userSessionData.language) ||
    state.users.currentUser
      ? state.users.currentUser.language
      : "",
  fleetPaymentProviders: state.users.currentUser.fleet_payment_providers
})

export default connect(mapStateToProps)(withTranslation(["settings", "payment"])(PaymentsIndex))
