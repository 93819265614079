import React, { Component } from "react"
import { Form, Grid } from "semantic-ui-react"
import TextField from "../../elements/TextField"
import Button from "../../elements/Button"
import Select from "../../elements/Select"
import { sortByAttr } from "../../helpers/arrayHelpers"
import { selectOptions } from "../../helpers/serviceHelpers"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faTimes } from "@fortawesome/pro-light-svg-icons"
import { withTranslation } from "react-i18next"

class AddServiceRow extends Component {
  availableServices() {
    const { services, serviceRows, serviceRow } = this.props

    const selected_service_ids = serviceRows.map((selected_service) => {
      if (selected_service.rowIndex !== serviceRow.rowIndex) {
        return selected_service.service_id
      }
    })

    return services.filter((service) => !selected_service_ids.includes(service.id))
  }

  positionOptions(positions) {
    let options = []
    positions.map((position) => options.push({ id: position, name: position }))
    return options
  }

  render() {
    let { serviceRow, serviceRows, t } = this.props
    const { rowIndex } = serviceRow

    return (
      <Grid columns="equal">
        <Grid.Column width={7}>
          <Form.Field required style={{ display: "flex" }}>
            <Select
              onChange={(event) => this.props.onServiceChange(event.target.value, rowIndex)}
              options={selectOptions(sortByAttr(this.availableServices(), "name"))}
              placeholder={t("selectServicesLabel")}
              defaultValue={serviceRow.service_id}
              value={serviceRow.service_id}
            />
            <label></label>
          </Form.Field>
        </Grid.Column>
        {serviceRow.service && serviceRow.service.positions.length > 0 && (
          <Grid.Column>
            {/* <Form.Field style={{display: "flex"}}> */}
            <Select
              onChange={(event) =>
                this.props.handleCurrentChange("position", event.target.value, rowIndex)
              }
              options={selectOptions(this.positionOptions(serviceRow.service.positions))}
              placeholder={t("selectPositionLabel")}
              defaultValue={serviceRow.position}
              value={serviceRow.position}
            />
            {/* <label></label>
            </Form.Field> */}
          </Grid.Column>
        )}
        <Grid.Column>
          <TextField
            onChange={(event) =>
              this.props.handleCurrentChange("price", event.target.value, rowIndex)
            }
            placeholder={t("priceLabel")}
            value={serviceRow.price}
            required={true}
            type="number"
          />
        </Grid.Column>
        {serviceRows.length > 1 && (
          <Grid.Column>
            <Button
              label={
                <>
                  <span>{t("common:removeLabel")} </span>
                  <FontAwesomeIcon icon={faTimes} style={{ marginTop: "-10px" }} />
                </>
              }
              onClick={() => this.props.handleRemove(rowIndex)}
            />
          </Grid.Column>
        )}
      </Grid>
    )
  }
}

export default withTranslation("services")(AddServiceRow)
