import { call, put, takeEvery } from "redux-saga/effects"

import api from "../../utilities/api"
import * as apiErrorHelpers from "../../helpers/apiErrorHelpers"
import * as sessionHelpers from "../../helpers/sessionHelpers"
import { NOTIFICATION_TYPE } from "../../constants/notification"
export const CARADVISE_RECOMMENDED_URL = "/services/recommended/caradvise"
export const MARK_SERVICES_COMPLETED_URL = "/service_based_reminders"
const INTERVAL_IS_COMPLETED = "/interval_based_reminders"

export function* snoozeMaintenanceReminders(action) {
  try {
    yield put({ type: "VEHICLE_UPCOMING_INTERVAL_SNOOZE_REQUESTED" })

    api.setAuthApi({
      Authorization: sessionHelpers.getApplicationCookie("authentication_token")
    })

    const { vehicleId, value } = action.payload

    const request = {
      url: `/vehicles/${vehicleId}/vehicle_reminders/snooze_reminder`,
      body: { vehicle_id: vehicleId, snoozValue: value }
    }

    const response = yield call(api.utility.post, api.path(request.url), { body: request.body })

    apiErrorHelpers.catchErrors(response.body)

    yield put({
      type: "VEHICLE_UPCOMING_INTERVAL_SNOOZE_SUCCEEDED",
      payload: {
        snoozeMaintenanceReminder: response.body
      }
    })

    const afterRequestData = {
      alertMessage: `Maintenance Reminder Is Snoozed up to ${response.body.reminder_date}`,
      alertType: "success"
    }

    yield call(action.callback, "success", afterRequestData)
  } catch (errors) {
    // eslint-disable-next-line
    errors = apiErrorHelpers.formatInternalErrors(errors)

    yield put({ type: "VEHICLE_UPCOMING_INTERVAL_SNOOZE_FAILED" })

    const afterRequestData = {
      alertMessage: apiErrorHelpers.toString(errors),
      alertType: "error"
    }

    yield call(action.callback, "failure", afterRequestData)

    apiErrorHelpers.consoleLog(errors)
  }
} // function* snoozeMaintenanceReminders

export function* getSnoozedDateForMaintenanceReminder(action) {
  try {
    yield put({ type: "VEHICLE_UPCOMING_INTERVAL_SNOOZED_DATE_REQUESTED" })

    api.setAuthApi({
      Authorization: sessionHelpers.getApplicationCookie("authentication_token")
    })

    const { vehicleIds } = action.payload

    const request = {
      url: `/vehicles/me/vehicle_reminders/snoozed_date?vehicle_ids=${vehicleIds}`
    }

    const response = yield call(api.utility.get, api.path(request.url))

    apiErrorHelpers.catchErrors(response.body)
    yield put({
      type: "VEHICLE_UPCOMING_INTERVAL_SNOOZED_DATE_LOAD_SUCCEEDED",
      payload: {
        snoozedReminderDates: response.body
      }
    })

    const afterRequestData = {
      alertMessage: `The snoozed date list are ${response.body.vehicle_reminders}`,
      alertType: "success",
      snoozedVehicles: response.body.vehicle_reminders
    }

    if (action.callback) {
      yield call(action.callback, "success", afterRequestData)
    }
  } catch (errors) {
    // eslint-disable-next-line
    errors = apiErrorHelpers.formatInternalErrors(errors)

    yield put({ type: "VEHICLE_UPCOMING_INTERVAL_SNOOZED_DATE_LOAD_FAILED" })

    const afterRequestData = {
      alertMessage: apiErrorHelpers.toString(errors),
      alertType: "error"
    }

    yield call(action.callback, "failure", afterRequestData)

    apiErrorHelpers.consoleLog(errors)
  }
} // function* getSnoozedDateForMaintenanceReminder

export function* getCarAdviseRecommendedServices(action) {
  try {
    yield put({ type: "GET_CARADVISE_RECOMMENDED_SERVICES_REQUESTED" })

    api.setAuthApi({
      Authorization: sessionHelpers.getApplicationCookie("authentication_token")
    })

    const { data } = action.payload

    const request = {
      url: CARADVISE_RECOMMENDED_URL,
      body: data
    }

    const response = yield call(api.utility.get, api.path(request.url), { body: request.body })

    apiErrorHelpers.catchErrors(response.body)
    yield put({
      type: "GET_CARADVISE_RECOMMENDED_SERVICES_SUCCEEDED",
      payload: {
        recommendedServices: response.body
      }
    })

    if (action.callback) {
      yield call(action.callback, "success", response.body)
    }
  } catch (errors) {
    // eslint-disable-next-line
    errors = apiErrorHelpers.formatInternalErrors(errors)

    yield put({
      type: "GET_CARADVISE_RECOMMENDED_SERVICES_FAILED",
      payload: {
        recommendedServices: []
      }
    })

    if (action.callback) yield call(action.callback, "failure")

    apiErrorHelpers.consoleLog(errors)
  }
} // function* getCarAdviseRecommendedServices

export function* markServicesCompleted(action) {
  try {
    yield put({ type: "MARK_SERVICES_COMPLETED_REQUESTED" })

    api.setAuthApi({
      Authorization: sessionHelpers.getApplicationCookie("authentication_token")
    })

    const { data } = action.payload

    const request = {
      url: `${MARK_SERVICES_COMPLETED_URL}/${data.vehicle_id}`,
      body: data
    }

    const response = yield call(api.utility.put, api.path(request.url), { body: request.body })

    apiErrorHelpers.catchErrors(response.body)
    yield put({
      type: "MARK_SERVICES_COMPLETED_SUCCEEDED",
      payload: {
        recommendedServices: response.body
      }
    })

    if (action.callback) {
      yield call(action.callback, "success", response.body)
    }
  } catch (errors) {
    // eslint-disable-next-line
    errors = apiErrorHelpers.formatInternalErrors(errors)

    yield put({ type: "MARK_SERVICES_COMPLETED_FAILED" })

    if (action.callback) yield call(action.callback, "failure")

    apiErrorHelpers.consoleLog(errors)
  }
} // function* markServicesCompleted

export function* updateIntervalMarkAsCompleted(action) {
  try {
    yield put({ type: "UPDATE_INTERVAL_MARK_AS_COMPLETED_REQUESTED" })

    api.setAuthApi({
      Authorization: sessionHelpers.getApplicationCookie("authentication_token")
    })

    const { data } = action.payload

    const request = {
      url: `${INTERVAL_IS_COMPLETED}/${data.vehicle_id}`,
      body: data
    }

    const response = yield call(api.utility.put, api.path(request.url), { body: request.body })

    apiErrorHelpers.catchErrors(response.body)
    yield put({
      type: "UPDATE_INTERVAL_MARK_AS_COMPLETED_SUCCEEDED",
      payload: {
        recommendedServices: response.body
      }
    })

    if (action.callback) {
      yield call(action.callback, "success", response.body)
    }
  } catch (errors) {
    // eslint-disable-next-line
    errors = apiErrorHelpers.formatInternalErrors(errors)

    yield put({ type: "UPDATE_INTERVAL_MARK_AS_COMPLETED_FAILED" })

    const afterRequestData = {
      alertMessage: apiErrorHelpers.toString(errors),
      alertType: "error"
    }

    if (action.callback) yield call(action.callback, "failure", errors)

    apiErrorHelpers.consoleLog(errors)
  }
} // function* updateIntervalMarkAsCompleted

export function* notifyMaintenanceReminders(action) {
  try {
    yield put({ type: "MAINTENANCE_REMINDER_NOTIFICATION_UPDATE_REQUESTED" })
    yield put({ type: "SET_CURRENT_USER_LOADING" })

    api.setAuthApi({
      Authorization: sessionHelpers.getApplicationCookie("authentication_token")
    })
    const { userId, preferences, t } = action.payload

    const request = {
      url: `/customers/${userId}/maintenance_preferences`,
      body: {
        text_notification: preferences.text_notification,
        email_notification: preferences.email_notification,
        push_notification: preferences.push_notification
      }
    }

    const response = yield call(api.utility.get, api.path(request.url), { body: request.body })

    apiErrorHelpers.catchErrors(response.body)

    yield put({
      type: "MAINTENANCE_REMINDER_NOTIFICATION_UPDATE_SUCCEEDDED",
      payload: {
        preferences: {
          text_notification: response.body.text_notification,
          email_notification: response.body.email_notification,
          push_notification: response.body.push_notification
        }
      }
    })
    yield put({
      type: "UPDATE_CURRENT_USER_SESSION",
      payload: {
        preferences: {
          text_notification: response.body.text_notification,
          email_notification: response.body.email_notification,
          push_notification: response.body.push_notification
        }
      }
    })
    yield put({ type: "UNSET_CURRENT_USER_LOADING" })

    const afterRequestData = {
      title: t("updateLabel"),
      message: t("notificationsSettingUpdated"),
      type: NOTIFICATION_TYPE.DEFAULT
    }
    yield call(action.callback, NOTIFICATION_TYPE.SUCCESS, afterRequestData)
  } catch (errors) {
    // eslint-disable-next-line
    errors = apiErrorHelpers.formatInternalErrors(errors)

    yield put({ type: "MAINTENANCE_REMINDER_NOTIFICATION_UPDATE_FAILED" })
    yield put({ type: "UNSET_CURRENT_USER_LOADING" })
    const afterRequestData = {
      title: action.payload.t("errorLabel"),
      message: apiErrorHelpers.toString(errors),
      type: NOTIFICATION_TYPE.DANGER
    }

    yield call(action.callback, "failure", afterRequestData)

    apiErrorHelpers.consoleLog(errors)
  }
} // function* notifyMaintenanceReminders

export default function* maintenanceReminderSaga() {
  yield takeEvery("MAINTENANCE_REMINDER_SNOOZE_SAGA", snoozeMaintenanceReminders)
  yield takeEvery("MAINTENANCE_REMINDER_SNOOZED_DATE_SAGA", getSnoozedDateForMaintenanceReminder)
  yield takeEvery("GET_CARADVISE_RECOMMENDED_SERVICES", getCarAdviseRecommendedServices)
  yield takeEvery("MARK_SERVICES_COMPLETED", markServicesCompleted)
  yield takeEvery("UPDATE_INTERVAL_MARK_AS_COMPLETED", updateIntervalMarkAsCompleted)
  yield takeEvery("MAINTENANCE_REMINDER_NOTIFICATION_SAGA", notifyMaintenanceReminders)
}
