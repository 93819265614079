import React, { Component } from "react"
import _ from "lodash"
import { connect } from "react-redux"
import { withTranslation } from "react-i18next"
import PageTitle from "./components/PageTitle"
import { Grid, Header, Icon } from "semantic-ui-react"
import MiniLoader from "../../shared/MiniLoader"
import { Redirect } from "react-router"
import SectionTitle from "./components/SectionTitle"
import Services from "./components/Services"
import ServicesLoadingComponent from "./components/ServicesLoadingComponent"
import { ProgressHUD } from "../../shared/ProgressHUD"
import { navigationPaths } from "../../../constants/paths"
import { formattedSelectedServices } from "../../../helpers/serviceHelpers"
import { Link } from "react-router-dom"
import CustomNotification from "../../shared/CustomNotification"
import Button from "../../../elements/Button"
import { CARADVISE, OEM_RECOMMENDED } from "../../../constants/application"

class MaintenanceScheduleHistoryDetails extends Component {
  constructor(props) {
    super(props)

    this.state = {
      oemRecommendedServices: [],
      caradviseServices: [],
      interval: undefined,
      interval_id: undefined,
      is_completed: false,
      loading: false,
      serviceToBeMarkedAsCompleted: [],
      servicesSelectedToBeBooked: [],
      markAllCompleted: false,
      selectAllChecked: false,
      caradviseServicesLoading: true,
      isChecked: false,
      isCheckedForCarAdvise: false,
      isCheckedForOemRecommended: false,
      showCheckAllCarAdviseRecommended: true,
      initialLoad: false
    }
  }

  getCarAdviseRecommendedServices = (interval) => {
    this.setState({
      caradviseServicesLoading: true
    })
    let currentInterval = this.getCurrentInterval().interval
    let intervalId = this.getCurrentInterval().interval_id

    if (interval) {
      currentInterval = interval
    }

    const data = {
      vehicleId: this.props.vehicle.id,
      interval: currentInterval,
      interval_id: intervalId
    }
    this.props.dispatch({
      type: "GET_CARADVISE_RECOMMENDED_SERVICES",
      payload: { data: data },
      callback: this.afterReceivedRecommended
    })
  }

  pullOemMaintenanceSchedules = () => {
    const { vehicle } = this.props
    this.props.dispatch({
      type: "PULL_OEM_MAINTENANCE_SCHEDULES",
      payload: {
        vehicle: vehicle
      },
      callback: this.afterPullOemSchedules
    })
  }

  afterPullOemSchedules = () => {
    const { initialLoad } = this.state
    if (initialLoad) {
      this.addAllServices(OEM_RECOMMENDED)
    }
  }

  afterReceivedRecommended = (status, data) => {
    if (status == "success") {
      const { initialLoad } = this.state
      const services = data.map((element) => {
        let isCompleted = false
        try {
          isCompleted = element.is_completed
        } catch (e) {}
        return {
          service_id: element.service_id,
          service_name: element.name,
          positions: element.positions,
          is_completed: isCompleted
        }
      })
      const incompleteCaradviseRecommendedServiceIndex = services.findIndex(
        (s) => s.is_completed == false
      )

      this.setState({
        caradviseServices: services,
        showCheckAllCarAdviseRecommended:
          incompleteCaradviseRecommendedServiceIndex == -1 ? false : true,
        caradviseServicesLoading: false,
        loading: false
      })
      if (initialLoad) {
        this.addAllServices(CARADVISE)
      }
      this.setState({ initialLoad: false })
    } else {
      this.setState({
        caradviseServicesLoading: false,
        loading: false,
        caradviseServices: [],
        showCheckAllCarAdviseRecommended: false
      })
    }
  }

  async componentDidMount() {
    const currentInterval = this.getCurrentInterval()
    this.setState({ initialLoad: !currentInterval.is_completed })
    this.getCarAdviseRecommendedServices()
    if (!currentInterval.is_completed) {
      this.addAllServices(OEM_RECOMMENDED)
    }
    window.addEventListener("resize", this.handleResize)
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleResize)
  }

  handleResize = () => {
    const windowInnerWidth = window.innerWidth
    const windowOuterWidth = window.outerWidth
    this.props.handleResize(windowInnerWidth, windowOuterWidth)
  }

  updateInterval = (interval) => {
    this.setState(
      {
        interval: interval,
        serviceToBeMarkedAsCompleted: [],
        servicesSelectedToBeBooked: [],
        markAllCompleted: false,
        selectAllChecked: false,
        isChecked: false,
        isCheckedForCarAdvise: false,
        isCheckedForOemRecommended: false
      },
      () => {
        const currentInterval = this.getCurrentInterval()
        if (!currentInterval.is_completed) {
          this.addAllServices(OEM_RECOMMENDED)
        }
        this.setState({ initialLoad: !currentInterval.is_completed })
      }
    )
    this.getCarAdviseRecommendedServices(interval)
  }

  hasNextInterval = () => {
    const { oemServices } = this.props
    const totalOemLength = oemServices.length
    const currentInterval = this.getCurrentInterval()
    const currentIntervalIndex = oemServices.findIndex(
      (service) => currentInterval.interval_id == service.interval_id
    )
    if (currentIntervalIndex != -1) {
      const hasNext = currentIntervalIndex < totalOemLength - 1
      if (hasNext) {
        const nextInterval = oemServices[currentIntervalIndex + 1].interval
        this.updateInterval(nextInterval)
      }
    }
  }

  markServicesCompleted = async () => {
    this.setState({
      loading: true
    })
    const { serviceToBeMarkedAsCompleted, selectAllChecked } = this.state
    const { t, currentUser } = this.props
    const service_completed_details = serviceToBeMarkedAsCompleted.map((s) => {
      return {
        id: s.service_id,
        is_completed: true
      }
    })
    const servicesArray = serviceToBeMarkedAsCompleted.map((service) => {
      return {
        id: service.service_id,
        name: service.service_name
      }
    })
    const data = {
      vehicle_id: this.props.vehicle.id,
      interval_id: this.getCurrentInterval().interval_id,
      service_completed_details: service_completed_details,
      are_all_completed: selectAllChecked,
      customer_id: currentUser.id
    }
    const key = "name"

    await this.props.dispatch({
      type: "MARK_SERVICES_COMPLETED",
      payload: { data: data },
      callback: this.afterCompletedServices
    })
  }

  afterCompletedServices = (status) => {
    if (status == "success") {
      const { selectAllChecked } = this.state
      const { t } = this.props
      if (selectAllChecked) {
        this.hasNextInterval()
      }
      CustomNotification("success", t("completed-success-title"), t("completed-success-message"), t)
      const currentInterval = this.getCurrentInterval()
      this.setState(
        {
          serviceToBeMarkedAsCompleted: [],
          servicesSelectedToBeBooked: [],
          initialLoad: !currentInterval.is_completed,
          isCheckedForCarAdvise: false,
          isCheckedForOemRecommended: false
        },
        () => {
          this.pullOemMaintenanceSchedules()
          this.getCarAdviseRecommendedServices(this.getCurrentInterval().interval)
        }
      )
    }
  }

  addorRemoveServices = (service, position = undefined) => {
    const { servicesSelectedToBeBooked, caradviseServices } = this.state
    const services = servicesSelectedToBeBooked

    if (services.length == 0) {
      if (position !== undefined) {
        const newService = {
          ...service,
          positions: [position]
        }
        services.push(newService)
      }
      if (position === undefined) {
        services.push(service)
      }
    } else {
      const serviceIndex = services.findIndex((s) => s.service_id === service.service_id)
      if (serviceIndex == -1) {
        if (position !== undefined) {
          const newService = {
            ...service,
            positions: [position]
          }
          services.push(newService)
        }
        if (position === undefined) {
          services.push(service)
        }
      } else {
        services.splice(serviceIndex, 1)
        if (position !== undefined) {
          const newService = {
            ...service,
            positions: [position]
          }
          services.push(newService)
        }
      }
    }
    if (services) {
      this.setState({
        isChecked:
          services.length ===
          caradviseServices.length +
            this.getCurrentInterval().services.filter((x) => {
              if (!x.is_completed) {
                return x
              }
            }).length
            ? true
            : false,
        isCheckedForCarAdvise:
          services.filter((x) => caradviseServices.find((i) => x.service_id === i.service_id))
            .length ===
          caradviseServices.filter((x) => {
            if (!x.is_completed) {
              return x
            }
          }).length
            ? true
            : false,
        isCheckedForOemRecommended:
          services.filter((x) =>
            this.getCurrentInterval().services.find((i) => x.service_id === i.service_id)
          ).length ===
          this.getCurrentInterval().services.filter((x) => {
            if (!x.is_completed) {
              return x
            }
          }).length
            ? true
            : false
      })
    }
    this.setState({
      servicesSelectedToBeBooked: services
    })
  }

  onMarkasCompletedCheckboxClick(service) {
    const { serviceToBeMarkedAsCompleted, caradviseServices } = this.state
    const servicesArray = serviceToBeMarkedAsCompleted
    const oemRecommendedServices = this.getCurrentInterval().services
    const oemServicesCompleted = oemRecommendedServices.filter((s) => s.is_completed)
    const caradviseServicesCompleted = caradviseServices.filter((s) => {
      if (s.hasOwnProperty("is_completed") && s.is_completed) {
        return s
      }
    })
    if (oemServicesCompleted && oemServicesCompleted.length > 0) {
      servicesArray.push(...oemServicesCompleted)
    }
    if (caradviseServicesCompleted && caradviseServicesCompleted.length > 0) {
      servicesArray.push(...caradviseServicesCompleted)
    }

    if (serviceToBeMarkedAsCompleted.length == 0) {
      servicesArray.push(service)
    } else {
      const index = serviceToBeMarkedAsCompleted.findIndex(
        (s) => s.service_id == service.service_id
      )
      if (index == -1) {
        servicesArray.push(service)
      } else {
        servicesArray.splice(index, 1)
        this.setState({
          selectAllChecked: false
        })
      }
    }
    this.setState({
      serviceToBeMarkedAsCompleted: servicesArray,
      selectAllChecked:
        [...new Set(servicesArray.map((item) => item.service_id))].length ===
        caradviseServices.length + oemRecommendedServices.length
          ? true
          : false
    })
  }

  getCurrentInterval = () => {
    const { oemServices } = this.props

    const { interval } = this.state
    let currentInterval
    if (interval == undefined) {
      currentInterval =
        oemServices && oemServices.filter((i) => i.interval_id === parseInt(this.props.id))[0]
    } else {
      currentInterval = oemServices && oemServices.filter((i) => i.interval == interval)[0]
    }
    return currentInterval
  }

  selectAllToggle = () => {
    const { caradviseServices, selectAllChecked } = this.state

    const oem = this.getCurrentInterval().services.slice()
    const crs = caradviseServices.slice()
    const services = [...oem, ...crs]
    if (selectAllChecked) {
      this.setState({
        serviceToBeMarkedAsCompleted: []
      })
    } else {
      this.setState({
        serviceToBeMarkedAsCompleted: services
      })
    }
    this.setState({
      selectAllChecked: !selectAllChecked
    })
  }

  addAllServices = (serviceTag) => {
    const {
      isCheckedForCarAdvise,
      servicesSelectedToBeBooked,
      caradviseServices,
      isCheckedForOemRecommended
    } = this.state
    if (serviceTag === CARADVISE) {
      if (isCheckedForCarAdvise) {
        this.setState({
          servicesSelectedToBeBooked: servicesSelectedToBeBooked.filter(
            (x) => !caradviseServices.find((i) => x.service_id === i.service_id)
          )
        })

        this.setState({ isCheckedForCarAdvise: !isCheckedForCarAdvise })
      } else {
        this.setState({
          servicesSelectedToBeBooked: [
            ...servicesSelectedToBeBooked,
            ...caradviseServices.filter((x) => {
              if (!x.is_completed) {
                return x
              }
            })
          ]
        })

        this.setState({ isCheckedForCarAdvise: !isCheckedForCarAdvise })
      }
    }
    if (serviceTag === OEM_RECOMMENDED) {
      if (isCheckedForOemRecommended) {
        this.setState({
          servicesSelectedToBeBooked: servicesSelectedToBeBooked.filter(
            (x) => !this.getCurrentInterval().services.find((i) => x.service_id === i.service_id)
          )
        })
        this.setState({ isCheckedForOemRecommended: !isCheckedForOemRecommended })
      } else {
        this.setState({
          servicesSelectedToBeBooked: [
            ...servicesSelectedToBeBooked,
            ...this.getCurrentInterval().services.filter((x) => {
              if (!x.is_completed) {
                return x
              }
            })
          ]
        })
        this.setState({ isCheckedForOemRecommended: !isCheckedForOemRecommended })
      }
    }
  }

  render() {
    const {
      caradviseServices,
      caradviseServicesLoading,
      oemRecommendedServices,
      isCheckedForCarAdvise,
      isCheckedForOemRecommended,
      showCheckAllCarAdviseRecommended
    } = this.state

    const {
      oemServices,
      vehicle,
      t,
      showMaintenanceDetails,
      showMaintenanceServiceDetails,
      showMaintenanceHistoryScreen
    } = this.props
    const currentInterval = this.getCurrentInterval()
    const oemIncompleteServiceIndex =
      currentInterval &&
      currentInterval.services.findIndex((s) =>
        s.hasOwnProperty("is_completed") ? s.is_completed == false : true
      )

    const showCheckAllOEMRecommended = oemIncompleteServiceIndex == -1 ? false : true
    let scheduleStatus = ""
    try {
      scheduleStatus = currentInterval && currentInterval.tag
    } catch (e) {
      scheduleStatus = ""
    }
    const isCompleted = currentInterval && currentInterval.is_completed

    const iconBgColor =
      scheduleStatus && scheduleStatus.includes("Past Due") ? "#B91C1C" : "#282828"

    const totalOemLength = oemServices.length
    let hasPreviousInterval = false
    let previousInterval = undefined
    let nextInterval = undefined
    let hasNextInterval = false

    const currentIntervalIndex =
      currentInterval &&
      oemServices &&
      oemServices.findIndex((service) => currentInterval.interval_id == service.interval_id)

    if (currentIntervalIndex == -1 || currentIntervalIndex === undefined) {
      return <Redirect to={{ pathname: navigationPaths.vehicleIndex(), state: 2 }} />
    } else {
      hasPreviousInterval = currentIntervalIndex != 0
      hasNextInterval = currentIntervalIndex < totalOemLength - 1
      if (currentIntervalIndex != 0) {
        previousInterval = oemServices[currentIntervalIndex - 1].interval
      }
      if (hasNextInterval) {
        nextInterval = oemServices[currentIntervalIndex + 1].interval
      }
    }

    const {
      selectAllChecked,
      serviceToBeMarkedAsCompleted,
      markAllCompleted,
      servicesSelectedToBeBooked,
      loading
    } = this.state
    return (
      <>
        {loading && <ProgressHUD />}
        <Grid.Row>
          <PageTitle
            showBack={showMaintenanceDetails}
            onBackClick={(e) => showMaintenanceServiceDetails(e, null)}
            vehicle={vehicle}
            t={t}
            goToHistory={showMaintenanceHistoryScreen}
            showButtons={true}
          />
        </Grid.Row>

        <Grid.Column mobile={4} tablet={8} computer={7}>
          <Grid.Row>
            <div className="miles-header-container">
              {hasPreviousInterval ? (
                <div
                  className="icon-container"
                  onClick={() =>
                    previousInterval != undefined && this.updateInterval(previousInterval)
                  }
                >
                  <Icon className="arrow-icon" name="angle left" />
                </div>
              ) : (
                <div style={{ width: "30px" }} />
              )}

              <Header as="h3" className="services-header">
                {t("services-due-at", { interval: currentInterval.interval })} mi
              </Header>
              {hasNextInterval ? (
                <div className="icon-container" onClick={() => this.updateInterval(nextInterval)}>
                  <Icon className="arrow-icon" name="angle right" />
                </div>
              ) : (
                <div style={{ width: "30px" }} />
              )}
            </div>
          </Grid.Row>
          <Grid.Row style={{ justifyContent: "end" }}>
            {markAllCompleted == false && !isCompleted ? (
              <Header
                as="h5"
                className="services-sub-header"
                onClick={() => {
                  this.setState({
                    serviceToBeMarkedAsCompleted: [],
                    servicesSelectedToBeBooked: [],
                    isCheckedForCarAdvise: false,
                    isCheckedForOemRecommended: false,
                    markAllCompleted: true
                  })
                }}
              >
                {t("mark-as-completed")}
              </Header>
            ) : (
              <div className="mark-all-completed-container">
                <div style={{ flexDirection: "row" }}>
                  {!isCompleted && (
                    <Icon.Group size="large" className="back-icon">
                      <Icon
                        color="black"
                        name={selectAllChecked ? "check circle" : "circle outline"}
                        onClick={() => this.selectAllToggle()}
                      />
                    </Icon.Group>
                  )}

                  {!isCompleted && <span>{t("mark-all-completed")}</span>}
                </div>

                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "center"
                  }}
                >
                  <span
                    disabled={serviceToBeMarkedAsCompleted.length == 0}
                    className=" services-sub-header1"
                    onClick={() => {
                      this.setState({
                        markAllCompleted: false,
                        selectAllChecked: false,
                        serviceToBeMarkedAsCompleted: []
                      })
                    }}
                    style={{ marginRight: "20px", color: "black" }}
                  >
                    {!isCompleted && "Cancel"}
                  </span>
                  <span
                    disabled={serviceToBeMarkedAsCompleted.length == 0}
                    className=" services-sub-header"
                    onClick={() => {
                      if (serviceToBeMarkedAsCompleted && serviceToBeMarkedAsCompleted.length > 0) {
                        this.markServicesCompleted()
                        this.setState({
                          markAllCompleted: false
                        })
                      }
                    }}
                  >
                    {!isCompleted && t("done")}
                  </span>
                </div>
              </div>
            )}
          </Grid.Row>
          <Grid.Row>
            <div className="scroll">
              {currentInterval.services && currentInterval.services.length > 0 ? (
                <>
                  <div
                    style={{
                      cursor: "pointer",
                      alignSelf: "flex-end",
                      textAlign: "right"
                    }}
                  ></div>
                  <SectionTitle
                    title={t("manufacturer-recommended")}
                    count={currentInterval.services.length}
                    bgColor={iconBgColor}
                    completed={isCompleted}
                    checked={isCheckedForOemRecommended}
                    markAllCompleted={markAllCompleted}
                    addAllServices={() => this.addAllServices(OEM_RECOMMENDED)}
                    showSelectAllCheckbox={showCheckAllOEMRecommended}
                  />

                  {currentInterval.services.map((service, index) => {
                    let serviceStatus = false
                    try {
                      serviceStatus = service.is_completed
                    } catch (e) {
                      serviceStatus = false
                    }
                    return (
                      <Services
                        key={index}
                        service={service}
                        completed={serviceStatus || isCompleted}
                        showMarkCompletedCheckBox={serviceStatus || !isCompleted}
                        selectedServices={servicesSelectedToBeBooked}
                        isLast={currentInterval.services.length - 1 == index}
                        addorRemoveServices={this.addorRemoveServices}
                        onMarkasCompletedCheckboxClick={(service) => {
                          this.onMarkasCompletedCheckboxClick(service)
                        }}
                        serviceToBeMarkedAsCompleted={serviceToBeMarkedAsCompleted}
                        markAllCompleted={markAllCompleted}
                      />
                    )
                  })}
                </>
              ) : (
                <div />
              )}
              {caradviseServicesLoading ? (
                <ServicesLoadingComponent />
              ) : caradviseServices && caradviseServices.length > 0 ? (
                <div className="extra-padding">
                  <div>
                    <SectionTitle
                      title={t("you-may-also-need")}
                      count={caradviseServices.length}
                      bgColor={iconBgColor}
                      completed={isCompleted}
                      checked={isCheckedForCarAdvise}
                      markAllCompleted={markAllCompleted}
                      addAllServices={() => this.addAllServices(CARADVISE)}
                      showSelectAllCheckbox={showCheckAllCarAdviseRecommended}
                    />
                    {caradviseServices.map((service, index) => {
                      let serviceStatus = false
                      try {
                        serviceStatus = service.is_completed
                      } catch (e) {
                        serviceStatus = false
                      }
                      return (
                        <Services
                          key={index}
                          service={service}
                          completed={serviceStatus || isCompleted}
                          showMarkCompletedCheckBox={isCompleted}
                          selectedServices={servicesSelectedToBeBooked}
                          isLast={caradviseServices.length - 1 == index}
                          addorRemoveServices={this.addorRemoveServices}
                          onMarkasCompletedCheckboxClick={(service) => {
                            this.onMarkasCompletedCheckboxClick(service)
                          }}
                          serviceToBeMarkedAsCompleted={serviceToBeMarkedAsCompleted}
                          markAllCompleted={markAllCompleted}
                        />
                      )
                    })}
                  </div>
                </div>
              ) : (
                <div className="extra-padding" />
              )}
            </div>
          </Grid.Row>
          <Grid.Row>
            <div className="bottom-fixed">
              <Header as="h3">
                {t("total-services", { count: servicesSelectedToBeBooked.length })}
              </Header>
              <Link
                to={{
                  pathname: navigationPaths.serviceRequestNew(),
                  state: {
                    previousFormData: {
                      order_services: formattedSelectedServices({
                        services: servicesSelectedToBeBooked,
                        vehicle
                      }),
                      vehicle_id: vehicle && vehicle.id,
                      vehicle: vehicle
                    }
                  }
                }}
                onClick={
                  loading || markAllCompleted || servicesSelectedToBeBooked.length == 0
                    ? (event) => event.preventDefault()
                    : ""
                }
              >
                <Button
                  className="right floated"
                  disabled={loading || markAllCompleted || servicesSelectedToBeBooked.length == 0}
                  label={loading ? <MiniLoader /> : t("book-services")}
                />
              </Link>
            </div>
          </Grid.Row>
        </Grid.Column>
        <div className="bottom-fixed-in-mobile">
          <div className="fixed-content-summary">
            <div className="manufacturer-title1">
              <Header as="h3" style={{ marginTop: "5px" }}>
                {t("total-services", { count: servicesSelectedToBeBooked.length })}
              </Header>
              <Link
                to={{
                  pathname: navigationPaths.serviceRequestNew(),
                  state: {
                    previousFormData: {
                      order_services: formattedSelectedServices({
                        services: servicesSelectedToBeBooked,
                        vehicle
                      }),
                      vehicle_id: vehicle && vehicle.id,
                      vehicle: vehicle
                    }
                  }
                }}
              >
                <Button
                  className="right floated"
                  disabled={loading || markAllCompleted || servicesSelectedToBeBooked.length == 0}
                  label={loading ? <MiniLoader /> : t("book-services")}
                />
              </Link>
            </div>
          </div>
        </div>
      </>
    )
  }
}

function mapStateToProps(state) {
  let currentUser = state.application.userSessionData || state.users.currentUser
  let vehicle = state.vehicles.vehicle || {}
  const carAdviseRecommended = state.maintenanceReminderReducers.recommendedServices
  const oemServices = vehicle.oemServices ? vehicle.oemServices : []

  return {
    vehicle: vehicle || {},
    currentUser,
    carAdviseRecommended,
    oemServices: oemServices
  }
}

export default connect(mapStateToProps)(
  withTranslation("maintenanceSchedule")(MaintenanceScheduleHistoryDetails)
)
