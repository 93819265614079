import React, { Component, forwardRef } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { Loader, Grid, Divider } from "semantic-ui-react"
import Switch from "../../components/shared/Switch"
import { getStyles } from "../../components/shared/StylesheetInjector"
import { withTranslation } from "react-i18next"
import LoadingError from "../../components/shared/LoadingError"
import LoadingThrobber from "../../components/shared/LoadingThrobber"
import { updateCookie } from "../../helpers/sessionHelpers"
import { NOTIFICATION_TYPE } from "../../constants/notification"
import CustomNotification from "../../components/shared/CustomNotification"

const styles = getStyles()
class NotificationsReminder extends Component {
  state = {
    isSubmitting: this.props.isLoading,
    isTextNotification: this.props.currentUser.preferences.text_notification,
    isEmailNotification: this.props.currentUser.preferences.email_notification
  }

  componentDidMount() {
    if (this.props.forwardedRef) {
      this.props.forwardedRef.current = this.saveReminderPreference
    }
  }

  static propTypes = {
    isLoading: PropTypes.bool.isRequired,
    isLoadingError: PropTypes.bool.isRequired,
    currentUser: PropTypes.object.isRequired
  }

  componentDidUpdate() {
    const { preferences } = this.props.currentUser
    if (preferences.text_notification !== this.state.isTextNotification) {
      this.props.isPreferenceChanged(true)
    } else if (preferences.email_notification !== this.state.isEmailNotification) {
      this.props.isPreferenceChanged(true)
    } else {
      this.props.isPreferenceChanged(false)
    }
  }

  saveReminderPreference = () => {
    const { currentUser, t, onSubmitPreferences, dispatch } = this.props
    const { isTextNotification, isEmailNotification } = this.state
    if (
      currentUser.preferences.text_notification !== isTextNotification ||
      currentUser.preferences.email_notification !== isEmailNotification
    ) {
      onSubmitPreferences(true)
      dispatch({
        type: "MAINTENANCE_REMINDER_NOTIFICATION_SAGA",
        payload: {
          userId: currentUser.id,
          t: t,
          preferences: {
            text_notification: isTextNotification,
            email_notification: isEmailNotification,
            push_notification: false
          }
        },
        callback: this.afterRequest.bind(this)
      })
    }
  }

  afterRequest(status, data) {
    this.props.onSubmitPreferences(false)
    this.props.isPreferenceChanged(false)
    if (status === NOTIFICATION_TYPE.SUCCESS) {
      this.onRequestSuccess(data)
    } else {
      this.onRequestFailure(data)
    }
  }

  onRequestFailure(data) {
    const { type, title, message } = data
    const { t } = this.props
    return CustomNotification(type, title, message, t)
  }

  onRequestSuccess(data) {
    const { type, title, message } = data
    const { t } = this.props
    return CustomNotification(type, title, message, t)
  }

  shouldRenderContent() {
    const { isLoading, isLoadingError } = this.props
    return !isLoading && !isLoadingError
  }

  renderContent() {
    const { isLoading, currentUser, t } = this.props
    const { isTextNotification, isEmailNotification } = this.state
    if (isLoading) return <Loader />

    if (this.shouldRenderContent()) {
      return (
        <div className="notifications-reminder-container">
          <h3>{t("maintenanceReminderLabel")}</h3>
          <Grid className="maintenance-reminders-notifications">
            <Grid.Row>
              <Grid.Column mobile={14} tablet={14} computer={8}>
                <p className="lable">{t("textMessagesLabel")}</p>
                <p className="label-meta">{t("textMessageDesription")}</p>
              </Grid.Column>
              <Grid.Column width={2}>
                <Switch
                  name="textMessage"
                  isOn={isTextNotification}
                  onColor={styles.primaryColor}
                  handleToggle={() => {
                    this.setState({ isTextNotification: !isTextNotification })
                  }}
                />
              </Grid.Column>
            </Grid.Row>
            <Divider section />
            <Grid.Row>
              <Grid.Column mobile={14} tablet={14} computer={8}>
                <p className="lable">{t("emailsLabel")}</p>
                <p className="label-meta">{t("emailsDescriptionLabel")}</p>
                <p className="label-meta">{currentUser.email}</p>
              </Grid.Column>
              <Grid.Column width={2}>
                <Switch
                  name="emails"
                  isOn={isEmailNotification}
                  onColor={styles.primaryColor}
                  handleToggle={() => {
                    this.setState({ isEmailNotification: !isEmailNotification })
                  }}
                />
              </Grid.Column>
            </Grid.Row>
            <Divider section />
          </Grid>
        </div>
      )
    } else {
      return null
    }
  }

  render() {
    const { isLoading, isLoadingError } = this.props
    return (
      <>
        <LoadingThrobber visible={isLoading} />

        <LoadingError visible={!isLoading && isLoadingError} />

        {this.renderContent()}
      </>
    )
  }
} // class NotificationsReminder

const mapStateToProps = (state) => ({
  isLoading: state.application.isCurrentUserUpdating,
  isLoadingError: state.application.isLoadingError,
  currentUser: state.application.userSessionData || state.users.currentUser
})
const WrappedNotificationsReminder = connect(mapStateToProps)(
  withTranslation("notificationsReminder")(NotificationsReminder)
)

const NotificationsReminderRef = forwardRef((props, ref) => (
  <WrappedNotificationsReminder {...props} forwardedRef={ref} />
))

export default NotificationsReminderRef
