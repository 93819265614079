export const initialState = {
  items: [],
  isLoading: false,
  isApproverNotesLoading: false
}

export default function (state = initialState, action) {
  // eslint-disable-next-line
  const { payload, type } = action

  switch (type) {
    case "SHOP_ORDER_ACTIVITY_ITEMS_SET_LOADING":
      return {
        ...state,
        isLoading: true
      }
    case "SHOP_ORDER_ACTIVITY_ITEMS_UNSET_LOADING":
      return {
        ...state,
        isLoading: false
      }
    case "SHOP_ORDER_APPROVAL_NOTES_SET_LOADING":
      return {
        ...state,
        isApproverNotesLoading: true
      }
    case "SHOP_ORDER_APPROVAL_NOTES_UNSET_LOADING":
      return {
        ...state,
        isApproverNotesLoading: false
      }
    case "SHOP_ORDER_ACTIVITY_ITEMS_LOAD_SUCCEEDED":
      return {
        ...state,
        items: payload.items || []
      }
    case "APPROVERS_NOTES_LOAD_SUCCEEDED":
      return {
        ...state,
        approvalsNotes: payload.approverNotes || []
      }
    case "APPROVERS_NOTES_CREATE_SUCCEEDED":
      return {
        ...state,
        approvalsNotes: [payload.approverNotes, ...state.approvalsNotes]
      }
    default:
      return state
  }
}
