import React, { Component } from "react"
import PropTypes from "prop-types"

import { Segment, Grid } from "semantic-ui-react"
import { withTranslation } from "react-i18next"
import ScheduleServiceHeader from "../../components/shared/ScheduleServiceHeader"
import { isFleetMaintenanceHubCanada, isFleetcor } from "../../helpers/affiliationHelpers"

class Stats extends Component {
  static propTypes = {
    fleet: PropTypes.object.isRequired,
    vehicles: PropTypes.array.isRequired
  }

  formattedYtdMaintenanceCost() {
    const { fleet } = this.props

    if (fleet.ytd_maintenance_cost) {
      return isFleetMaintenanceHubCanada()
        ? `$${parseFloat(fleet.ytd_maintenance_cost).toFixed(2)} CAD`
        : `$${fleet.ytd_maintenance_cost}`
    } else {
      return "-"
    }
  }
  formattedVehicleCostPerMile() {
    const { fleet } = this.props

    if (fleet.cost_per_mile) {
      return isFleetMaintenanceHubCanada()
        ? `$${parseFloat(fleet.cost_per_mile).toFixed(2)} CAD`
        : `$${fleet.cost_per_mile}`
    } else {
      return "-"
    }
  }

  vehicleTotalCount() {
    const { vehicles } = this.props
    var active_vehicles
    if (isFleetcor())
      active_vehicles = vehicles && vehicles.filter((vehicle) => vehicle.fleetcor_enrolled === true)
    else active_vehicles = vehicles.filter((vehicle) => vehicle.is_active === true)
    return active_vehicles.length
  }

  unenrolledVehicleTotalCount() {
    const { vehicles } = this.props
    const unenrolled_vehicles = vehicles.filter((vehicle) => vehicle.fleetcor_enrolled === false)
    return unenrolled_vehicles.length
  }

  render() {
    const { t } = this.props
    // For some reason span is needed before Header to make it keep it's top padding. Not sure why.
    return (
      <React.Fragment>
        <span />
        <ScheduleServiceHeader header={t("common:vehiclesLabel")} />
        <Grid columns="equal" stretched stackable>
          <Grid.Row>
            <Grid.Column>
              <Segment vertical className={"dash-stats-item"}>
                {this.formattedYtdMaintenanceCost()}
                <span>{t("maintenanceCostYTDLabel")} </span>
              </Segment>
            </Grid.Column>
            <Grid.Column>
              <Segment vertical className={"dash-stats-item"}>
                {this.formattedVehicleCostPerMile()}
                <span>
                  {" "}
                  {isFleetMaintenanceHubCanada()
                    ? t("averageCostPerKilometerLabel")
                    : t("averageCostPerMileLabel")}
                </span>
              </Segment>
            </Grid.Column>
            <Grid.Column>
              <Segment vertical className={"dash-stats-item"}>
                {this.vehicleTotalCount()}
                <span>{isFleetcor() ? "Enrolled Vehicles" : t("totalVehiclesLabel")} </span>
              </Segment>
            </Grid.Column>
            {isFleetcor() ? (
              <Grid.Column>
                <Segment vertical className={"dash-stats-item"}>
                  {this.unenrolledVehicleTotalCount()}
                  <span>Unenrolled Vehicles</span>
                </Segment>
              </Grid.Column>
            ) : (
              ""
            )}
          </Grid.Row>
        </Grid>
      </React.Fragment>
    )
  }
} // class Stats

export default withTranslation("vehicleIndex")(Stats)
