import { CURRENT_USER, FLEET, FMC } from "../../constants/application"

export const initialState = {
  isLoading: false,
  isLoadingError: false,
  isInnerLoading: false,
  mode: sessionStorage.getItem("mode") || FLEET,
  userSessionData: JSON.parse(sessionStorage.getItem("current_user")),
  fleetSessionData: JSON.parse(sessionStorage.getItem("current_fleet")),
  isCurrentUserUpdating: false
}

export default function (state = initialState, action) {
  // eslint-disable-next-line
  const { payload, type } = action

  switch (type) {
    case "SET_LOADING":
      return {
        ...state,
        isLoading: true
      }
    case "SET_LOADING_ERROR":
      return {
        ...state,
        isLoadingError: true
      }
    case "UNSET_LOADING":
      return {
        ...state,
        isLoading: false
      }
    case "UNSET_LOADING_ERROR":
      return {
        ...state,
        isLoadingError: false
      }
    case "SET_INNER_LOADING":
      return {
        ...state,
        isInnerLoading: true
      }
    case "UNSET_INNER_LOADING":
      return {
        ...state,
        isInnerLoading: false
      }
    case "TOGGLE_MOBILE_MENU":
      return {
        ...state,
        mobileMenuOpen: !state.mobileMenuOpen
      }
    case "FMC_FLIP":
      let mode = action.payload && action.payload.mode
      const newMode = mode || (state.mode == FMC ? FLEET : FMC)
      sessionStorage.setItem("mode", newMode)

      return {
        ...state,
        mode: newMode,
        isLoadingError: false
      }
    case "SET_VEHICLE_LOADING":
      return {
        ...state,
        isVehicleLoading: true
      }
    case "UNSET_VEHICLE_LOADING":
      return {
        ...state,
        isVehicleLoading: false
      }
    case "SET_USER_SESSION_DATA":
      return {
        ...state,
        userSessionData: action.payload
      }
    case "UPDATE_USER_SESSION_DATA":
      const {
        fleet_id,
        firstName,
        lastName,
        email,
        cellPhone,
        name,
        isFMC,
        language,
        zip,
        preferences
      } = action.payload
      const updatedUserSessionData = {
        ...state.userSessionData,
        ...(fleet_id && { fleet_id }),
        ...(name && { name }),
        ...(firstName && { firstName }),
        ...(lastName && { lastName }),
        ...(email && { email }),
        ...(cellPhone && { cellPhone }),
        ...(isFMC && { isFMC }),
        ...(language && { language }),
        ...(zip && { zip }),
        ...(preferences && { preferences })
      }
      sessionStorage.setItem("current_user", JSON.stringify(updatedUserSessionData))
      return {
        ...state,
        userSessionData: updatedUserSessionData
      }
    case "UPDATE_FLEET_COUNT_FOR_CURRENT_USER":
      state.userSessionData.total_fleet_number += 1
      sessionStorage.setItem("current_user", JSON.stringify(state.userSessionData))
      return {
        ...state,
        userSessionData: state.userSessionData
      }
    case "SET_FLEET_SESSION_DATA":
      sessionStorage.setItem("current_fleet", JSON.stringify(action.payload))
      return {
        ...state,
        fleetSessionData: action.payload
      }
    case "SET_CURRENT_USER_LOADING":
      return {
        ...state,
        isCurrentUserUpdating: true
      }
    case "UNSET_CURRENT_USER_LOADING":
      return {
        ...state,
        isCurrentUserUpdating: false
      }
    case "UPDATE_CURRENT_USER_SESSION":
      const currentUser = JSON.parse(sessionStorage.getItem(CURRENT_USER))
      const currentUserNew = { ...currentUser, ...payload }
      sessionStorage.setItem(CURRENT_USER, JSON.stringify(currentUserNew))
      return {
        ...state,
        userSessionData: {
          ...state.userSessionData,
          ...payload
        }
      }
    default:
      return state
  }
}
