export function totalVehicles(fleets) {
  return fleets.reduce((total, fleet) => {
    const fleetsTotal = fleet.vehicles_total || 0
    return total + fleetsTotal
  }, 0)
}

export function totalYtdMaintenanceCosts(fleets) {
  return fleets.reduce((total, fleet) => {
    return total + parseFloat(fleet.ytd_maintenance_cost || 0)
  }, 0)
}

export function selectOptions(fleets, t, options = {}) {
  let vehicleOptions = []

  fleets.forEach((fleet) => {
    vehicleOptions.push({
      text: fleet.name,
      value: fleet.id,
      key: `fleet-${fleet.id}`
    })
  })

  return vehicleOptions
}
