import React from "react"
import InfoLabel from "./InfoLabel"
import { Label, Input, Form, Checkbox, Divider, Dropdown } from "semantic-ui-react"
import DatePicker from "react-datepicker"
import moment from "moment"
import * as stringHelpers from "../../helpers/stringHelpers"
import debounce from "debounce"
import { isFleetcor, isFuelman } from "../../helpers/affiliationHelpers"
import { DRIVER, FLEET_MANAGER, ACCOUNT_OWNER } from "../../constants/roles"

const fleetcor = isFleetcor()
const fuelman = isFuelman()

function DateInput({ label, currentValue, onFieldChange }) {
  let dateVal
  if (currentValue && currentValue._isAMomentObject) dateVal = currentValue
  else if (!currentValue) dateVal = null
  else dateVal = moment(currentValue)

  return (
    <DatePicker
      placeholderText={label}
      customInput={<Input value={dateVal} />}
      selected={dateVal}
      onChange={onFieldChange}
    />
  )
}

function CheckboxFormInput({ data, label, onFieldChange, t }) {
  // Note below we use debounce on onFieldChange. There is some kinda bug in Checkbox
  // Where if you pass in an onChange function it'll get called twice on a click, always going from true to false
  // to true again, or vice versa. Debouncing it seems to fix the issue (with an imperceptible delay.)
  return (
    <React.Fragment>
      <label>{label}:</label>
      <Divider hidden />
      {Object.keys(data).map(
        (key) =>
          !data[key].hidden && (
            <Form.Field>
              <Checkbox
                label={t(data[key].label) || stringHelpers.prettify(key)}
                name={key}
                onChange={debounce(onFieldChange, 100)}
                checked={data[key].value}
              />
            </Form.Field>
          )
      )}
    </React.Fragment>
  )
}

export default function SpecializedFormInput({
  formData,
  onEnterCreateCall,
  field,
  onFieldChange,
  outerProps,
  error,
  t,
  disabled
}) {
  let data = outerProps[field.valueSet]
  let condition =
    window.location.href.indexOf("settings") > -1 &&
    (fleetcor || fuelman) &&
    field.valueSet === "userRoles"
  if (condition && !formData.id) data = data.filter((role) => role.value === DRIVER)
  else if (condition && formData.id) data = data.filter((role) => role.value !== ACCOUNT_OWNER)
  else if ((fleetcor || fuelman) && !formData.id && field.valueSet === "userRoles")
    data = data.filter((role) => role.value !== ACCOUNT_OWNER && role.value !== FLEET_MANAGER)

  if (field.filterOn) {
    let mappingKeys = Object.keys(field.filterOn)

    mappingKeys.forEach((mappingKey) => {
      // Allow filtering on the data based on a formData key to dataset key mapping
      // Example: motorModelID on vehicle - data has multiple distinct motorModelIDs, we only want set that has this motorModelID
      // However in this data set from API, the field is called model_id. So the mapping is set in the filterOn hash.
      data = data.filter((val) => val[field.filterOn[mappingKey]] === formData[mappingKey])
    })
  }

  let input
  const currentValue = formData[field.fieldName]

  if (field.date)
    input = (
      <DateInput
        label={field.placeholder + (field.optional ? `(${t("optionalLabel")})` : "")}
        currentValue={currentValue}
        onFieldChange={onFieldChange}
      />
    )
  else if (!field.valueSet)
    if (typeof currentValue === "object" && currentValue !== null && !currentValue.length)
      return (
        <CheckboxFormInput
          data={formData[field.fieldName]}
          onFieldChange={onFieldChange}
          label={field.placeholder}
          t={t}
        />
      )
    else
      input = (
        <Input
          onChange={onFieldChange}
          placeholder={
            field.placeholder +
            (!field.fieldName.match(/vin/) &&
            !field.fieldName.match(/license_plate_number/) &&
            field.optional
              ? `(${t("optionalLabel")})`
              : "")
          }
          value={currentValue}
          onKeyPress={onEnterCreateCall}
          type={
            field.fieldName.match(/password/)
              ? "password"
              : field.fieldName.match(/vehiclesTotal/)
              ? "number"
              : "text"
          }
          disabled={disabled}
          maxLength={field.fieldName.match(/department_number/) ? "50" : ""}
        />
      )
  else
    input = (
      <Dropdown
        fluid
        search
        clearable
        selection
        placeholder={field.placeholder}
        multiple={Array.isArray(currentValue)}
        options={data}
        value={currentValue}
        disabled={data.length == 0 || disabled}
        onChange={onFieldChange}
      />
    )

  return (
    <Form.Field>
      <InfoLabel field={field} />
      {input}
      {currentValue != 0 && !error && currentValue && field.hint && <Label>{field.hint}</Label>}
      {error && <Label pointing>{error}</Label>}
    </Form.Field>
  )
}
