import { call, put, takeEvery, select } from "redux-saga/effects"

import api from "../../utilities/api"
import * as apiErrorHelpers from "../../helpers/apiErrorHelpers"
import * as sessionHelpers from "../../helpers/sessionHelpers"
import { addOrUpdateSentryContext } from "../../utilities/sentry"
import { setLanguage } from "../../helpers/translationHelpers"
import { CANDA_COUNTRY_CODE, US_COUNTRY_CODE, OBJECT } from "../../constants/users"
import { FMC_FLEET_ADMIN, FMC_FLEET_MANAGER } from "../../constants/roles"
import { isFleetMaintenanceHubCanada } from "../../helpers/affiliationHelpers"

export function* createOrUpdateUser(action) {
  const formData = action.payload

  const verb = formData.id ? "UPDATE" : "CREATE"

  try {
    yield put({
      type: `USER_${verb}_REQUESTED`
    })

    const address_attributes = {
      id: typeof formData.address_id != OBJECT ? formData.address_id : null,
      address: typeof formData.address !== OBJECT ? formData.address : null,
      state: typeof formData.state !== OBJECT ? formData.state : null,
      city: typeof formData.city !== OBJECT ? formData.city : null,
      zip: typeof formData.garaging_zip !== OBJECT ? formData.garaging_zip : null
    }

    api.setAuthApi({
      Authorization: sessionHelpers.getApplicationCookie("authentication_token")
    })
    var request = {
      url: `/fleet_users/${formData.id ? formData.id : ""}`,
      body: {
        id: formData.id,
        firstName: formData.firstName,
        lastName: formData.lastName,
        password: formData.password,
        password_confirmation: formData.password_confirmation,
        email: formData.email,
        cellPhone: formData.cellPhone,
        other_id: formData.other_id,
        roles: "role" in formData ? [formData.role] : formData.roles,
        affiliation_id: formData.affiliationId,
        approval_fleet_ids: formData.approvalFleetIds,
        payment_id: formData.payment_id,
        text_notification_preference_attributes: formData.text_notification_preference_attributes,
        email_notification_preference_attributes: formData.email_notification_preference_attributes,
        language: formData.language ? formData.language : null,
        country: isFleetMaintenanceHubCanada() ? CANDA_COUNTRY_CODE : US_COUNTRY_CODE
      }
    }
    if (formData.zip != "" && formData.zip != null) {
      request["body"]["zip"] = formData.zip
    }
    if (Object.keys(address_attributes).length > 0) {
      if (
        address_attributes.address_attributes != null ||
        address_attributes.zip != null ||
        address_attributes.city != null ||
        address_attributes.state != null
      )
        request["body"]["address_attributes"] = address_attributes
    }
    if (window.location.href.indexOf("fmc_users") <= -1) {
      request["body"]["vehicle_ids"] = formData.vehicle_ids
    }
    const response = yield call(
      formData.id ? api.utility.put : api.utility.post,
      api.path(request.url),
      { body: request.body }
    )

    apiErrorHelpers.catchErrors(response.body)
    const { language = "", country = "" } = response.body
    language && setLanguage(language, country)

    yield put({
      type: `USER_${verb}_SUCCEEDED`,
      payload: {
        user: response.body
      }
    })

    if (
      formData.id &&
      formData.id.toString() === sessionHelpers.getApplicationCookie("current_user_id")
    ) {
      addOrUpdateSentryContext(response.body)
      var isFMC =
        response.body &&
        response.body.roles &&
        response.body.roles.find(
          (role) => role === "FMC" || role === FMC_FLEET_ADMIN || role === FMC_FLEET_MANAGER
        )
      const {
        firstName,
        lastName,
        email,
        cellPhone,
        zip,
        email_notification_preference,
        text_notification_preference
      } = response.body
      yield put({
        type: "UPDATE_USER_SESSION_DATA",
        payload: {
          firstName: firstName,
          lastName: lastName,
          email: email,
          cellPhone: cellPhone,
          isFMC: isFMC,
          zip: zip,
          language: language,
          email_notification_preference: email_notification_preference,
          text_notification_preference: text_notification_preference
        }
      })
      // yield put({
      //   type: "CURRENT_USER_LOAD_SUCCEEDED",
      //   payload: {
      //     currentUser: response.body
      //   }
      // })
    }

    const afterRequestData = {
      alertMessage: `User ${formData.id ? "updated" : "created"}`,
      alertType: "success"
    }

    yield call(action.callback, "success", afterRequestData)
  } catch (errors) {
    // eslint-disable-next-line
    errors = apiErrorHelpers.formatInternalErrors(errors)

    yield put({ type: `USER_${verb}_FAILED` })

    const afterRequestData = {
      alertMessage: apiErrorHelpers.toString(errors),
      alertType: "error"
    }

    yield call(action.callback, "failure", afterRequestData)

    apiErrorHelpers.consoleLog(errors)
  }
} // function* createOrUpdateUser

export function* updateShellUser(action) {
  const formData = action.payload

  try {
    yield put({
      type: "SHELL_USER_UPDATE_REQUESTED"
    })

    const request = {
      url: `/shell_customers/${formData.id}`,
      body: {
        firstName: formData.firstName,
        lastName: formData.lastName,
        email: formData.email,
        cellPhone: formData.cellPhone,
        fleet_name: formData.fleetName,
        auth_token: formData.authToken,
        geotab_database_name: formData.geotabDatabaseName
      }
    }

    const response = yield call(api.utility.put, api.path(request.url), { body: request.body })

    apiErrorHelpers.catchErrors(response.body)

    yield put({
      type: "SHELL_USER_UPDATE_SUCCEEDED"
    })

    const afterRequestData = {
      authenticationToken: response.body.authentication_token
    }

    yield call(action.callback, "success", afterRequestData)
  } catch (errors) {
    // eslint-disable-next-line
    errors = apiErrorHelpers.formatInternalErrors(errors)

    yield put({ type: "SHELL_USER_UPDATE_FAILED" })

    const afterRequestData = {
      alertMessage: apiErrorHelpers.toString(errors),
      alertType: "error"
    }

    yield call(action.callback, "failure", afterRequestData)

    apiErrorHelpers.consoleLog(errors)
  }
} // function* updateShellUser

export function* deleteUser(action) {
  try {
    yield put({ type: "USER_DELETE_REQUESTED" })

    api.setAuthApi({
      Authorization: sessionHelpers.getApplicationCookie("authentication_token")
    })

    const userId = action.payload.userId

    const request = {
      url: `/fleet_users/${userId}`,
      body: {}
    }

    const response = yield call(api.utility.del, api.path(request.url), { body: request.body })

    apiErrorHelpers.catchErrors(response.body)

    yield put({
      type: "USER_DELETE_SUCCEEDED",
      payload: {
        user: response.body
      }
    })

    const afterRequestData = {
      alertMessage: "User deleted",
      alertType: "success"
    }

    yield call(action.callback, "success", afterRequestData)
  } catch (errors) {
    // eslint-disable-next-line
    errors = apiErrorHelpers.formatInternalErrors(errors)

    yield put({ type: "USER_DELETE_FAILED" })

    const afterRequestData = {
      alertMessage: apiErrorHelpers.toString(errors),
      alertType: "error"
    }

    yield call(action.callback, "failure", afterRequestData)

    apiErrorHelpers.consoleLog(errors)
  }
} // function* deleteUser

export function* deactivateUser(action) {
  try {
    yield put({ type: "USER_DEACTIVATE_REQUESTED" })

    api.setAuthApi({
      Authorization: sessionHelpers.getApplicationCookie("authentication_token")
    })

    const userId = action.payload.userId
    const request = {
      url: `/fleet_users/${userId}/deactivate`,
      body: {}
    }

    const response = yield call(api.utility.put, api.path(request.url), { body: request.body })
    apiErrorHelpers.catchErrors(response.body)

    yield put({
      type: "USER_UPDATE_SUCCEEDED",
      payload: {
        user: response.body
      }
    })

    const afterRequestData = {
      alertMessage: "User deactivated",
      alertType: "success"
    }

    yield call(action.callback, "success", afterRequestData)
  } catch (errors) {
    // eslint-disable-next-line
    errors = apiErrorHelpers.formatInternalErrors(errors)

    yield put({ type: "USER_DEACTIVATE_FAILED" })

    const afterRequestData = {
      alertMessage: apiErrorHelpers.toString(errors),
      alertType: "error"
    }

    yield call(action.callback, "failure", afterRequestData)

    apiErrorHelpers.consoleLog(errors)
  }
} // function* deactivateUser

export function* loadCurrentUser(action) {
  try {
    const payload = action.payload || {}
    if (!payload.skipLoadingCalls) {
      yield put({ type: "SET_LOADING" })
      yield put({ type: "UNSET_LOADING_ERROR" })
    }

    api.setAuthApi({
      Authorization: sessionHelpers.getApplicationCookie("authentication_token")
    })

    yield put({ type: "CURRENT_USER_LOAD_REQUESTED" })

    const userId = sessionHelpers.getApplicationCookie("current_user_id")

    const userRequest = {
      url: `/fleet_users/${userId}`,
      body: {}
    }

    const userResponse = yield call(api.utility.get, api.path(userRequest.url), {
      body: userRequest.body
    })

    apiErrorHelpers.catchErrors(userResponse.body)

    addOrUpdateSentryContext(userResponse.body)
    const { language = "", country = "" } = userResponse.body
    language && setLanguage(language, country)

    yield put({
      type: "CURRENT_USER_LOAD_SUCCEEDED",
      payload: {
        currentUser: userResponse.body
      }
    })

    let currentUser = userResponse.body
    currentUser.isFMC = currentUser.roles.find(
      (role) => role === "FMC" || role === FMC_FLEET_ADMIN || role === FMC_FLEET_MANAGER
    )
    currentUser.total_fleet_number = currentUser.total_fleets.length

    const { total_fleets, ...currentUserNew } = currentUser

    sessionStorage.setItem("current_user", JSON.stringify(currentUserNew))
    yield put({
      type: "SET_USER_SESSION_DATA",
      payload: currentUserNew
    })

    if (!payload.skipLoadingCalls) yield put({ type: "UNSET_LOADING" })
  } catch (errors) {
    // eslint-disable-next-line
    errors = apiErrorHelpers.formatInternalErrors(errors)

    yield put({ type: "LOADING_FAILED" })
    yield put({ type: "SET_LOADING_ERROR" })
    yield put({ type: "UNSET_LOADING" })

    apiErrorHelpers.consoleLog(errors)
  }
} // function* loadCurrentUser

export function* loadCurrentUserPaymentMethods(action) {
  try {
    yield put({ type: "SET_LOADING" })
    yield put({ type: "UNSET_LOADING_ERROR" })

    api.setAuthApi({
      Authorization: sessionHelpers.getApplicationCookie("authentication_token")
    })

    yield put({ type: "CURRENT_USER_LOAD_PAYMENT_METHODS_REQUESTED" })

    const userId = sessionHelpers.getApplicationCookie("current_user_id")

    const userRequest = {
      url: `/fleet_users/${userId}`,
      body: { for_payment_methods: true }
    }

    const response = yield call(api.utility.get, api.path(userRequest.url), {
      body: userRequest.body
    })

    apiErrorHelpers.catchErrors(response.body)

    yield put({
      type: "CURRENT_USER_LOAD_PAYMENT_METHODS_SUCCEEDED",
      payload: {
        data: response.body
      }
    })

    yield put({ type: "UNSET_LOADING" })
  } catch (errors) {
    // eslint-disable-next-line
    errors = apiErrorHelpers.formatInternalErrors(errors)

    yield put({ type: "LOADING_FAILED" })
    yield put({ type: "SET_LOADING_ERROR" })
    yield put({ type: "UNSET_LOADING" })

    apiErrorHelpers.consoleLog(errors)
  }
} // function* loadCurrentUserPaymentMethods

export function* loadUser(action) {
  try {
    yield put({ type: "SET_LOADING" })
    yield put({ type: "UNSET_LOADING_ERROR" })

    api.setAuthApi({
      Authorization: sessionHelpers.getApplicationCookie("authentication_token")
    })

    ////////////////////////////
    //     LOAD USER DATA     //
    ////////////////////////////
    yield put({ type: "USER_LOAD_REQUESTED" })

    const userId = action.payload.userId

    const userRequest = {
      url: `/fleet_users/${userId}`,
      body: {}
    }

    const userResponse = yield call(api.utility.get, api.path(userRequest.url), {
      body: userRequest.body
    })

    apiErrorHelpers.catchErrors(userResponse.body)

    yield put({
      type: "USER_LOAD_SUCCEEDED",
      payload: {
        user: userResponse.body
      }
    })
    ////////////////////////////

    ////////////////////////////
    //    LOAD USERS DATA     //
    ////////////////////////////
    // yield put({ type: "USERS_LOAD_REQUESTED" })
    //
    // const usersRequest = {
    //   url: "/fleet_users",
    //   body: {}
    // }
    //
    // const usersResponse = yield call(api.utility.get, api.path(usersRequest.url), {
    //   body: usersRequest.body
    // })
    //
    // apiErrorHelpers.catchErrors(usersResponse.body)
    //
    // yield put({
    //   type: "USERS_LOAD_SUCCEEDED",
    //   payload: {
    //     users: usersResponse.body
    //   }
    // })
    ////////////////////////////

    ////////////////////////////
    //  LOAD USER ROLES DATA  //
    ////////////////////////////
    yield put({ type: "USER_ROLES_LOAD_REQUESTED" })

    const userRolesRequest = {
      url: "/roles",
      body: {}
    }

    const userRolesResponse = yield call(api.utility.get, api.path(userRolesRequest.url), {
      body: userRolesRequest.body
    })

    apiErrorHelpers.catchErrors(userRolesResponse.body)

    yield put({
      type: "USER_ROLES_LOAD_SUCCEEDED",
      payload: {
        userRoles: userRolesResponse.body
      }
    })
    ////////////////////////////

    ////////////////////////////
    //   LOAD VEHICLES DATA   //
    ////////////////////////////

    yield put({ type: "VEHICLES_LOAD_REQUESTED" })

    const vehiclesRequest = {
      url: "/vehicles",
      body: {}
    }

    const vehiclesResponse = yield call(api.utility.get, api.path(vehiclesRequest.url), {
      body: vehiclesRequest.body
    })

    apiErrorHelpers.catchErrors(vehiclesResponse.body)

    yield put({
      type: "VEHICLES_LOAD_SUCCEEDED",
      payload: {
        vehicles: vehiclesResponse.body
      }
    })

    yield put({
      type: "SET_VEHICLES_DATA_TYPE",
      payload: { dataType: "standard" }
    })
    ////////////////////////////

    ////////////////////////////
    //   LOAD SERVICES DATA   //
    ////////////////////////////
    yield put({ type: "SERVICES_LOAD_REQUESTED" })

    const servicesRequest = {
      url: "/services",
      body: { basic: true }
    }

    const servicesResponse = yield call(api.utility.get, api.path(servicesRequest.url), {
      body: servicesRequest.body
    })

    apiErrorHelpers.catchErrors(servicesResponse.body)

    yield put({
      type: "SERVICES_LOAD_SUCCEEDED",
      payload: {
        services: servicesResponse.body
      }
    })
    ////////////////////////////

    yield put({ type: "UNSET_LOADING" })
  } catch (errors) {
    // eslint-disable-next-line
    errors = apiErrorHelpers.formatInternalErrors(errors)

    yield put({ type: "LOADING_FAILED" })
    yield put({ type: "SET_LOADING_ERROR" })
    yield put({ type: "UNSET_LOADING" })

    apiErrorHelpers.consoleLog(errors)
  }
} // function* loadUser

export function* loadUsers(action) {
  try {
    yield put({
      type: "USERS_LOAD_SUCCEEDED",
      payload: {
        users: []
      }
    })

    const defaultOptions = {
      includeServices: "true"
    }

    const payload = action.payload || {}

    const payloadOptions = payload.options || {}

    const options = {
      ...defaultOptions,
      ...payloadOptions
    }

    const authToken = sessionHelpers.getApplicationCookie("authentication_token")

    api.setAuthApi({
      Authorization: authToken
    })

    if (payload && payload.download && payload.language) {
      const url = `/fleet_users.csv?auth_token=${authToken}&lancode=${payload.language.toUpperCase()}`
      window.open(api.path(url))
      return
    }

    yield put({ type: "SET_LOADING" })
    yield put({ type: "UNSET_LOADING_ERROR" })

    ////////////////////////////
    //    LOAD USERS DATA     //
    ////////////////////////////
    yield put({ type: "USERS_LOAD_REQUESTED" })

    const usersRequest = {
      url: "/fleet_users",
      body: {}
    }

    const usersResponse = yield call(api.utility.get, api.path(usersRequest.url), {
      body: usersRequest.body
    })

    apiErrorHelpers.catchErrors(usersResponse.body)

    const currentUserId = sessionHelpers.getApplicationCookie("current_user_id")
    yield put({
      type: "USERS_LOAD_SUCCEEDED",
      payload: {
        users: usersResponse.body,
        currentUserId: currentUserId
      }
    })
    ////////////////////////////

    ////////////////////////////
    // LOAD CURRENT USER DATA //
    ////////////////////////////

    // yield put({ type: "CURRENT_USER_LOAD_SAGA" })

    ////////////////////////////

    ////////////////////////////
    //  LOAD USER ROLES DATA  //
    ////////////////////////////
    yield put({ type: "USER_ROLES_LOAD_REQUESTED" })

    const userRolesRequest = {
      url: "/roles",
      body: {}
    }

    const userRolesResponse = yield call(api.utility.get, api.path(userRolesRequest.url), {
      body: userRolesRequest.body
    })

    apiErrorHelpers.catchErrors(userRolesResponse.body)

    yield put({
      type: "USER_ROLES_LOAD_SUCCEEDED",
      payload: {
        userRoles: userRolesResponse.body
      }
    })
    ////////////////////////////

    ////////////////////////////
    //   LOAD VEHICLE DATA    //
    ////////////////////////////

    yield put({ type: "VEHICLES_LOAD_REQUESTED" })
    // Initialize an empty object to be used as the request body
    let body = {}

    // Check if there is a current_user object stored in sessionStorage
    const currentFleet = JSON.parse(sessionStorage.getItem("current_fleet"))
    if (currentFleet) {
      body.fleet_id = currentFleet.id
      body.from = "fleet_web"
    }

    // Create a vehiclesRequest object with the URL and body properties
    const vehiclesRequest = {
      url: "/vehicles",
      body: body
    }

    const vehiclesResponse = yield call(api.utility.get, api.path(vehiclesRequest.url), {
      body: vehiclesRequest.body
    })

    apiErrorHelpers.catchErrors(vehiclesResponse.body)

    yield put({
      type: "VEHICLES_LOAD_SUCCEEDED",
      payload: {
        vehicles: vehiclesResponse.body
      }
    })

    yield put({
      type: "SET_VEHICLES_DATA_TYPE",
      payload: { dataType: "standard" }
    })
    ////////////////////////////

    if (options.includeServices === "true") {
      ////////////////////////////
      //   LOAD SERVICES DATA   //
      ////////////////////////////
      yield put({ type: "SERVICES_LOAD_REQUESTED" })

      const servicesRequest = {
        url: "/services",
        body: { basic: true }
      }

      const servicesResponse = yield call(api.utility.get, api.path(servicesRequest.url), {
        body: servicesRequest.body
      })

      apiErrorHelpers.catchErrors(servicesResponse.body)

      yield put({
        type: "SERVICES_LOAD_SUCCEEDED",
        payload: {
          services: servicesResponse.body
        }
      })
      ////////////////////////////
    }

    yield put({ type: "UNSET_LOADING" })
  } catch (errors) {
    // eslint-disable-next-line
    errors = apiErrorHelpers.formatInternalErrors(errors)

    yield put({ type: "LOADING_FAILED" })
    yield put({ type: "SET_LOADING_ERROR" })
    yield put({ type: "UNSET_LOADING" })

    apiErrorHelpers.consoleLog(errors)
  }
} // function* loadUsers

export function* loadFmcUsers(action) {
  try {
    const payload = action.payload || {}
    const authToken = sessionHelpers.getApplicationCookie("authentication_token")

    api.setAuthApi({
      Authorization: authToken
    })

    const currentUserState = (state) => state.users.currentUser
    let currentUser = yield select(currentUserState)
    if (currentUser == null)
      yield call(() => loadCurrentUser({ payload: { skipLoadingCalls: true } }))
    currentUser = yield select(currentUserState)

    if (payload && payload.download && payload.language) {
      const url = `/fleet_fmc_users.csv?affiliation_id=${
        currentUser.affiliation_id
      }&auth_token=${authToken}&lancode=${payload.language.toUpperCase()}`
      window.open(api.path(url))
      return
    }

    yield put({ type: "SET_LOADING" })
    yield put({ type: "UNSET_LOADING_ERROR" })

    ////////////////////////////////
    //    LOAD FMC USERS DATA     //
    ////////////////////////////////
    yield put({ type: "FMC_USERS_LOAD_REQUESTED" })

    const request = {
      url: "/fleet_fmc_users",
      body: {
        affiliation_id: currentUser && currentUser.affiliation_id
      }
    }

    const response = yield call(api.utility.get, api.path(request.url), { body: request.body })

    apiErrorHelpers.catchErrors(response.body)

    yield put({
      type: "FMC_USERS_LOAD_SUCCEEDED",
      payload: {
        fmcUsers: response.body
      }
    })
    //////////////////////////////////
    //  LOAD FMC USER ROLES DATA   //
    ////////////////////////////////
    yield put({ type: "USER_ROLES_LOAD_REQUESTED" })

    const userRolesRequest = {
      url: "/roles?role_type=fmc",
      body: {}
    }

    const userRolesResponse = yield call(api.utility.get, api.path(userRolesRequest.url), {
      body: userRolesRequest.body
    })

    apiErrorHelpers.catchErrors(userRolesResponse.body)

    yield put({
      type: "USER_ROLES_LOAD_SUCCEEDED",
      payload: {
        userRoles: userRolesResponse.body
      }
    })
    ////////////////////////////

    yield put({ type: "UNSET_LOADING" })
  } catch (errors) {
    // eslint-disable-next-line
    errors = apiErrorHelpers.formatInternalErrors(errors)

    yield put({ type: "LOADING_FAILED" })
    yield put({ type: "SET_LOADING_ERROR" })
    yield put({ type: "UNSET_LOADING" })

    apiErrorHelpers.consoleLog(errors)
  }
} // function* loadFmcUsers

export function* loadAccountFleets(action) {
  try {
    yield put({ type: "SET_LOADING" })
    yield put({ type: "UNSET_LOADING_ERROR" })

    api.setAuthApi({
      Authorization: sessionHelpers.getApplicationCookie("authentication_token")
    })
    //////////////////////////////////////
    //     LOAD ACCOUNT FLEETS DATA     //
    /////////////////////////////////////
    yield put({ type: "ACCOUNT_FLEETS_REQUESTED" })

    const account_id = action.payload.accountId

    const userRequest = {
      url: `/fleetcor_users/account_fleets`,
      body: { account_id: account_id }
    }

    const userResponse = yield call(api.utility.get, api.path(userRequest.url), {
      body: userRequest.body
    })

    apiErrorHelpers.catchErrors(userResponse.body)

    yield put({
      type: "ACCOUNT_FLEETS_SUCCEEDED",
      payload: {
        account_fleets: userResponse.body
      }
    })

    yield put({ type: "UNSET_LOADING" })
    const afterRequestData = {}
    yield call(action.callback, "success", afterRequestData)
  } catch (errors) {
    // eslint-disable-next-line
    errors = apiErrorHelpers.formatInternalErrors(errors)

    yield put({ type: "LOADING_FAILED" })
    yield put({ type: "SET_LOADING_ERROR" })
    yield put({ type: "UNSET_LOADING" })

    apiErrorHelpers.consoleLog(errors)
  }
} // function* loadUser

export function* loadAllStates(action) {
  try {
    yield put({ type: "FETCH_ALL_STATES_REQUESTED" })
    const { country } = action.payload

    const request = {
      url: `/states/?country=${country}`
    }
    const response = yield call(api.utility.get, api.path(request.url))

    apiErrorHelpers.catchErrors(response.body)

    yield put({
      type: "FETCH_ALL_STATES_SUCCEEDED",
      payload: {
        states: response.body
      }
    })
  } catch (errors) {
    errors = apiErrorHelpers.formatInternalErrors(errors)

    yield put({ type: "FETCH_ALL_STATES_FAILED" })

    apiErrorHelpers.consoleLog(errors)
  }
}

export function* loadAllVehiclesDocuments(action) {
  try {
    yield put({ type: "USER_VEHICLES_GLOVEBOX_SAGA_REQUESTED" })

    const request = {
      url: `/glovebox_items`
    }
    const response = yield call(api.utility.get, api.path(request.url))

    apiErrorHelpers.catchErrors(response.body)

    yield put({
      type: "USER_VEHICLES_GLOVEBOX_SAGA_SUCCEEDED",
      payload: {
        states: response.body
      }
    })
  } catch (errors) {
    errors = apiErrorHelpers.formatInternalErrors(errors)

    yield put({ type: "USER_VEHICLES_GLOVEBOX_SAGA_FAILED" })

    apiErrorHelpers.consoleLog(errors)
  }
}

export default function* userSaga() {
  yield takeEvery("USER_CREATE_OR_UPDATE_SAGA", createOrUpdateUser)
  yield takeEvery("SHELL_USER_UPDATE_SAGA", updateShellUser)
  yield takeEvery("USER_DELETE_SAGA", deleteUser)
  yield takeEvery("USER_LOAD_SAGA", loadUser)
  yield takeEvery("CURRENT_USER_LOAD_SAGA", loadCurrentUser)
  yield takeEvery("CURRENT_USER_LOAD_PAYMENT_METHODS_SAGA", loadCurrentUserPaymentMethods)
  yield takeEvery("USERS_LOAD_SAGA", loadUsers)
  yield takeEvery("FMC_USERS_LOAD_SAGA", loadFmcUsers)
  yield takeEvery("USER_DEACTIVATE_SAGA", deactivateUser)
  yield takeEvery("ACCOUNT_FLEETS", loadAccountFleets)
  yield takeEvery("FETCH_ALL_STATES", loadAllStates)
  yield takeEvery("USER_VEHICLES_GLOVEBOX_SAGA", loadAllVehiclesDocuments)
}
