import React, { Component } from "react"
import PropTypes from "prop-types"
import * as agGridHelpers from "../../helpers/agGridHelpers"
import { agGridColumnDefs, agGridRowData } from "../../constants/fleets"
import { navigationPaths } from "../../constants/paths"
import { Link, withRouter } from "react-router-dom"
import { Segment, Input, Button } from "semantic-ui-react"
import { AgGridReact } from "ag-grid-react"
import { withTranslation } from "react-i18next"
import CustomNoRowsOverlay from "../shared/CustomNoRowsOverlay"
import { FIELD_AWAITING_APPROVAL_COUNT } from "../../constants/application"
import CustomLoadingOverlay from "../shared/CustomLoadingOverlay"

class FleetList extends Component {
  constructor(props) {
    super(props)
    this.state = {
      page: 1,
      prevBottomDisplayedRowIndex: 0,
      search_text: ""
    }
  }

  static propTypes = {
    fleets: PropTypes.array.isRequired
  }

  handleNavigateToServicePage = async (fleetId) => {
    const { dispatch } = this.props
    dispatch({
      type: "SERVICES_AWAITING_APPROVAL_FLAG",
      payload: { render_active_order_tab: true, fleetId }
    })
  }

  setFilteredItems = () =>
    this.setState({ filteredItems: this.api.getModel().rowsToDisplay.map((r) => r.data) })

  handleNavigate = () => {
    this.props.dispatch({
      type: "SERVICES_AWAITING_APPROVAL_FLAG",
      payload: { render_active_order_tab: false }
    })
  }

  defaultCellRenderer = (cellParams) => {
    const {
      data,
      colDef: { field }
    } = cellParams
    const fleetId = data.id

    return data.awaiting_approval_count > 0 && field === FIELD_AWAITING_APPROVAL_COUNT ? (
      <Link
        className={"ag-grid-link-column"}
        to={navigationPaths.servicesIndex()}
        onClick={() => this.handleNavigateToServicePage(fleetId)}
      >
        <div style={{ color: "red", backgroundColor: "yellow" }}>{cellParams.value}</div>
      </Link>
    ) : (
      <Link
        className={"ag-grid-link-column"}
        to={navigationPaths.fleetEdit(fleetId)}
        onClick={this.handleNavigate}
      >
        <div>{cellParams.value}</div>
      </Link>
    )
  }

  resetFiltering = () => this.api.setFilterModel([])

  onGridReady = (params) => {
    this.api = params.api
    this.columnApi = params.columnApi
  }

  handlePageChange = async ({ api: pageApi, newPage }) => {
    const { page, prevBottomDisplayedRowIndex } = this.state
    const { dispatch } = this.props

    const bottomDisplayedRowIndex = pageApi.paginationProxy.bottomDisplayedRowIndex / 50
    if (newPage && bottomDisplayedRowIndex > prevBottomDisplayedRowIndex) {
      this.api.showLoadingOverlay()
      this.setState({ page: page + 1, prevBottomDisplayedRowIndex: bottomDisplayedRowIndex })
      dispatch({
        type: "FLEETS_LOAD_SAGA",
        payload: {
          page: page + 1
        },
        callback: this.afterLoadServices
      })
    }
  }

  afterLoadServices = (message) => {
    this.api.hideOverlay()
  }

  onTextChange = (event) => {
    const { dispatch } = this.props
    this.setState({ search_text: event.target.value })

    if (event.target.value == "") {
      this.api.showLoadingOverlay()
      dispatch({
        type: "FLEETS_LOAD_SAGA",
        payload: {
          page: 1,
          pageLoad: "false"
        },
        callback: this.afterLoadServices
      })
    }
  }

  onSearch = () => {
    const { dispatch } = this.props
    this.api.showLoadingOverlay()
    dispatch({
      type: "FLEETS_LOAD_SAGA",
      payload: {
        page: 0,
        query: this.state.search_text
      },
      callback: this.afterLoadServices
    })
  }

  render() {
    const { fleets, t } = this.props

    return (
      <Segment
        className={"ag-theme-material"}
        style={{
          height: agGridHelpers.dynamicGridHeight({ items: fleets })
        }}
      >
        <Input
          className="search"
          icon="search"
          onChange={this.onTextChange}
          placeholder="Search fleet..."
        />
        <Button disabled={!(this.state.search_text.length >= 1)} onClick={() => this.onSearch()}>
          Search
        </Button>
        <AgGridReact
          columnDefs={agGridColumnDefs(this.defaultCellRenderer, t)}
          onPaginationChanged={this.handlePageChange}
          defaultColDef={{
            sortable: true,
            filter: true,
            headerComponentParams: {
              template:
                '<div class="ag-cell-label-container" role="presentation">' +
                '  <span ref="eMenu" class="ag-header-icon ag-header-cell-menu-button"></span>' +
                '  <div ref="eLabel" class="ag-header-cell-label" role="presentation">' +
                '    <span ref="eSortOrder" class="ag-header-icon ag-sort-order"></span>' +
                '    <span ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon"></span>' +
                '    <span ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon"></span>' +
                '    <span ref="eSortNone" class="ag-header-icon ag-sort-none-icon"></span>' +
                '    <span ref="eText" class="ag-header-cell-text" role="columnheader" style="white-space: normal;"></span>' +
                '    <span ref="eFilter" class="ag-header-icon ag-filter-icon"></span>' +
                "  </div>" +
                "</div>"
            }
          }}
          gridOptions={{
            onFilterChanged: this.setFilteredItems
          }}
          onGridReady={this.onGridReady}
          rowData={agGridRowData(fleets)}
          rowSelection={"single"}
          frameworkComponents={{
            customNoRowsOverlay: CustomNoRowsOverlay,
            customLoadingOverlay: CustomLoadingOverlay
          }}
          noRowsOverlayComponent={"customNoRowsOverlay"}
          noRowsOverlayComponentParams={{
            translationFile: "fleets",
            label: "noFleetsLabel"
          }}
          cacheQuickFilter={true}
          pagination={true}
          paginationPageSize={agGridHelpers.PAGINATION_AMOUNT}
          suppressMenuHide={true}
          suppressMovableColumns={false}
          suppressCellSelection={true}
          loadingOverlayComponent={"customLoadingOverlay"}
          loadingOverlayComponentParams={{
            loadingMessage: "One moment please"
          }}
        ></AgGridReact>
      </Segment>
    )
  }
} // class FleetList

export default withRouter(withTranslation("fleets")(FleetList))
