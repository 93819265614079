import React, { Component } from "react"
import PropTypes from "prop-types"
import moment from "moment"

import { Table, Checkbox } from "semantic-ui-react"
import ScheduleServiceButton from "../shared/ScheduleServiceButton"
import { isFleetMaintenanceHubCanada } from "../../helpers/affiliationHelpers"
import { DUE_NOW, PAST_DUE } from "../../helpers/maintenanceHelpers"
import { Date_Format } from "../../constants/years"

class MaintenanceScheduleItem extends Component {
  static propTypes = {
    upcomingMaintenance: PropTypes.object.isRequired,
    vehicle: PropTypes.object.isRequired,
    language: PropTypes.object.isRequired
  }

  onCompletedCheckbox = async (completedStatus, id, vehicleId) => {
    let isCompleted
    if (completedStatus === true) {
      isCompleted = false
    } else {
      isCompleted = true
    }

    await this.props.dispatch({
      type: "IS_SERVICE_COMPLETE_SAGA",
      payload: {
        is_completed: isCompleted,
        service_id: id,
        vehicle_id: vehicleId
      },
      callback: async () => {
        await this.props.dispatch({
          type: "UNLOAD_VEHICLE_SERVICES"
        })
        await this.props.dispatch({
          type: "NEXT_MAINTENANCE_INTERVAL_SAGA",
          payload: { vehicleIds: [vehicleId] }
        })
      }
    })
  }

  render() {
    const { upcomingMaintenance, vehicle } = this.props
    const isDomainFleetCanada = isFleetMaintenanceHubCanada()
    const color = upcomingMaintenance.recommendation_state.includes(PAST_DUE)
      ? "red"
      : upcomingMaintenance.recommendation_state.includes(DUE_NOW)
      ? "orange"
      : "green"

    return (
      <Table.Row>
        <Table.Cell>{upcomingMaintenance.name}</Table.Cell>
        <Table.Cell>{isDomainFleetCanada ? vehicle.kilometers : vehicle.miles}</Table.Cell>
        <Table.Cell>
          {isDomainFleetCanada
            ? upcomingMaintenance.interval_kilometers
            : upcomingMaintenance.interval}
        </Table.Cell>
        <Table.Cell style={{ color: color }}>
          <span
            style={{
              borderStyle: "ridge",
              borderWidth: "1px",
              borderColor: color,
              padding: "5%",
              fontSize: "80%"
            }}
          >
            <b>{upcomingMaintenance.recommendation_state}</b>
          </span>
        </Table.Cell>
        <Table.Cell>
          {isDomainFleetCanada
            ? vehicle.kilometers > upcomingMaintenance.interval_kilometers
              ? upcomingMaintenance.interval_kilometers - vehicle.kilometers
              : ""
            : vehicle.miles > upcomingMaintenance.interval
            ? upcomingMaintenance.interval - vehicle.miles
            : ""}
        </Table.Cell>
        <Table.Cell width={2}>
          {moment(upcomingMaintenance.estimated_maintenance_date).format(Date_Format.MM_DD_YYYY)}
        </Table.Cell>
        <Table.Cell>
          {upcomingMaintenance.name === "Oil Change" ? (
            <Checkbox disabled />
          ) : (
            <div
              onClick={() =>
                this.onCompletedCheckbox(
                  upcomingMaintenance.is_completed,
                  upcomingMaintenance.id,
                  vehicle.id
                )
              }
            >
              <Checkbox checked={upcomingMaintenance.is_completed} />
            </div>
          )}
        </Table.Cell>
        <Table.Cell>
          <ScheduleServiceButton maintenance={[upcomingMaintenance]} vehicle={vehicle} />
        </Table.Cell>
      </Table.Row>
    )
  }
} // class MaintenanceScheduleItem

export default MaintenanceScheduleItem
