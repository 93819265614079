import React from "react"

import GenericPanel from "../../../shared/GenericPanel"
import Skeleton from "react-loading-skeleton"
import "react-loading-skeleton/dist/skeleton.css"

const ServicesLoadingComponent = () => {
  return (
    <>
      <Skeleton className="skeleton" />
      <div style={{ height: "10px" }} />
      <Skeleton className="skeleton" count={3} />
    </>
  )
}

export default ServicesLoadingComponent
