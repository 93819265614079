import React, { Component, Fragment } from "react"
import { connect } from "react-redux"

import CurrentLocation from "../../elements/CurrentLocation"
import Geosuggest from "react-geosuggest"
import { Grid } from "semantic-ui-react"
import { getStyles } from "../shared/StylesheetInjector"
import { withTranslation } from "react-i18next"
import { CANDA_COUNTRY_CODE, US_COUNTRY_CODE, PR_COUNTRY_CODE } from "../../constants/users"
import { isFleetMaintenanceHubCanada } from "../../helpers/affiliationHelpers"

const styles = getStyles()

class ShopListFilter extends Component {
  constructor(props) {
    super(props)

    this.state = {
      zip_code: "",
      searchLocation: null,
      searchQuery: "",
      focused: false
    }

    this._handleSuggestSelect = this._handleSuggestSelect.bind(this)
  }

  _handleSuggestSelect(suggest) {
    if (suggest) {
      this.setState({
        searchQuery: suggest.label,

        searchLocation: {
          name: suggest.label,
          latitude: suggest.location.lat,
          longitude: suggest.location.lng
        }
      })

      this.onSubmit()
    }
  }

  onChange(field, value) {
    this.setState({ [field]: value })
  }

  onKeyDown(event) {
    if (event.key === "Enter") {
      this.onSubmit()
    }
  }

  onSubmit() {
    const { searchLocation, zip_code } = this.state
    if (searchLocation == null && (zip_code == null || zip_code === "")) return
    const { asSubForm, filterShopsParams, dispatch, setChangedFilter, pageCount = 1 } = this.props
    dispatch({
      type: "SHOPS_FILTER_SAGA",
      payload: {
        filters: this.state,
        asSubForm,
        filterShopsParams,
        pageCount: pageCount
      }
    })
    dispatch({ type: "SHOP_LOCATION", payload: { location: this.state.searchLocation } })
    setChangedFilter({ filters: this.state, asSubForm, filterShopsParams }, pageCount)
    dispatch({
      type: "RESET_SHOPS_FILTER_SAGA"
    })
  }

  onFocus = () => {
    this.setState({ focused: true })
  }

  onBlur = () => {
    this.setState({ focused: false })
  }

  searchByLocation = (locationData) => {
    const { latitude, longitude } = locationData.coords
    const { dispatch } = this.props

    dispatch({
      type: "SHOPS_FILTER_SAGA",
      payload: {
        filters: {
          searchLocation: {
            latitude,
            longitude
          }
        },
        pageCount: 1
      }
    })
  }

  setLoading = () => {
    this.props.dispatch({ type: "SET_LOADING" })
  }

  unsetLoading = () => {
    this.props.dispatch({ type: "UNSET_LOADING" })
  }

  handleLocationChange = (val) => {
    this.setState({ searchQuery: val })
  }

  render() {
    const { t } = this.props

    return (
      <Grid columns="equal">
        <Grid.Column width={6}>
          <Geosuggest
            country={
              isFleetMaintenanceHubCanada()
                ? CANDA_COUNTRY_CODE
                : [US_COUNTRY_CODE, PR_COUNTRY_CODE]
            }
            ignoreTab={true}
            inputClassName="searchFldShop"
            onKeyDown={(event) => this.onKeyDown(event)}
            onSuggestSelect={this._handleSuggestSelect}
            onChange={this.handleLocationChange}
            onFocus={this.onFocus}
            onBlur={this.onBlur}
            placeholder={t("searchLocationsLabel")}
            ref="geosuggest"
            suggestItemActiveClassName="search-suggest-item-active"
            suggestItemClassName="search-suggest-item"
            suggestsClassName="search-suggests-list"
            suggestsHiddenClassName="search-suggests-list-hidden"
            types={["geocode"]}
            style={{
              input: {
                height: "30px",
                marginTop: "10px",
                fontFamily: styles.fontFamily,
                fontSize: "1em"
              }
            }}
          />

          {!this.props.asSubForm && (
            <CurrentLocation
              visible={this.state.focused && this.state.searchQuery.length === 0}
              onLocationDataObtained={this.searchByLocation}
              onLocationDataRequested={this.setLoading}
              onLocationDataError={this.unsetLoading}
            />
          )}
        </Grid.Column>
      </Grid>
    )
  }
} // class ShopListFilter

export default connect()(withTranslation("shops")(ShopListFilter))
