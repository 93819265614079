import React from "react"

import { Header } from "semantic-ui-react"

export default function FMCOrderHeader({ header }) {
  return (
    <Header
      size="small"
      style={{
        marginBottom: "0px",
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between"
      }}
    >
      <div style={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
        {header}
      </div>
    </Header>
  )
}
