import React, { PureComponent } from "react"
import PropTypes from "prop-types"
import { Link } from "react-router-dom"

import VehicleModal from "./VehicleModal"
import { agGridColumnDefs, agGridRowData } from "../../constants/basicVehicles"
import * as agGridHelpers from "../../helpers/agGridHelpers"
import { navigationPaths } from "../../constants/paths"
import LoadingThrobber from "../../components/shared/LoadingThrobber"

import { AgGridReact } from "ag-grid-react"
import { Header, Segment, Button, Checkbox } from "semantic-ui-react"
import { FLEET_MANAGER, ACCOUNT_OWNER } from "../../constants/roles"
import { withRouter } from "react-router-dom"
import debounce from "debounce"
import VehicleSearch from "./VehicleSearch"
import { withTranslation } from "react-i18next"
import frGrid from "../../locales/fr-ca/grid.json"
import enGrid from "../../locales/en/grid.json"
import { FR_LOCALE } from "../../constants/users"
import CustomNoRowsOverlay from "../shared/CustomNoRowsOverlay"
import { isFleetcor } from "../../helpers/affiliationHelpers"

class NewVehicleList extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      sizeToFit: false,
      showGrid: true,
      rowSelection: "multiple",
      rowData: null
    }
    this.debouncedOnFilterChange = debounce(this.onFilterChange, 300)
    this.debouncedOnSizeToFitChange = debounce(this.onSizeToFitChange, 100)
  }

  // NOTE: This is used over linking from ag-grid's onRowSelected, because clicking a column button (i.e. Edit), will
  // fire both the row and column click handlers. This will take you to the expected edit page, but polutes the
  // navigation stack, and breaks expected back button functionality.
  // NOTE: This is not needed without linking, as it just wraps the ag-grid default render with a link.
  defaultCellRenderer(cellParams) {
    const vehicleId = cellParams.data.id

    return (
      <Link className={"ag-grid-link-column"} to={navigationPaths.vehicleShow(vehicleId)}>
        <div>{cellParams.value}</div>
      </Link>
    )
  }

  onFilterChange = (_, event) => {
    this.setState({ quickFilterText: event.value })
  }

  onGridReady = (params) => {
    this.api = params.api
    this.columnApi = params.columnApi
    if (this.state.sizeToFit) params.api.sizeColumnsToFit()
    window.addEventListener("resize", () => this.state.sizeToFit && this.api.sizeColumnsToFit())
  }

  resetFiltering = () => this.api.setFilterModel([])

  setFilteredItems = () =>
    this.setState({ filteredItems: this.api.getModel().rowsToDisplay.map((r) => r.data) })

  onSizeToFitChange = (event, syntheticEvent) => {
    this.setState({ sizeToFit: syntheticEvent.checked })
    if (syntheticEvent.checked) this.api.sizeColumnsToFit()
    else this.redrawGrid()
  }

  onSelectionChanged = (event) => {
    this.props.onSelectionChanged(event)
  }

  redrawGrid() {
    this.setState({ showGrid: false })
    setTimeout(() => this.setState({ showGrid: true }), 500)
  }

  render() {
    const {
      users,
      vehicles,
      language,
      t,
      dispatch,
      currentUser,
      showMultiSelection,
      showGrid1,
      showGrid2
    } = this.props

    const { sizeToFit } = this.state

    let showGrid = showGrid1 || showGrid2

    const getRowStyle = (params) => {
      if (
        params.data.vehicle.is_active === false ||
        (isFleetcor() && params.data.vehicle.fleetcor_enrolled === false)
      ) {
        return { background: "#D3D3D3" }
      }
    }
    return (
      <div>
        <span />
        <Segment
          className={"ag-theme-material basic_vehicles"}
          style={{
            height: "521px"
          }}
        >
          <Checkbox
            label={t("sizeToFitLabel")}
            onChange={this.debouncedOnSizeToFitChange}
            checked={sizeToFit}
          />
          {showGrid ? (
            <AgGridReact
              columnDefs={agGridColumnDefs(
                this.defaultCellRenderer,
                t,
                isFleetcor(),
                vehicles,
                showMultiSelection
              )}
              defaultColDef={{
                sortable: true,
                filter: true
              }}
              rowData={agGridRowData(vehicles, users, language, isFleetcor())}
              rowSelection={"single"}
              onGridReady={this.onGridReady}
              gridOptions={{
                onFilterChanged: this.setFilteredItems
              }}
              frameworkComponents={{
                customNoRowsOverlay: CustomNoRowsOverlay
              }}
              noRowsOverlayComponent={"customNoRowsOverlay"}
              noRowsOverlayComponentParams={{
                translationFile: "vehicleIndex",
                label: "noVehiclesInFleetLabel"
              }}
              localeText={language === FR_LOCALE ? frGrid : enGrid}
              quickFilterText={this.state.quickFilterText}
              cacheQuickFilter={true}
              pagination={true}
              paginationPageSize="7"
              suppressMenuHide={true}
              suppressMovableColumns={false}
              suppressCellSelection={true}
              getRowStyle={getRowStyle}
              suppressRowClickSelection={true}
              rowSelection={this.state.rowSelection}
              onGridReady={this.onGridReady}
              onSelectionChanged={this.onSelectionChanged.bind(this)}
            ></AgGridReact>
          ) : (
            <LoadingThrobber visible />
          )}
        </Segment>
      </div>
    )
  }
} // class NewVehicleList

NewVehicleList.propTypes = {
  users: PropTypes.array.isRequired,
  vehicles: PropTypes.array.isRequired
}

export default withRouter(withTranslation("vehicleIndex")(NewVehicleList))
