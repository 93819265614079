import React, { Component } from "react"
import { Button, Modal } from "semantic-ui-react"
import { connect } from "react-redux"
import { Dropdown } from "semantic-ui-react"
import { text } from "@fortawesome/fontawesome-svg-core"

class SnoozModal extends Component {
  constructor(props) {
    super(props)
    this.state = {
      selectedValue: props.selectedValue
    }
  }

  ReminderOptionValues = () => {
    const { t } = this.props

    const options = [
      {
        text: t("common:remindMeLabel"),
        value: "Remind Me"
      },
      {
        text: t("common:after1WeekLabel"),
        value: "After 1 week"
      },
      {
        text: t("common:after2WeeksLabel"),
        value: "After 2 weeks"
      },
      {
        text: t("common:after4WeeksLabel"),
        value: "After 4 weeks"
      },
      {
        text: t("common:after8WeeksLabel"),
        value: "After 8 weeks"
      },
      {
        text: t("common:after13WeeksLabel"),
        value: "After 13 weeks"
      }
    ]

    return options.map((option, index) => {
      return {
        content: (
          <option key={index} value={option.value}>
            {option.text}
          </option>
        ),
        text: option.text,
        value: option.value
      }
    })
  }

  snoozeReminder = (selectedValue, vehicleId, dispatch) => {
    if (/\d/.test(selectedValue)) {
      dispatch({
        type: "MAINTENANCE_REMINDER_SNOOZE_SAGA",
        payload: { vehicleId: vehicleId, value: selectedValue },
        callback: this.afterSnoozed
      })
    }
  }

  afterSnoozed = (status, data) => {
    const { closeSnoozModal, dispatch, vehicles } = this.props
    if (status === "success") {
      closeSnoozModal()
      dispatch({
        type: "MAINTENANCE_REMINDER_SNOOZED_DATE_SAGA",
        payload: { vehicleIds: vehicles.map((veh) => veh.id) }
      })
    }
  }

  setValue = (value) => {
    const dropDownValue = value.target.innerText
    this.setState({ selectedValue: dropDownValue })
  }

  render() {
    const { vehicleId, dispatch, openSnoozModal, closeSnoozModal, t } = this.props
    const { selectedValue } = this.state

    return (
      <>
        <Modal
          closeIcon
          open={openSnoozModal}
          className="snoozModal"
          onClose={closeSnoozModal}
          size="tiny"
        >
          <Modal.Header>{t("snoozReminderLabel")}</Modal.Header>
          <Modal.Content>
            <Dropdown
              fluid
              selection
              placeholder={t("common:remindMeLabel")}
              options={this.ReminderOptionValues()}
              closeOnChange={true}
              onChange={this.setValue}
            ></Dropdown>
          </Modal.Content>
          <Modal.Actions>
            <Button onClick={() => this.snoozeReminder(selectedValue, vehicleId, dispatch)}>
              {t("common:confirmLabel")}
            </Button>
            <Button onClick={closeSnoozModal}>{t("common:cancelLabel")}</Button>
          </Modal.Actions>
        </Modal>
      </>
    )
  }
}

export default connect()(SnoozModal)
