export const applicationIdentifier = "fleetmd"
export const FLEET = "fleet"
export const FMC = "fmc"

// subdomains
export const CARVANA = "carvana"
export const FLEET_MAINTENANCE_HUB = "shell"
export const FLEET_MAINTENANCE_HUB_CANADA = "shellcanada"
export const FLEET_AMERICA = "fleetamerica"
export const FLEET_ADVISE = "fleetadvise"
export const ZUBIE = "zubie"
export const FLEETCOR = "fleetcor"
export const FLEETCOR_NAL = "fleetcor_nal"
export const FLEETCOR_NAP = "fleetcor_nap"
export const FUELMAN = "fuelman"
export const NTWTBC = "ntw"
export const PEPBOYS = "pepboys"
export const AZUGA = "azuga"

export const VEHICLE_INDEX = "vehicle_index"
export const MAINTENANCE_INDEX = "maintenance_policy"
export const FMC_USER = "fmc_user"
export const FMC_EDIT = "fmc_edit"
export const SETTINGS_USER = "settings_user"
export const PAYMENT = "payment"
export const FIELD_AWAITING_APPROVAL_COUNT = "awaiting_approval_count"
export const IS_DEFAULT = "is_default"

export const MD_HD_VEHICLE_TYPE_ID = 10
export const MAX_FILE_LENGTH = 5

export const DOCUMENT_TYPE = {
  DRIVER_LICENSE: "Drivers License",
  VEHICLE_INSURANCE: "Vehicle Insurance",
  REGISTRATION: "Registration",
  OTHER: "Other"
}

export const DOCUMENT_EXTENSION = {
  PDF: "pdf",
  JPEG: "jpeg",
  JPG: "jpg",
  PNG: "png",
  GIF: "gif",
  SVG: "svg"
}

export const SUPPORTED_DOCUMENT_TYPES = ["image/png", "image/jpg", "image/jpeg", "application/pdf"]

export const MONTH = "month"
export const DAY = "day"
export const DATE = "Date"
export const MILEAGE = "Mileage"
export const DEFAULT_IMG_URL = "https://app.caradvise.com/static/media/placeholder_car.fcab19be.svg"
export const CURRENT_USER = "current_user"
export const CARADVISE = "CarAdvise"
export const OEM_RECOMMENDED = "OEM_RECOMMENDED"

export const URLS = {
  ntw: {
    privacyPolicy: "https://www.ntw.com/privacy-policy",
    terms: "https://www.ntw.com/terms-of-use",
    doNotShareInfo:
      "https://privacyportal.onetrust.com/webform/6ab91689-fe4e-4bac-8d52-04fb14560768/99b21304-4adc-4fa4-aa51-edbeba2d7f24",
    accessebility: "https://www.ntw.com/accessibility",
    aboutAds: "https://www.ntw.com/about-our-ads",
    copyrightLabel: "common:copyrightNTWTBCLabel",
    termsLabel: "common:termsOfUseLabel",
    privacyPolicyLabel: "common:privacyPolicyLabel"
  },
  shell: {
    privacyPolicy: "https://caradvise.com/privacy-policy-fmh",
    terms: "http://caradvise.com/terms/",
    termsLabel: "common:termsOfUseLabel",
    privacyPolicyLabel: "common:privacyPolicyLabel"
  },
  shellcanada: {
    privacyPolicy: "https://caradvise.com/privacy-policy-ca",
    terms: "http://caradvise.com/terms/",
    termsLabel: "common:termsOfUseLabel",
    privacyPolicyLabel: "common:privacyPolicyLabel",
    promotionalTermsLabel: "common:promotionsAndTermsLabel",
    promotionalTerms: "https://caradvise.com/promotional-terms-of-use/"
  },
  fleetcor: {
    privacyPolicy: "https://www.fuelman.com/privacy-policy/",
    terms: "https://caradvise.com/fuelmanterms/",
    termsLabel: "common:siteTermsLabel",
    privacyPolicyLabel: "common:fuelmanPrivacyPolicyLabel"
  },
  fuelman: {
    privacyPolicy: "https://www.fuelman.com/privacy-policy/",
    terms: "https://caradvise.com/fuelmanterms/",
    addendum: "https://fuelmanmaintenance.com/fuelman-addendum/",
    termsLabel: "common:siteTermsLabel",
    privacyPolicyLabel: "common:fuelmanPrivacyPolicyLabel"
  },
  fleetadvise: {
    privacyPolicy: "http://caradvise.com/privacy/",
    terms: "http://caradvise.com/terms/",
    termsLabel: "common:termsOfUseLabel",
    privacyPolicyLabel: "common:privacyPolicyLabel"
  }
}
