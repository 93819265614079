import React, { useEffect, useState } from "react"
import { Button, Grid, Input, Modal, Image, Dropdown, Message, Label } from "semantic-ui-react"
import DatePicker from "react-datepicker"
import { Date_Format } from "../../constants/years"
import { isFleetMaintenanceHubCanada } from "../../helpers/affiliationHelpers"
import { isEmpty, isNumber } from "lodash"
import moment from "moment"
import { useTranslation } from "react-i18next"
import { DRIVER } from "../../constants/roles"
import { DOCUMENT_TYPE } from "../../constants/application"
import { handleKeypress } from "../../constants/vehicles"

function DocumentUploadModal({
  open,
  showOrHideModal,
  states,
  dispatch,
  vehicleId,
  driverLicence,
  docType,
  userFirstName,
  vehicleInsurance,
  registration,
  customDocuments,
  userRoles,
  readOnly = false
}) {
  const [fileInputRefs] = useState({
    fileRef: React.createRef(),
    fileRef_1: React.createRef()
  })
  const [formDetails, setFormDetails] = useState({
    fileObjectURL: null,
    fileType: "",
    docuemntNumber: null, //docuemntNumber can be license number or license plate or insurance number
    expirationDate: null,
    statesOrInsurerDetail: null,
    file: null,
    documentItemId: null,
    documentId: null,
    fileSizeExceededError: null,
    customDocFileSizeExceededError: null,
    fileTypeError: null,
    customDocs: [
      {
        fileObjectURL: null,
        documentTitle: null,
        expirationDate: null,
        file: null,
        fileType: "",
        isHideReuploadButtonForCustDoc: true,
        isCustomDocumentReuploaded: false,
        isHideEditButtonForCustDoc: true,
        isHideDeleteButtonForCustDoc: true,
        documentItemId: null,
        documentId: null
      }
    ]
  })
  const [documentConfig, setDocumentConfig] = useState({
    isHideReuploadButton: true,
    isStateSelected: false,
    isHideDeleteButton: true,
    isShowDeleteConfirmationModal: false,
    isDocumentReuploaded: false,
    isEditButtonClicked: false,
    isCustomDocSaveClicked: false,
    customDocIndex: null
  })

  const { t } = useTranslation("vehicle")

  useEffect(() => {
    if (docType === DOCUMENT_TYPE.DRIVER_LICENSE && !isEmpty(driverLicence)) {
      const { document_number = "", expiration_date, state_id = "", id } = driverLicence
      const gloveboxDocumentLength = driverLicence.glovebox_documents.length
      setFormDetails({
        fileObjectURL: gloveboxDocumentLength
          ? driverLicence.glovebox_documents[gloveboxDocumentLength - 1].document_url.url
          : null,
        docuemntNumber: document_number,
        expirationDate: expiration_date ? moment(expiration_date) : null,
        statesOrInsurerDetail: state_id,
        documentItemId: id,
        documentId: gloveboxDocumentLength
          ? driverLicence.glovebox_documents[gloveboxDocumentLength - 1].id
          : null
      })
      setDocumentConfig({
        isHideReuploadButton: gloveboxDocumentLength
          ? driverLicence.glovebox_documents[gloveboxDocumentLength - 1].document_url.url
            ? false
            : true
          : true,
        isStateSelected: state_id ? true : false,
        isHideDeleteButton: id ? false : true
      })
    } else if (docType === DOCUMENT_TYPE.VEHICLE_INSURANCE && !isEmpty(vehicleInsurance)) {
      const {
        document_number = "",
        expiration_date,
        id,
        insurance_name = "",
        state_id
      } = vehicleInsurance
      const gloveboxDocumentLength = vehicleInsurance.glovebox_documents.length
      setFormDetails({
        fileObjectURL: gloveboxDocumentLength
          ? vehicleInsurance.glovebox_documents[gloveboxDocumentLength - 1].document_url.url
          : null,
        docuemntNumber: document_number,
        expirationDate: expiration_date ? moment(expiration_date) : null,
        statesOrInsurerDetail: insurance_name,
        documentItemId: id,
        documentId: gloveboxDocumentLength
          ? vehicleInsurance.glovebox_documents[gloveboxDocumentLength - 1].id
          : null
      })
      setDocumentConfig({
        isHideReuploadButton: gloveboxDocumentLength
          ? vehicleInsurance.glovebox_documents[gloveboxDocumentLength - 1].document_url.url
            ? false
            : true
          : true,
        isStateSelected: state_id ? true : false,
        isHideDeleteButton: id ? false : true
      })
    } else if (docType === DOCUMENT_TYPE.REGISTRATION && !isEmpty(registration)) {
      const { document_number = "", expiration_date, id, state_id = "" } = registration
      const gloveboxDocumentLength = registration.glovebox_documents.length
      setFormDetails({
        fileObjectURL: gloveboxDocumentLength
          ? registration.glovebox_documents[gloveboxDocumentLength - 1].document_url.url
          : null,
        docuemntNumber: document_number,
        expirationDate: expiration_date ? moment(expiration_date) : null,
        statesOrInsurerDetail: state_id,
        documentItemId: id,
        documentId: gloveboxDocumentLength
          ? registration.glovebox_documents[gloveboxDocumentLength - 1].id
          : null
      })
      setDocumentConfig({
        isHideReuploadButton: gloveboxDocumentLength
          ? registration.glovebox_documents[gloveboxDocumentLength - 1].document_url.url
            ? false
            : true
          : true,
        isStateSelected: state_id ? true : false,
        isHideDeleteButton: id ? false : true
      })
    } else {
      setStateForCustomDocument(customDocuments)
    }
  }, [])

  const setStateForCustomDocument = (customDocuments) => {
    const tempCustomDocs = []
    customDocuments.map((customDocument) => {
      const { document_title = "", expiration_date, id } = customDocument
      const gloveboxDocumentLength = customDocument.glovebox_documents.length
      tempCustomDocs.push({
        fileObjectURL: gloveboxDocumentLength
          ? customDocument.glovebox_documents[gloveboxDocumentLength - 1].document_url.url
          : null,
        documentTitle: document_title,
        expirationDate: expiration_date ? moment(expiration_date) : null,
        documentItemId: id,
        documentId: gloveboxDocumentLength
          ? customDocument.glovebox_documents[gloveboxDocumentLength - 1].id
          : null,
        isHideReuploadButtonForCustDoc: false,
        isCustomDocumentReuploaded: false,
        isHideEditButtonForCustDoc: false,
        isHideDeleteButtonForCustDoc: false
      })
    })
    if (!readOnly) {
      tempCustomDocs.push({
        fileObjectURL: null,
        documentTitle: null,
        expirationDate: null,
        file: null,
        fileType: "",
        isHideReuploadButtonForCustDoc: true,
        isCustomDocumentReuploaded: false,
        documentItemId: null,
        documentId: null,
        isHideEditButtonForCustDoc: true,
        isHideDeleteButtonForCustDoc: true
      })
    }
    setFormDetails({
      customDocs: [...tempCustomDocs]
    })
  }

  const fileChange = (e, isReuploaded = false, index, documentItemId) => {
    const file = e.target.files[0]
    const { id } =
      docType === DOCUMENT_TYPE.DRIVER_LICENSE
        ? driverLicence
        : docType === DOCUMENT_TYPE.REGISTRATION
        ? registration
        : vehicleInsurance
    if (
      file.type === "image/jpeg" ||
      file.type === "image/jpg" ||
      file.type === "image/png" ||
      file.type === "application/pdf"
    ) {
      if (file.size >= 10000000) {
        if (isNumber(index)) {
          customDocs[index].file = null
          customDocs[index].fileType = null
          customDocs[index].fileObjectURL = null
          customDocs[index].isHideReuploadButtonForCustDoc = true
          customDocs[index].isCustomDocumentReuploaded = false
          setFormDetails({
            ...formDetails,
            customDocs,
            customDocFileSizeExceededError: t("fileSizeLimitLabel"),
            fileTypeError: ""
          })
        } else {
          setFormDetails({
            ...formDetails,
            fileObjectURL: null,
            fileType: null,
            file: null,
            fileSizeExceededError: t("fileSizeLimitLabel"),
            fileTypeError: ""
          })
          setDocumentConfig({
            ...documentConfig,
            isHideReuploadButton: true,
            isStateSelected: false,
            isHideDeleteButton: id ? false : true,
            isDocumentReuploaded: isReuploaded
          })
        }
        return
      }
      if (docType === DOCUMENT_TYPE.OTHER) {
        customDocs[index].file = file
        customDocs[index].fileType = file.type
        customDocs[index].fileObjectURL = URL.createObjectURL(file)
        customDocs[index].isHideReuploadButtonForCustDoc = false
        customDocs[index].isCustomDocumentReuploaded = documentItemId ? true : isReuploaded
        setFormDetails({
          ...formDetails,
          customDocFileSizeExceededError: "",
          fileTypeError: "",
          customDocs
        })
      } else {
        setFormDetails({
          ...formDetails,
          fileSizeExceededError: "",
          fileTypeError: "",
          fileObjectURL: URL.createObjectURL(file),
          fileType: file.type,
          file
        })
        setDocumentConfig({
          ...documentConfig,
          isHideReuploadButton: false,
          isStateSelected: false,
          isHideDeleteButton: id ? false : true,
          isDocumentReuploaded: id ? true : isReuploaded
        })
      }
    } else {
      if (isNumber(index)) {
        customDocs[index].file = null
        customDocs[index].fileType = null
        customDocs[index].fileObjectURL = null
        customDocs[index].isHideReuploadButtonForCustDoc = true
        customDocs[index].isCustomDocumentReuploaded = false
        setFormDetails({
          ...formDetails,
          customDocs,
          fileTypeError: t("fileTypeErrorLabel"),
          customDocFileSizeExceededError: ""
        })
      } else {
        setFormDetails({
          ...formDetails,
          fileObjectURL: null,
          fileType: null,
          file: null,
          fileTypeError: t("fileTypeErrorLabel"),
          fileSizeExceededError: ""
        })
        setDocumentConfig({
          isHideReuploadButton: true,
          isStateSelected: false,
          isHideDeleteButton: id ? false : true,
          isDocumentReuploaded: isReuploaded
        })
      }
    }
  }

  const handleNumberChange = ({ target }) => {
    if (target.value.includes(" ")) {
      return
    }
    setFormDetails({
      ...formDetails,
      docuemntNumber: target.value
    })
  }

  const handleStateChange = (event, { value }) => {
    setFormDetails({
      ...formDetails,
      statesOrInsurerDetail: value
    })
    setDocumentConfig({
      ...documentConfig,
      isStateSelected: true
    })
  }

  const handleExpirationDateChange = (value, index) => {
    const now = moment()
    if (value) {
      if (value._d.getFullYear() < now._d.getFullYear()) {
        return
      } else if (value._d.getFullYear() == now._d.getFullYear()) {
        if (value._d.getMonth() < now._d.getMonth()) {
          return
        } else if (value._d.getMonth() == now._d.getMonth()) {
          if (value._d.getDate() < now._d.getDate()) {
            return
          }
        }
      }
    }
    if (docType === DOCUMENT_TYPE.OTHER) {
      customDocs[index].expirationDate = value
      setFormDetails({
        ...formDetails,
        customDocs
      })
    } else {
      setFormDetails({
        ...formDetails,
        expirationDate: value
      })
    }
  }

  const handleDocumentSave = () => {
    const {
      file,
      docuemntNumber = "",
      expirationDate = null,
      statesOrInsurerDetail = null,
      documentItemId,
      documentId,
      customDocs
    } = formDetails
    const formData = new FormData()
    if (docType !== DOCUMENT_TYPE.OTHER) {
      file && formData.append("glovebox_item[glovebox_documents_attributes][0][document_url]", file)
      formData.append(
        "glovebox_item[expiration_date]",
        expirationDate ? expirationDate.format(Date_Format.YYYY_MM_DD) : ""
      )
      formData.append("glovebox_item[document_type]", docType)
      formData.append("glovebox_item[document_number]", docuemntNumber ? docuemntNumber : "")
      formData.append("glovebox_item[vehicle_id]", vehicleId)
      if (documentId && documentConfig.isDocumentReuploaded) {
        formData.append("glovebox_item[glovebox_documents_attributes][1][id]", documentId)
        formData.append("glovebox_item[glovebox_documents_attributes][1][_destroy]", true)
      }
      if (docType === DOCUMENT_TYPE.VEHICLE_INSURANCE) {
        formData.append(
          "glovebox_item[insurance_name]",
          statesOrInsurerDetail ? statesOrInsurerDetail : ""
        )
      } else {
        formData.append(
          "glovebox_item[state_id]",
          statesOrInsurerDetail ? statesOrInsurerDetail : ""
        )
      }
    } else {
      const {
        file,
        documentTitle = null,
        expirationDate = null,
        documentId,
        isCustomDocumentReuploaded
      } = customDocs[customDocIndex]
      file && formData.append(`glovebox_item[glovebox_documents_attributes][0][document_url]`, file)
      formData.append(
        "glovebox_item[expiration_date]",
        expirationDate ? expirationDate.format(Date_Format.YYYY_MM_DD) : ""
      )
      formData.append("glovebox_item[document_type]", docType)
      formData.append("glovebox_item[vehicle_id]", vehicleId)
      formData.append("glovebox_item[document_title]", documentTitle ? documentTitle : "")
      if (documentId && isCustomDocumentReuploaded) {
        formData.append("glovebox_item[glovebox_documents_attributes][1][id]", documentId)
        formData.append("glovebox_item[glovebox_documents_attributes][1][_destroy]", true)
      }
    }
    dispatch({
      type: "SAVE_OR_UPDATE_UPLOADED_DOCUMENT",
      payload: {
        formData,
        documentItemId:
          docType === DOCUMENT_TYPE.OTHER
            ? customDocs[customDocIndex].documentItemId
            : documentItemId
      },
      callback: handleAfterDocumentSave
    })
    showOrHideModal(false)
    setDocumentConfig({
      ...documentConfig,
      isCustomDocSaveClicked: true
    })
  }

  const handleAfterDocumentSave = (resultType) => {
    if (resultType === "success") {
      dispatch({
        type: "VEHICLE_GLOVEBOX_SAGA",
        payload: {
          vehicleId: vehicleId
        }
      })
    }
  }

  const handleDeleteDocument = () => {
    setDocumentConfig({
      ...documentConfig,
      isShowDeleteConfirmationModal: true
    })
  }

  const handleDeleteConfirmation = () => {
    const { id } =
      docType === DOCUMENT_TYPE.DRIVER_LICENSE
        ? driverLicence
        : docType === DOCUMENT_TYPE.REGISTRATION
        ? registration
        : docType === DOCUMENT_TYPE.OTHER
        ? customDocuments[customDocIndex]
        : vehicleInsurance
    dispatch({
      type: "DELETE_UPLOADED_DOCUMENT",
      payload: { id },
      callback: afterDeleteDocument
    })
    setDocumentConfig({
      ...documentConfig,
      isShowDeleteConfirmationModal: false
    })
    showOrHideModal(false)
  }

  const afterDeleteDocument = (resultType) => {
    if (resultType === "success") {
      dispatch({
        type: "VEHICLE_GLOVEBOX_SAGA",
        payload: {
          vehicleId: vehicleId
        }
      })
    }
  }

  const renderDeleteConfirmationModal = () => {
    return (
      <Modal
        centered={false}
        open={documentConfig.isShowDeleteConfirmationModal}
        onClose={() =>
          setDocumentConfig({
            ...documentConfig,
            isShowDeleteConfirmationModal: false
          })
        }
        size="tiny"
        closeIcon
      >
        <Modal.Header>{t("deleteDocumentLabel")}</Modal.Header>
        <Modal.Content>
          <Message warning>
            <Message.Header>{t("deleteDocumentMessgeLabel")}</Message.Header>
          </Message>
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={handleDeleteConfirmation}>{t("common:yesLabel")}</Button>
          <Button
            onClick={() =>
              setDocumentConfig({ ...documentConfig, isShowDeleteConfirmationModal: false })
            }
          >
            {t("common:noLabel")}
          </Button>
        </Modal.Actions>
      </Modal>
    )
  }

  const handleNameChange = (event, { value }, index) => {
    const tempCustDocs = [...customDocs]
    tempCustDocs[index] = { ...customDocs[index], documentTitle: value }
    setFormDetails({
      ...formDetails,
      customDocs: tempCustDocs
    })
  }

  const isFleetShellCanada = isFleetMaintenanceHubCanada()
  const renderlabel = (type) => {
    if (docType === DOCUMENT_TYPE.DRIVER_LICENSE) {
      if (type === "modalHeader") {
        return t("uploadDrivingLicenseLabel")
      } else if (type === "documentNumber") {
        return t("licenseNumberLabel")
      } else if (type === "states") {
        if (isFleetShellCanada) {
          return t("licenseCanadaStateLabel")
        } else {
          return t("licenseStateLabel")
        }
      } else if (type === "expirationDate") {
        return t("expirationDateLabel")
      }
    } else if (docType === DOCUMENT_TYPE.VEHICLE_INSURANCE) {
      if (type === "modalHeader") {
        return t("uploadVehicleInsuranceLabel")
      } else if (type === "documentNumber") {
        return t("insuranceNumberLabel")
      } else if (type === "insuranceDetails") {
        return t("insurerDetailsLabel")
      } else if (type === "expirationDate") {
        return t("expirationDateLabel")
      }
    } else if (docType === DOCUMENT_TYPE.REGISTRATION) {
      if (type === "modalHeader") {
        return t("uploadVehicleRegistrationLabel")
      } else if (type === "documentNumber") {
        return t("licensePlateLabel")
      } else if (type === "states") {
        if (isFleetShellCanada) {
          return t("registrationCanadaStateLabel")
        } else {
          return t("registrationStateLabel")
        }
      } else if (type === "expirationDate") {
        return t("expirationDateLabel")
      }
    } else {
      if (type === "modalHeader") {
        return t("extraDocumentsLable")
      } else if (type === "name") {
        return t("fleets:nameLabel")
      } else if (type === "expirationDate") {
        return t("expirationDateLabel")
      } else if (type === "editModalHeader") {
        return t("editDocumentLabel")
      }
    }
  }

  const {
    fileType,
    docuemntNumber,
    expirationDate,
    statesOrInsurerDetail,
    fileObjectURL,
    fileSizeExceededError,
    customDocFileSizeExceededError,
    fileTypeError,
    customDocs
  } = formDetails
  const {
    isStateSelected,
    isShowDeleteConfirmationModal,
    isHideDeleteButton,
    isHideReuploadButton,
    isEditButtonClicked,
    customDocIndex
  } = documentConfig

  const dateFormat = isFleetMaintenanceHubCanada() ? Date_Format.YYYY_MM_DD : Date_Format.MM_DD_YYYY

  const isUserOnlyDriver = userRoles.length === 1 ? userRoles[0] === DRIVER : false
  const isRestrictDriverToUpdateDocument =
    isUserOnlyDriver && docType !== DOCUMENT_TYPE.DRIVER_LICENSE
  const disabledStyle =
    isRestrictDriverToUpdateDocument || readOnly ? { cursor: "not-allowed" } : {}

  const renderDocumentUploadModal = () => {
    return (
      <Modal onClose={() => showOrHideModal(false)} open={open} closeIcon>
        <Modal.Header>{renderlabel("modalHeader")}</Modal.Header>
        <Grid columns={2} celled="internally" style={{ height: "350px" }}>
          <Grid.Column width={10}>
            {fileSizeExceededError ? (
              <Modal.Content>
                <Message error>
                  <Message.Header>{fileSizeExceededError}</Message.Header>
                </Message>
              </Modal.Content>
            ) : null}
            {fileTypeError ? (
              <Modal.Content>
                <Message error>
                  <Message.Header>{fileTypeError}</Message.Header>
                </Message>
              </Modal.Content>
            ) : null}
            {fileObjectURL ? (
              <div>
                {fileType ? (
                  fileType === "application/pdf" ? (
                    <embed src={`${fileObjectURL}#toolbar=0`} className="uploaded-image" />
                  ) : (
                    <Image as="img" src={fileObjectURL} className="uploaded-image" />
                  )
                ) : (
                  <embed src={`${fileObjectURL}#toolbar=0`} className="uploaded-image" />
                )}
              </div>
            ) : (
              <>
                <div
                  style={{
                    width: "100%",
                    margin:
                      fileSizeExceededError || customDocFileSizeExceededError || fileTypeError
                        ? "22% 32%"
                        : "27% 35%"
                  }}
                >
                  <Button
                    circular
                    fluid
                    content={t("uploadDocumentLabel")}
                    type="file"
                    onClick={() => fileInputRefs.fileRef.current.click()}
                    style={{
                      width: "32%"
                    }}
                  />
                  <br />
                  <span style={{ width: "50%", margin: "-15%" }}>
                    <b>{t("imageTypesLabel")}</b>
                  </span>
                </div>
                <input
                  ref={fileInputRefs.fileRef}
                  type="file"
                  hidden
                  onChange={fileChange}
                  accept="image/jpeg, image/jpg, image/png, application/pdf"
                />
              </>
            )}
          </Grid.Column>
          <Grid.Column width={6} className="docuemnt-upload">
            {
              <>
                <div>{renderlabel("documentNumber")}</div>
                <Input
                  size="small"
                  value={docuemntNumber}
                  onChange={handleNumberChange}
                  className="docuemnt-upload-input"
                  disabled={isRestrictDriverToUpdateDocument || readOnly}
                  style={disabledStyle}
                  onKeyPress={handleKeypress}
                />
              </>
            }
            {docType === DOCUMENT_TYPE.VEHICLE_INSURANCE ? (
              <>
                <div>{renderlabel("insuranceDetails")}</div>
                <Input
                  className="docuemnt-upload-input"
                  size="small"
                  onChange={handleStateChange}
                  value={statesOrInsurerDetail}
                  disabled={isRestrictDriverToUpdateDocument || readOnly}
                  style={disabledStyle}
                />
              </>
            ) : (
              <>
                <div>{renderlabel("states")}</div>
                <div className="upload-dropdown">
                  <Dropdown
                    selection
                    search
                    disabled={isRestrictDriverToUpdateDocument || readOnly}
                    onChange={handleStateChange}
                    options={states}
                    style={
                      isStateSelected
                        ? {
                            padding: "0.9em",
                            ...disabledStyle,
                            minHeight: "38px",
                            maxHeight: "38px"
                          }
                        : {
                            padding: "0.9em",
                            ...disabledStyle,
                            minHeight: "38px",
                            maxHeight: "38px"
                          }
                    }
                    value={statesOrInsurerDetail}
                  />
                </div>
              </>
            )}
            <div>{renderlabel("expirationDate")}</div>
            <div className="upload-daterange">
              <div className="upload-datepicker" style={disabledStyle}>
                <DatePicker
                  disabled={isRestrictDriverToUpdateDocument || readOnly}
                  selectsStart
                  selected={expirationDate}
                  onChange={handleExpirationDateChange}
                  minDate={moment()}
                  dateFormat={dateFormat}
                />
              </div>
            </div>
            <div className="edit-document-button">
              {isHideReuploadButton ||
              readOnly ||
              (isUserOnlyDriver && docType !== DOCUMENT_TYPE.DRIVER_LICENSE) ? null : (
                <>
                  <Button onClick={() => fileInputRefs.fileRef_1.current.click()}>
                    {t("uploadLabel")}
                  </Button>
                  <input
                    ref={fileInputRefs.fileRef_1}
                    type="file"
                    hidden
                    onChange={(e) => fileChange(e, true)}
                    accept="image/jpeg, image/jpg, image/png, application/pdf"
                  />
                </>
              )}
              &nbsp;&nbsp;
              {isHideDeleteButton ||
              readOnly ||
              (isUserOnlyDriver && docType !== DOCUMENT_TYPE.DRIVER_LICENSE) ? null : (
                <Button onClick={handleDeleteDocument}>{t("common:deleteLabel")}</Button>
              )}
            </div>
          </Grid.Column>
        </Grid>
        {readOnly ? null : (
          <Modal.Actions style={disabledStyle} className="document-action-button">
            <Button
              disabled={fileObjectURL && !isRestrictDriverToUpdateDocument ? false : true}
              onClick={handleDocumentSave}
            >
              {t("common:saveLabel")}
            </Button>
            <Button onClick={() => showOrHideModal(false)}>{t("common:cancelLabel")}</Button>
          </Modal.Actions>
        )}
        {isShowDeleteConfirmationModal ? renderDeleteConfirmationModal() : null}
      </Modal>
    )
  }

  const renderCustomDocumentUploadModal = (props) => {
    const disabledStyle =
      isRestrictDriverToUpdateDocument || props.readOnly || readOnly
        ? { cursor: "not-allowed" }
        : {}
    return (
      <Modal onClose={() => showOrHideModal(false)} open={open} closeIcon>
        <Modal.Header>{renderlabel("modalHeader")}</Modal.Header>
        {props.customDocs.map((doc, index) => {
          const {
            fileObjectURL,
            documentTitle,
            expirationDate,
            file,
            fileType,
            isHideEditButtonForCustDoc
          } = doc
          return (
            <span>
              <Label style={{ fontSize: "1em", height: "2.5em", margin: "1%" }}>
                {fileObjectURL
                  ? `${index + 1}. ${documentTitle ? documentTitle : ""}`
                  : t("addDocumentLabel")}
              </Label>
              <Grid
                columns={2}
                celled="internally"
                style={{ height: "380px", borderStyle: "ridge" }}
              >
                <Grid.Column width={10}>
                  {fileObjectURL ? (
                    <div>
                      {fileType ? (
                        fileType === "application/pdf" ? (
                          <embed
                            src={`${fileObjectURL}#toolbar=0`}
                            className="uploaded-image-custom-doc"
                          />
                        ) : (
                          <Image
                            as="img"
                            src={fileObjectURL}
                            className="uploaded-image-custom-doc"
                          />
                        )
                      ) : (
                        <embed
                          src={`${fileObjectURL}#toolbar=0`}
                          className="uploaded-image-custom-doc"
                        />
                      )}
                    </div>
                  ) : (
                    <>
                      <Button
                        disabled={isUserOnlyDriver && docType !== DOCUMENT_TYPE.DRIVER_LICENSE}
                        circular
                        fluid
                        content={t("addDocumentLabel")}
                        onClick={() => {
                          setDocumentConfig({
                            ...documentConfig,
                            isEditButtonClicked: true,
                            customDocIndex: index
                          })
                        }}
                        style={{ width: "32%", margin: "27% 35%" }}
                      />
                    </>
                  )}
                </Grid.Column>
                <Grid.Column width={6} className="docuemnt-upload">
                  <div>{renderlabel("name")}</div>
                  <div>
                    <Input
                      className="docuemnt-upload-input"
                      size="small"
                      onChange={(e, value) => handleNameChange(e, value, index)}
                      value={documentTitle}
                      disabled={isRestrictDriverToUpdateDocument || props.readOnly}
                      style={disabledStyle}
                    />
                  </div>
                  <div>{renderlabel("expirationDate")}</div>
                  <div className="upload-daterange">
                    <div className="upload-datepicker" style={disabledStyle}>
                      <DatePicker
                        disabled={isRestrictDriverToUpdateDocument || props.readOnly}
                        selectsStart
                        selected={expirationDate}
                        onChange={(value) => handleExpirationDateChange(value, index)}
                        minDate={moment()}
                        dateFormat={dateFormat}
                        style={disabledStyle}
                      />
                    </div>
                  </div>
                  <div className="edit-document-button" style={{ marginTop: "55%" }}>
                    {isHideEditButtonForCustDoc ||
                    readOnly ||
                    (isUserOnlyDriver && docType !== DOCUMENT_TYPE.DRIVER_LICENSE) ? null : (
                      <Button
                        onClick={() => {
                          setDocumentConfig({
                            ...documentConfig,
                            isEditButtonClicked: true,
                            customDocIndex: index
                          })
                        }}
                      >
                        {t("common:editLabel")}
                      </Button>
                    )}
                  </div>
                </Grid.Column>
              </Grid>
            </span>
          )
        })}
        {isShowDeleteConfirmationModal ? renderDeleteConfirmationModal() : null}
      </Modal>
    )
  }

  const handleEditModalClose = () => {
    setStateForCustomDocument(customDocuments)
    setDocumentConfig({ ...documentConfig, isEditButtonClicked: false })
  }

  const handleEditModalCancel = () => {
    setStateForCustomDocument(customDocuments)
    docType === DOCUMENT_TYPE.OTHER
      ? setDocumentConfig({ ...documentConfig, isEditButtonClicked: false })
      : showOrHideModal(false)
  }

  const renderCustomDocumentEditModal = ({ customDocs, customDocIndex }) => {
    const disabledStyle = isRestrictDriverToUpdateDocument ? { cursor: "not-allowed" } : {}
    const {
      fileObjectURL,
      documentTitle = "",
      expirationDate,
      file,
      fileType,
      isHideReuploadButtonForCustDoc,
      isHideDeleteButtonForCustDoc,
      documentItemId
    } = customDocs[customDocIndex]

    return (
      <Modal onClose={handleEditModalClose} open={isEditButtonClicked} closeIcon>
        <Modal.Header>{renderlabel("editModalHeader")}</Modal.Header>
        {
          <span>
            <Label style={{ fontSize: "1em", height: "2.5em", margin: "1%" }}>
              {fileObjectURL
                ? `${customDocIndex + 1}. ${documentTitle ? documentTitle : ""}`
                : t("addDocumentLabel")}
            </Label>
            <Grid columns={2} celled="internally" style={{ height: "380px", borderStyle: "ridge" }}>
              <Grid.Column width={10}>
                {customDocFileSizeExceededError ? (
                  <Modal.Content>
                    <Message error>
                      <Message.Header>{customDocFileSizeExceededError}</Message.Header>
                    </Message>
                  </Modal.Content>
                ) : null}
                {fileTypeError ? (
                  <Modal.Content>
                    <Message error>
                      <Message.Header>{fileTypeError}</Message.Header>
                    </Message>
                  </Modal.Content>
                ) : null}
                {fileObjectURL ? (
                  <div>
                    {fileType ? (
                      fileType === "application/pdf" ? (
                        <embed
                          src={`${fileObjectURL}#toolbar=0`}
                          className="uploaded-image-custom-doc"
                        />
                      ) : (
                        <Image as="img" src={fileObjectURL} className="uploaded-image-custom-doc" />
                      )
                    ) : (
                      <embed
                        src={`${fileObjectURL}#toolbar=0`}
                        className="uploaded-image-custom-doc"
                      />
                    )}
                  </div>
                ) : (
                  <>
                    <div
                      style={{
                        width: "100%",
                        margin:
                          fileSizeExceededError || customDocFileSizeExceededError || fileTypeError
                            ? "22% 32%"
                            : "27% 35%"
                      }}
                    >
                      <Button
                        circular
                        fluid
                        content={t("uploadDocumentLabel")}
                        type="file"
                        onClick={() => fileInputRefs.fileRef.current.click()}
                        style={{
                          width: "32%"
                        }}
                      />
                      <br />
                      <span style={{ width: "50%", margin: "-15%" }}>
                        <b>{t("imageTypesLabel")}</b>
                      </span>
                      <input
                        ref={fileInputRefs.fileRef}
                        type="file"
                        hidden
                        onChange={(e) => fileChange(e, false, customDocIndex, documentItemId)}
                        accept="image/jpeg, image/jpg, image/png, application/pdf"
                      />
                    </div>
                  </>
                )}
              </Grid.Column>
              <Grid.Column width={6} className="docuemnt-upload">
                <div>{renderlabel("name")}</div>
                <div>
                  <Input
                    className="docuemnt-upload-input"
                    size="small"
                    onChange={(e, value) => handleNameChange(e, value, customDocIndex)}
                    value={documentTitle ? documentTitle : ""}
                    disabled={isRestrictDriverToUpdateDocument}
                    style={disabledStyle}
                  />
                </div>
                <div>{renderlabel("expirationDate")}</div>
                <div className="upload-daterange">
                  <div className="upload-datepicker" style={disabledStyle}>
                    <DatePicker
                      disabled={isRestrictDriverToUpdateDocument}
                      selectsStart
                      selected={expirationDate}
                      onChange={(value) => handleExpirationDateChange(value, customDocIndex)}
                      minDate={moment()}
                      dateFormat={dateFormat}
                    />
                  </div>
                </div>
                <div className="edit-document-button" style={{ marginTop: "55%" }}>
                  {isHideReuploadButtonForCustDoc ||
                  (isUserOnlyDriver && docType !== DOCUMENT_TYPE.DRIVER_LICENSE) ? null : (
                    <>
                      <Button onClick={() => fileInputRefs.fileRef_1.current.click()}>
                        {t("uploadLabel")}
                      </Button>
                      <input
                        ref={fileInputRefs.fileRef_1}
                        type="file"
                        hidden
                        onChange={(e) => fileChange(e, true, customDocIndex, documentItemId)}
                        accept="image/jpeg, image/jpg, image/png, application/pdf"
                      />
                    </>
                  )}
                  &nbsp;&nbsp;
                  {isHideDeleteButtonForCustDoc ||
                  (isUserOnlyDriver && docType !== DOCUMENT_TYPE.DRIVER_LICENSE) ? null : (
                    <Button
                      disabled={!customDocs[customDocIndex].fileObjectURL}
                      onClick={handleDeleteDocument}
                    >
                      {t("common:deleteLabel")}
                    </Button>
                  )}
                </div>
              </Grid.Column>
            </Grid>
          </span>
        }
        <Modal.Actions style={disabledStyle} className="document-action-button">
          <Button
            disabled={
              customDocs[customDocIndex].fileObjectURL && !isRestrictDriverToUpdateDocument
                ? false
                : true
            }
            onClick={handleDocumentSave}
          >
            {t("common:saveLabel")}
          </Button>
          <Button onClick={handleEditModalCancel}>{t("common:cancelLabel")}</Button>
        </Modal.Actions>
        {isShowDeleteConfirmationModal ? renderDeleteConfirmationModal() : null}
      </Modal>
    )
  }

  return docType !== DOCUMENT_TYPE.OTHER
    ? renderDocumentUploadModal()
    : isEditButtonClicked
    ? renderCustomDocumentEditModal({ customDocs, customDocIndex })
    : renderCustomDocumentUploadModal({ readOnly: true, customDocs })
}

export default DocumentUploadModal
