import React, { Component } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { Table } from "semantic-ui-react"

import MaintenanceScheduleItem from "./MaintenanceScheduleItem"
import ScheduleServiceButton from "../shared/ScheduleServiceButton"
import { withTranslation } from "react-i18next"
import { isFleetMaintenanceHubCanada } from "../../helpers/affiliationHelpers"

class MaintenanceSchedule extends Component {
  static propTypes = {
    vehicle: PropTypes.object.isRequired
  }

  state = {
    vehicleId: this.props.vehicle.id
  }

  renderScheduleItems() {
    const { vehicle, language, dispatch } = this.props
    const serviceSchedulesByVehicle = this.props.maintenanceIntervals.service_schedules_by_vehicle
    return (serviceSchedulesByVehicle[vehicle.id] || [])
      .filter((upcomingService) => upcomingService.recommended)
      .map((upcomingService, index) => {
        return (
          <MaintenanceScheduleItem
            key={index}
            upcomingMaintenance={upcomingService}
            vehicle={vehicle}
            language={language}
            dispatch={dispatch}
          />
        )
      })
  }

  render() {
    const { vehicle, t } = this.props
    const serviceSchedulesByVehicle = this.props.maintenanceIntervals.service_schedules_by_vehicle
    const isDomainFleetCanada = isFleetMaintenanceHubCanada()
    return (
      <Table className="borderless">
        <Table.Header>
          <Table.Row>
            {[
              t("serviceLabel"),
              isDomainFleetCanada ? t("currentKilometersLabel") : t("currentMilesLabel"),
              isDomainFleetCanada ? t("reccomendedKilometersLabel") : t("reccomendedMilesLabel"),
              t("serviceTypeLabel"),
              isDomainFleetCanada ? t("overdueByKilometersLabel") : t("overdueByMilesLabel"),
              t("due_date"),
              t("isCompletedLabel"),
              serviceSchedulesByVehicle[vehicle.id] ? (
                <ScheduleServiceButton
                  text={t("scheduleAllLabel")}
                  maintenance={serviceSchedulesByVehicle[vehicle.id]}
                  vehicle={vehicle}
                />
              ) : (
                ""
              )
            ].map((s) => (
              <Table.HeaderCell>{s}</Table.HeaderCell>
            ))}
          </Table.Row>
        </Table.Header>
        <Table.Body>{this.renderScheduleItems()}</Table.Body>
      </Table>
    )
  }
} // class MaintenanceSchedule

const mapStateToProps = (state) => {
  const { maintenanceIntervals } = state

  return {
    maintenanceIntervals: maintenanceIntervals
  }
}

export default connect(mapStateToProps)(withTranslation("vehicle")(MaintenanceSchedule))
