import { call, put, takeEvery, take } from "redux-saga/effects"
import api from "../../utilities/api"
import * as apiErrorHelpers from "../../helpers/apiErrorHelpers"
import * as sessionHelpers from "../../helpers/sessionHelpers"

export function* loadPromotionalCodes(action) {
  try {
    const payloadOptions = action.payload.options || {}
    // yield put({ type: "SET_LOADING" })
    yield put({ type: "UNSET_LOADING_ERROR" })

    api.setAuthApi({
      Authorization: sessionHelpers.getApplicationCookie("authentication_token")
    })

    ////////////////////////////
    //   LOAD VEHICLE DATA    //
    ////////////////////////////
    yield put({ type: "PROMOTIONAL_CODE_LOAD_REQUESTED" })

    let url = `/promotions/available?shopping_cart_id=${payloadOptions.shop_order_id}`

    const promotionalCodeRequest = {
      url: url,
      body: { fleet_promocode: true }
    }

    const promotionalCodeResponse = yield call(
      api.utility.get,
      api.path(promotionalCodeRequest.url),
      {
        body: promotionalCodeRequest.body
      }
    )

    apiErrorHelpers.catchErrors(promotionalCodeResponse.body)

    yield put({
      type: "PROMOTIONAL_CODE_LOAD_SUCCEEDED",
      payload: {
        promotionalCodes: promotionalCodeResponse.body
      }
    })

    if (action.callback) {
      yield call(action.callback, promotionalCodeResponse.body)
    }

    // yield put({ type: "UNSET_LOADING" })
  } catch (errors) {
    // eslint-disable-next-line
    errors = apiErrorHelpers.formatInternalErrors(errors)

    yield put({ type: "PROMOTIONAL_CODE_LOAD_FAILED" })
    yield put({ type: "SET_LOADING_ERROR" })
    yield put({ type: "UNSET_LOADING" })

    apiErrorHelpers.consoleLog(errors)
  }
} // function* loadVehicle

export function* applyPromotionalCode(action) {
  try {
    const payloadOptions = action.payload.options || {}
    // yield put({ type: "SET_LOADING" })
    yield put({ type: "UNSET_LOADING_ERROR" })

    api.setAuthApi({
      Authorization: sessionHelpers.getApplicationCookie("authentication_token")
    })

    ////////////////////////////
    //   LOAD VEHICLE DATA    //
    ////////////////////////////
    yield put({ type: "PROMOTIONAL_CODE_APPLY_REQUESTED" })

    let body = {}

    if (payloadOptions.shop_order_id && payloadOptions.promotion_id) {
      body.shopping_cart_id = payloadOptions.shop_order_id
      body.promotion_id = payloadOptions.promotion_id
      body.fleet_promocode = true
    }

    let url = `/customers_promotions/apply`

    const promotionalCodeRequest = {
      url: url,
      body: body
    }

    const promotionalCodeResponse = yield call(
      api.utility.post,
      api.path(promotionalCodeRequest.url),
      {
        body: promotionalCodeRequest.body
      }
    )

    apiErrorHelpers.catchErrors(promotionalCodeResponse.body)

    if (action.callback) {
      yield call(action.callback, "success", promotionalCodeResponse.body)
    }

    // yield put({ type: "UNSET_LOADING" })
  } catch (errors) {
    // eslint-disable-next-line
    errors = apiErrorHelpers.formatInternalErrors(errors)

    yield put({ type: "PROMOTIONAL_CODE_APPLY_FAILED" })
    // yield put({ type: "SET_LOADING_ERROR" })
    // yield put({ type: "UNSET_LOADING" })

    const afterRequestData = {
      error: apiErrorHelpers.toString(errors)
    }

    yield call(action.callback, "failure", afterRequestData)

    apiErrorHelpers.consoleLog(errors)
  }
} // function* loadVehicle

export function* createPromotionalCode(action) {
  try {
    const payloadOptions = action.payload.options || {}
    // yield put({ type: "SET_LOADING" })
    yield put({ type: "UNSET_LOADING_ERROR" })

    api.setAuthApi({
      Authorization: sessionHelpers.getApplicationCookie("authentication_token")
    })

    ////////////////////////////
    //   LOAD VEHICLE DATA    //
    ////////////////////////////
    yield put({ type: "PROMOTIONAL_CODE_CREATE_REQUESTED" })

    let body = {}

    if (payloadOptions.shop_order_id && payloadOptions.code) {
      body.shopping_cart_id = payloadOptions.shop_order_id
      body.code = payloadOptions.code
      body.fleet_promocode = true
    }

    let url = `/customers_promotions`

    const promotionalCodeRequest = {
      url: url,
      body: body
    }

    const promotionalCodeResponse = yield call(
      api.utility.post,
      api.path(promotionalCodeRequest.url),
      {
        body: promotionalCodeRequest.body
      }
    )

    apiErrorHelpers.catchErrors(promotionalCodeResponse.body)
    apiErrorHelpers.catchErrors(promotionalCodeResponse.body)

    if (action.callback) {
      yield call(action.callback, "success", promotionalCodeResponse.body)
    }

    // yield put({ type: "UNSET_LOADING" })
  } catch (errors) {
    // eslint-disable-next-line
    errors = apiErrorHelpers.formatInternalErrors(errors)

    yield put({ type: "PROMOTIONAL_CODE_CREATE_FAILED" })
    // yield put({ type: "SET_LOADING_ERROR" })
    // yield put({ type: "UNSET_LOADING" })

    const afterRequestData = {
      error: apiErrorHelpers.toString(errors)
    }

    yield call(action.callback, "failure", afterRequestData)

    apiErrorHelpers.consoleLog(errors)
  }
} // function* loadVehicle

export default function* promotionalCodeSaga() {
  yield takeEvery("PROMORIONAL_CODE_LOAD_SAGA", loadPromotionalCodes)
  yield takeEvery("PROMORIONAL_CODE_APPLY_SAGA", applyPromotionalCode)
  yield takeEvery("PROMORIONAL_CODE_CREATE_SAGA", createPromotionalCode)
}
