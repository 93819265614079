import React, { Component } from "react"
import { connect } from "react-redux"
import { withTranslation } from "react-i18next"
import { Modal, Header, Segment, Grid, Label, Button, Input } from "semantic-ui-react"
import TextField from "../../elements/TextField"
import Alert from "../../components/shared/Alert"

class PromotionalCodeModal extends Component {
  constructor(props) {
    super(props)

    this.state = {
      modalOpen: false,
      code: "",
      alertMessage: "",
      alertType: "error"
    }
  }

  loadPromotionalCodes = () => {
    const { shop_order_id } = this.props
    this.props.dispatch({
      type: "PROMORIONAL_CODE_LOAD_SAGA",
      payload: {
        options: {
          shop_order_id: shop_order_id
        }
      },
      callback: this.afterRequestSuccess
    })
  }

  applyPromotionalCode = (promotion_id) => {
    const { shop_order_id } = this.props
    this.props.dispatch({
      type: "PROMORIONAL_CODE_APPLY_SAGA",
      payload: {
        options: {
          shop_order_id: shop_order_id,
          promotion_id: promotion_id
        }
      },
      callback: this.afterApplyPromotionalCode
    })
  }

  checkApplyPromotionalCode = () => {
    const { shop_order_id } = this.props
    const { code } = this.state
    if (code) {
      this.props.dispatch({
        type: "PROMORIONAL_CODE_CREATE_SAGA",
        payload: {
          options: {
            shop_order_id: shop_order_id,
            code: code
          }
        },
        callback: this.afterApplyPromotionalCode
      })
    } else {
      this.setState({
        alertMessage: "Please Enter Code"
      })
    }
  }

  afterRequestSuccess = () => {
    this.setState({ modalOpen: true })
  }

  afterApplyPromotionalCode = (status, response) => {
    const { onApprovalChange, services } = this.props
    if (status == "success") {
      this.setState({ modalOpen: false })
      onApprovalChange(services)
    } else {
      this.setState({
        alertMessage: response.error
      })
    }
  }

  handleOpen = async () => {
    this.setState({
      alertMessage: ""
    })
    this.loadPromotionalCodes()
  }

  handleClose = () => {
    this.setState({ modalOpen: false })
  }

  renderView = () => {
    const { promotionalCodes, order, t } = this.props
    const { alertMessage, alertType } = this.state

    return (
      <React.Fragment>
        <Alert message={alertMessage} type={alertType} visible={alertMessage !== ""} />
        <Header textAlign="left" className="primary-color">
          {t("applyPromoCodeLabel")}
        </Header>
        {Object.keys(promotionalCodes).length > 0 &&
          promotionalCodes.map((code) => (
            <Segment>
              <Grid.Row>
                <Grid.Column>
                  <div style={{ display: "grid" }}>
                    <span> {code.name} </span>
                    <span> {code.description} </span>
                  </div>
                </Grid.Column>
                <Grid.Column>
                  {order.promotions[0] && order.promotions[0].id == code.id ? (
                    <Button disabled>{t("appliedButtonLable")}</Button>
                  ) : (
                    <Button onClick={(e) => this.applyPromotionalCode(code.id)}>
                      {t("applyButtonLable")}
                    </Button>
                  )}
                </Grid.Column>
              </Grid.Row>
            </Segment>
          ))}
        <div style={{ display: "flex" }}>
          <TextField
            className="promo-input-width"
            onChange={(e) => this.setState({ code: e.target.value })}
          />
          <Button style={{ marginLeft: 20 }} onClick={this.checkApplyPromotionalCode}>
            {t("applyButtonLable")}
          </Button>
        </div>
      </React.Fragment>
    )
  }

  render() {
    const { children } = this.props
    return (
      <Modal
        size="tiny"
        trigger={
          React.Children.map(children, (c) =>
            React.cloneElement(c, { onClick: this.handleOpen })
          )[0]
        }
        open={this.state.modalOpen}
        closeIcon
        onClose={this.handleClose}
      >
        <Modal.Content>{this.renderView()}</Modal.Content>
      </Modal>
    )
  }
} // class PaymentActivityModal

const mapStateToProps = (state) => {
  return {
    promotionalCodes: state.promotionalCodes.promotionalCodes
  }
}

export default connect(mapStateToProps)(withTranslation("services")(PromotionalCodeModal))
