import React, { PureComponent } from "react"
import PropTypes from "prop-types"
import * as maintenanceHelpers from "../../helpers/maintenanceHelpers"
import { Segment, Grid, Header } from "semantic-ui-react"
import PromotionalCodeModal from "../../components/shared/PromotionalCodeModal"
import { connect } from "react-redux"
import { withTranslation } from "react-i18next"

class PromotionalCodeLink extends PureComponent {
  render() {
    const { shop_order_id, onApprovalChange, order, t, services } = this.props
    return (
      <PromotionalCodeModal
        shop_order_id={shop_order_id}
        onApprovalChange={onApprovalChange}
        services={services}
        order={order}
      >
        <span className="link">{t("applyPromoCodeLabel")}</span>
      </PromotionalCodeModal>
    )
  }
}

export default connect()(withTranslation("services")(PromotionalCodeLink))
