import React, { Component } from "react"
import { connect } from "react-redux"
import PropTypes from "prop-types"
import * as shopHelpers from "../../helpers/shopHelpers"
import * as vehicleHelpers from "../../helpers/vehicleHelpers"
import Button from "../../elements/Button"
import Select from "../../elements/Select"
import AddServiceRow from "./AddServiceRow"
import TextField from "../../elements/TextField"
import { Segment, Form, Header, Grid, Divider, Input } from "semantic-ui-react"
import DatePicker from "react-datepicker"
import moment from "moment"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faPlus } from "@fortawesome/pro-light-svg-icons"
import { Date_Format } from "../../constants/years"
import { FR_LOCALE } from "../../constants/users"
import { withTranslation } from "react-i18next"
import { isFleetMaintenanceHubCanada } from "../../helpers/affiliationHelpers"
import { DocumentUploader } from "../shared/DocumentUploader"
import CustomNotification from "../shared/CustomNotification"
import { NOTIFICATION_TYPE } from "../../constants/notification"
import { MAX_FILE_LENGTH, SUPPORTED_DOCUMENT_TYPES } from "../../constants/application"
import i18n from "../../utilities/i18n"
import { fileSizes } from "../../helpers/numberHelpers"
import { ZIP_CODE_REGEX, POSTAL_CODE_REGEX } from "../../utilities/stringHelpers"
import ErrorField from "../../elements/ErrorField"
import { isEmpty } from "lodash"

let current = {
  odometer: null,
  odometer_units: "miles",
  name: "",
  service_id: null,
  price: null,
  position: null,
  rowIndex: 0
}

class MaintenanceHistoryForm extends Component {
  static propTypes = {
    onSubmit: PropTypes.func.isRequired,
    services: PropTypes.array.isRequired,
    shops: PropTypes.array.isRequired,
    vehicles: PropTypes.array.isRequired,
    isSubmitting: PropTypes.bool,
    gotoShopSearch: PropTypes.func.isRequired
  }

  static defaultProps = {
    isSubmitting: false
  }

  state = {
    ...this.props.previousFormData,
    serviceRows: [{ ...current }],
    maintenanceInvoiceDocumentURL: null,
    selectedFiles: [],
    canUploadMultipleFiles: true,
    deleteDocumentIds: [],
    zipCode: "",
    timerId: null,
    errors: {}
  }

  componentDidMount() {
    const { dispatch, manualMaintenanceId } = this.props
    if (manualMaintenanceId) this.fetchManualMaintenanceData(dispatch, manualMaintenanceId)
  }

  fetchManualMaintenanceData = (dispatch, manualMaintenanceId) => {
    dispatch({
      type: "MAINTENANCE_HISTORY_LOAD_SAGA",
      payload: {
        id: manualMaintenanceId
      },
      callback: this.afterSuccessfullyLoad
    })
  }

  afterSuccessfullyLoad = () => {
    const isFleetShellCanada = isFleetMaintenanceHubCanada()
    const { services, dispatch } = this.props
    const {
      custom_shop_name,
      date_of_completion,
      vehicle_id,
      po_number,
      shop_id,
      customer_comments,
      order_services,
      maintenance_documents,
      zip
    } = this.props.maintenance_history
    const updatedArray =
      order_services &&
      order_services.map((obj, index) => {
        const { service_definition_id, ...rest } = obj
        return {
          ...obj,
          service_id: service_definition_id,
          rowIndex: index + 1,
          service: services.find((s) => s.id === service_definition_id)
        }
      })

    const updatedMaintenance_documents =
      maintenance_documents &&
      maintenance_documents.map((obj, index) => {
        return {
          ...obj,
          fileImage: obj.invoice_document_url && obj.invoice_document_url.url,
          fileName:
            obj.invoice_document_url &&
            new URL(obj.invoice_document_url.url).pathname.split("/").pop()
        }
      })

    var odometer
    {
      isFleetShellCanada
        ? (odometer = order_services && order_services[0].service_kilometers)
        : (odometer = order_services && order_services[0].service_miles)
    }

    this.setState({
      custom_shop_name: custom_shop_name,
      date_of_completion: moment(date_of_completion),
      vehicle_id: vehicle_id,
      po_number: po_number,
      shop_id: shop_id,
      customer_comments: customer_comments,
      serviceRows: updatedArray,
      selectedFiles: updatedMaintenance_documents,
      date_of_completion_pretty: moment(date_of_completion).format("YYYY-MM-DD HH:mm:ss"),
      odometer: odometer,
      zipCode: zip
    })
    if (zip) this.filterShops(dispatch, zip)
  }

  isAnyServiceNull(serviceRows) {
    return serviceRows.map((row) => row.service_id).includes(null)
  }

  isAnyServicePriceNull(serviceRows) {
    return serviceRows.some((servicerRow) => servicerRow.price === "" || servicerRow.price === null)
  }

  allowSubmit() {
    const { isSubmitting } = this.props
    const {
      shop_id,
      vehicle_id,
      odometer,
      date_of_completion,
      custom_shop_name,
      serviceRows,
      deleteDocumentIds
    } = this.state
    return (
      !isSubmitting &&
      vehicle_id &&
      date_of_completion &&
      !this.isAnyServiceNull(serviceRows) &&
      (shop_id || custom_shop_name) &&
      !this.isAnyServicePriceNull(serviceRows) &&
      odometer
    )
  }

  formattedFormData() {
    let {
      vehicle_id,
      odometer,
      date_of_completion,
      shop_id,
      custom_shop_name,
      po_number,
      customer_comments,
      serviceRows,
      date_of_completion_pretty,
      selectedFiles,
      deleteDocumentIds,
      zipCode
    } = this.state

    const { manualMaintenanceId } = this.props
    const formData = new FormData()
    formData.append("vehicle_id", JSON.stringify(vehicle_id))
    if (zipCode) formData.append("zip", zipCode)
    if (shop_id !== undefined && shop_id !== null && shop_id !== "") {
      formData.append("shop_id", JSON.stringify(shop_id))
      formData.append("custom_shop_name", "")
    } else {
      formData.append("shop_id", "")
      formData.append("zip", "")
      formData.append("custom_shop_name", custom_shop_name)
    }

    if (po_number) formData.append("po_number", po_number)
    if (customer_comments) formData.append("customer_comments", customer_comments)

    formData.append("odometer", JSON.stringify(odometer))
    formData.append("date_of_completion", date_of_completion_pretty)
    formData.append("with_manual_history", JSON.stringify(true))

    for (var i = 0; i < serviceRows.length; i++) {
      formData.append(`vehicle_services[][id]`, serviceRows[i].id)
      formData.append(`vehicle_services[][odometer]`, JSON.stringify(odometer))
      formData.append(
        `vehicle_services[][odometer_units]`,
        JSON.stringify(serviceRows[i].odometer_units)
      )
      formData.append(`vehicle_services[][name]`, JSON.stringify(serviceRows[i].name))
      formData.append(`vehicle_services[][serviceDefinition]`, null)
      formData.append(
        `vehicle_services[][service_definition_id]`,
        JSON.stringify(serviceRows[i].service_id)
      )
      if (serviceRows[i].position)
        formData.append(`vehicle_services[][position]`, serviceRows[i].position)

      formData.append(`vehicle_services[][price]`, serviceRows[i].price)
      formData.append(`vehicle_services[][date_of_completion]`, date_of_completion_pretty)
    }

    for (var i = 0; i < deleteDocumentIds.length; i++) {
      formData.append(`maintenance_documents_attributes[][id]`, deleteDocumentIds[i])
      formData.append(`maintenance_documents_attributes[][_destroy]`, true)
    }

    if (selectedFiles.length > 0) {
      var key
      if (manualMaintenanceId) key = `new_maintenance_documents_attributes[][invoice_document_url]`
      else key = `maintenance_documents_attributes[][invoice_document_url]`
      for (var i = 0; i < selectedFiles.length; i++) {
        selectedFiles[i].file && formData.append(key, selectedFiles[i].file)
      }
    }
    return {
      formData,
      vehicle_id
    }
  }

  onChange(field, value) {
    if (field === "date_of_completion") {
      this.setState({
        [field]: value === "" ? undefined : value,
        [`${field}_pretty`]: value === "" ? undefined : value.format("YYYY-MM-DD HH:mm:ss")
      })
    } else {
      if (field == "custom_shop_name") {
        this.setState({ errors: [], shop_id: "", zipCode: "" })
        this.props.dispatch({
          type: "SHOPS_FILTER_SUCCEEDED",
          payload: { shops: [], pageCount: 1 }
        })
      } else if (field == "shop_id") {
        this.setState({ custom_shop_name: "" })
      }
      this.setState({ [field]: value === "" ? undefined : value })
    }
  }

  filterShopsParams() {
    let { vehicle_id, serviceRows } = this.state
    let vehicle_type_id
    if (this.state.vehicle) vehicle_type_id = this.state.vehicle.vehicle_type_id
    return {
      vehicle_id,
      vehicle_type_id,
      services_requested: serviceRows.map((service) => {
        return {
          position: service.position,
          service_definition_id: service.id
        }
      })
    }
  }

  validateFields = (name, value) => {
    switch (name) {
      case "zipCode":
        if (isFleetMaintenanceHubCanada()) return POSTAL_CODE_REGEX.test(value)
        else return ZIP_CODE_REGEX.test(value)
    }
  }

  onZipChange = (value) => {
    const { dispatch, t } = this.props
    this.setState({ zipCode: value })

    if (this.validateFields("zipCode", value)) {
      this.setState({
        errors: {
          zipCode: ""
        }
      })
      clearTimeout(this.state.timerId)

      const newTimerId = setTimeout(() => {
        this.filterShops(dispatch, value)
      }, 2000)

      this.setState({ timerId: newTimerId, custom_shop_name: "" })
    } else {
      if (value.length == 0) {
        this.setState({
          errors: {
            zipCode: ""
          }
        })
      } else {
        this.setState({
          errors: {
            zipCode: isFleetMaintenanceHubCanada()
              ? t("postalCodeValidationLabel")
              : t("zipCodeValidationLabel")
          }
        })
      }
      dispatch({ type: "SHOPS_FILTER_SUCCEEDED", payload: { shops: [], pageCount: 1 } })
    }
  }

  filterShops = (dispatch, value) => {
    this.setState({ shopsLoading: true })
    dispatch({
      type: "SHOPS_FILTER_SAGA",
      payload: {
        asSubForm: true,
        fromMaintananceHistory: true,
        filterShopsParams: this.filterShopsParams(),
        filters: {
          searchLocation: {
            zip: value
          }
        }
      },
      callback: this.afterShopFilter
    })
  }

  afterShopFilter = () => {
    this.setState({ shopsLoading: false })
  }

  onVehicleChange(value) {
    if (value) {
      this.setState({
        vehicle_id: value,
        vehicle: this.props.vehicles.find((s) => s.id == parseInt(value))
      })
    }
  }

  onServiceChange = (value, rowIndex) => {
    if (value) {
      let serviceRows = [...this.state.serviceRows]
      let selectedService = this.props.services.find((s) => s.id == parseInt(value))

      serviceRows.forEach((serviceRow) => {
        if (serviceRow.rowIndex === rowIndex) {
          let resetPosition
          if (selectedService && isEmpty(selectedService.positions)) resetPosition = ""

          return Object.assign(serviceRow, {
            service_id: selectedService.id,
            name: selectedService.name,
            service: selectedService,
            position: resetPosition
          })
        }
      })

      this.setState({ ...this.state, serviceRows: serviceRows })
    }
  }

  handleCurrentChange = (field, value, rowIndex) => {
    let { serviceRows } = this.state

    serviceRows.forEach((serviceRow) => {
      if (serviceRow.rowIndex === rowIndex) {
        return Object.assign(serviceRow, { [field]: value })
      }
    })

    this.setState({ serviceRows })
  }

  renderAddServiceRows(serviceRows) {
    if (serviceRows && serviceRows.length > 0) {
      return serviceRows.map((serviceRow, index) => {
        return (
          <AddServiceRow
            key={index}
            serviceRow={serviceRow}
            services={this.props.services}
            onServiceChange={this.onServiceChange}
            handleCurrentChange={this.handleCurrentChange}
            handleRemove={this.handleRemove}
            {...this.state}
          />
        )
      })
    }
  }

  addMoreService() {
    let { serviceRows } = this.state
    const lastRow = serviceRows.slice(-1)[0]
    let rowIndex = lastRow ? lastRow.rowIndex + 1 : 0

    serviceRows = [...serviceRows, { ...current, rowIndex }]
    this.setState({ serviceRows })
  }

  handleRemove = (rowIndex) => {
    let { serviceRows } = this.state
    serviceRows = [...serviceRows.filter((service) => service.rowIndex !== rowIndex)]
    this.setState({ serviceRows })
  }

  handleFileChange = (files) => {
    const { canUploadMultipleFiles, selectedFiles } = this.state
    const fileCount = [...files, ...selectedFiles].length
    if (
      (canUploadMultipleFiles && files.length > MAX_FILE_LENGTH && fileCount > MAX_FILE_LENGTH) ||
      selectedFiles.length >= MAX_FILE_LENGTH ||
      fileCount > MAX_FILE_LENGTH
    ) {
      CustomNotification(
        NOTIFICATION_TYPE.DANGER,
        i18n.t("settings:errorLabel"),
        `${i18n.t("vehicle:maxFilesLengthErrorLabel", { maxFiles: MAX_FILE_LENGTH })}`,
        i18n.t
      )
      return
    }

    for (let i = 0; i < files.length; i++) {
      let file = files[i]

      if (SUPPORTED_DOCUMENT_TYPES.includes(file.type)) {
        if (file.size >= 5000000) {
          CustomNotification(
            NOTIFICATION_TYPE.DANGER,
            i18n.t("settings:errorLabel"),
            i18n.t("vehicle:invoiceFileSizeLimitLabel"),
            i18n.t
          )
          return
        }
        let reader = new FileReader()
        // For Multiple File Input
        if (canUploadMultipleFiles) {
          if (selectedFiles.some((selectedFile) => selectedFile.fileName === file.name)) {
            CustomNotification(
              NOTIFICATION_TYPE.DANGER,
              i18n.t("settings:errorLabel"),
              i18n.t("vehicle:duplicateFileErrorLabel", { fileName: file.name }),
              i18n.t
            )
            continue
          } else {
            reader.onloadend = () => {
              this.setState((prevState) => ({
                selectedFiles: [
                  ...prevState.selectedFiles,
                  {
                    file: file,
                    fileName: file.name,
                    fileType: file.type,
                    fileImage: reader.result,
                    dateTime: file.lastModifiedDate.toLocaleString("en-US"),
                    fileSize: fileSizes(file.size),
                    url: URL.createObjectURL(file)
                  }
                ]
              }))
            }
            if (files[i]) {
              reader.readAsDataURL(file)
            }
          }
        } else {
          // For Single File Input
          reader.onloadend = () => {
            this.setState({
              selectedFiles: [
                {
                  fileName: file.name,
                  fileType: file.type,
                  fileImage: reader.result,
                  dateTime: file.lastModifiedDate.toLocaleString("en-US"),
                  fileSize: fileSizes(file.size),
                  url: URL.createObjectURL(file)
                }
              ]
            })
          }

          if (files[0]) {
            reader.readAsDataURL(file)
          }
        }
      } else {
        CustomNotification(
          NOTIFICATION_TYPE.DANGER,
          i18n.t("settings:errorLabel"),
          i18n.t("vehicle:fileTypeErrorLabel"),
          i18n.t
        )
      }
    }
  }

  deleteSelectedFile = (id, document_id) => {
    const { maintenance_history } = this.props
    if (maintenance_history && maintenance_history.id && document_id) {
      this.setState({ deleteDocumentIds: [...this.state.deleteDocumentIds, document_id] })
    }
    const { selectedFiles } = this.state
    this.setState((prevState) => {
      const updatedFiles = prevState.selectedFiles.filter((item, i) => i !== id)
      return {
        selectedFiles: updatedFiles
      }
    })
  }

  render() {
    const { onSubmit, shops, vehicles, t, maintenance_history, manualMaintenanceId } = this.props
    const isEnabled = this.allowSubmit()
    const {
      shop_id,
      vehicle_id,
      odometer,
      date_of_completion,
      po_number,
      custom_shop_name,
      customer_comments,
      serviceRows,
      selectedFiles,
      canUploadMultipleFiles,
      zipCode,
      errors,
      shopsLoading
    } = this.state

    return (
      <div>
        <Segment padded raised>
          <Form>
            <Grid stackable columns={2}>
              <Grid.Column width={7}>
                <Header as="h3">
                  {manualMaintenanceId
                    ? t("common:editMaintenanceHistoryLabel")
                    : t("common:addMaintenanceHistoryLabel")}
                </Header>
                <Divider />
                <Form.Field required style={{ display: "flex" }}>
                  <Select
                    onChange={(event) => this.onVehicleChange(event.target.value)}
                    options={vehicleHelpers.selectOptions(vehicles)}
                    placeholder={t("selectVehicleLabel")}
                    defaultValue={vehicle_id}
                    value={vehicle_id}
                  />
                  <label></label>
                </Form.Field>
                <Divider hidden />
                <TextField
                  onChange={(event) => this.onChange("odometer", event.target.value)}
                  placeholder={t("enterOdometerLabel")}
                  value={odometer}
                  required={true}
                />
                <Divider hidden />

                <TextField
                  onChange={(event) => this.onZipChange(event.target.value)}
                  placeholder={
                    isFleetMaintenanceHubCanada() ? t("codePostalLabel") : t("zipCodeLabel")
                  }
                  value={zipCode}
                  title={t("searchShopByZipLabel")}
                  required={true}
                />
                <ErrorField
                  displayError={errors.zipCode}
                  className={"error-field"}
                  label={errors.zipCode}
                />
                <Form.Field
                  required={custom_shop_name ? false : true}
                  style={{ display: "flex", width: custom_shop_name ? "99%" : "100%" }}
                >
                  <Select
                    onChange={(event) => this.onChange("shop_id", event.target.value)}
                    options={[{ value: undefined, label: t("manualEnterShopLabel") }].concat(
                      shopHelpers.selectOptions(shops)
                    )}
                    placeholder={shops.length == 0 ? t("noShopsLabel") : t("selectShopLabel")}
                    defaultValue={shop_id}
                    value={shop_id}
                    loading={shopsLoading}
                  />
                  <label> </label>
                </Form.Field>

                <Divider hidden />

                <TextField
                  onChange={(event) => this.onChange("custom_shop_name", event.target.value)}
                  placeholder={t("CustomShopNameLabel")}
                  value={custom_shop_name}
                  required={shop_id ? false : true}
                />
              </Grid.Column>

              <Grid.Column width={7}>
                <Header as="h3">&nbsp; </Header>
                <Divider />

                <TextField
                  onChange={(event) => this.onChange("po_number", event.target.value)}
                  placeholder={t("poNumberLabel")}
                  value={po_number}
                />

                <Divider hidden />

                <TextField
                  onChange={(event) => this.onChange("customer_comments", event.target.value)}
                  placeholder={t("customerCommentsLabel")}
                  value={customer_comments}
                />

                <Divider hidden />
                <Divider hidden />
                <Form.Field required style={{ display: "flex" }}>
                  <DatePicker
                    selected={date_of_completion}
                    onChange={(value) => this.onChange("date_of_completion", value)}
                    maxDate={moment()}
                    dateFormat={
                      isFleetMaintenanceHubCanada()
                        ? Date_Format.YYYY_MM_DD
                        : Date_Format.MM_DD_YYYY
                    }
                    placeholderText={t("dateOfCompletionLabel")}
                    customInput={<Input value={date_of_completion} />}
                  />
                  <label></label>
                </Form.Field>
                <Divider hidden />
                <Form.Field required style={{ display: "flex" }}>
                  <DocumentUploader
                    canUploadMultipleFiles={canUploadMultipleFiles}
                    selectedFiles={selectedFiles}
                    handleFileChange={this.handleFileChange}
                    deleteSelectedFile={this.deleteSelectedFile}
                  />
                </Form.Field>
              </Grid.Column>
            </Grid>

            <Header as="h3">{t("servicesLabel")} </Header>
            <Divider />

            {this.renderAddServiceRows(serviceRows)}

            {
              <Grid centered>
                <Grid.Row>
                  <div className={"submit-container"}>
                    <Button
                      label={
                        <>
                          <span>{t("addMoreLabel")} </span>
                          <FontAwesomeIcon icon={faPlus} style={{ marginTop: "-10px" }} />
                        </>
                      }
                      onClick={() => this.addMoreService()}
                    />
                  </div>
                </Grid.Row>
              </Grid>
            }

            <Grid centered>
              <Grid.Row>
                <div className={"submit-container"}>
                  <Button
                    disabled={!isEnabled}
                    label={manualMaintenanceId ? t("common:updateLabel") : t("common:saveLabel")}
                    onClick={() => onSubmit(this.formattedFormData(), manualMaintenanceId)}
                  />
                </div>
              </Grid.Row>
            </Grid>
          </Form>
        </Segment>
      </div>
    )
  }
} // class MaintenanceHistoryForm

const mapStateToProps = (state) => {
  return {
    services: state.services.services,
    vehicles: state.vehicles.vehicles,
    shops: state.shops.shops,
    maintenance_history: state.maintenanceHistories.manualMaintenanceHistory || []
  }
}

export default connect(mapStateToProps)(
  withTranslation("maintenanceHistory")(MaintenanceHistoryForm)
)
