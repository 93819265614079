import { isFleetMaintenanceHubCanada } from "../helpers/affiliationHelpers"

export const formatWithCommas = (number) => {
  if (number) {
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
  } else {
    return "-"
  }
}

export const formattedPrice = (price) => {
  if (price == null || price == undefined) return ""

  return typeof price === "number"
    ? isFleetMaintenanceHubCanada()
      ? `$${formatWithCommas(parseFloat(price).toFixed(2))} CAD`
      : `$${formatWithCommas(parseFloat(price).toFixed(2))}`
    : isFleetMaintenanceHubCanada()
    ? `$${formatWithCommas(parseFloat(price).toFixed(2))} CAD`
    : `$${formatWithCommas(parseFloat(price).toFixed(2))}`
}

export const fileSizes = (bytes, decimals = 2) => {
  if (bytes === 0) return "0 Bytes"
  const k = 1024
  const dm = decimals < 0 ? 0 : decimals
  const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]
  const i = Math.floor(Math.log(bytes) / Math.log(k))
  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i]
}
