export const APPROVAL_EMPTY_STATE = "approvalsEmptyState"
export const APPROVAL_TOGGLE_BUTTON = "approvalToggleButton"
export const APPROVALS_FORM = "approvalsForm"
export const APPROVALS_FORM_CANCEL = "approvalsFormCancel"
export const APPROVALS_FORM_FIELD = "approvalsFormField"
export const APPROVALS_FORM_FIELDS = "approvalsFormFields"
export const APPROVALS_FORM_HEADER = "approvalsFormHeader"
export const APPROVALS_FORM_NOTES = "approvalsFormNotes"
export const APPROVALS_FORM_PAYMENT_METHOD = "approvalsFormPaymentMethod"
export const APPROVALS_FORM_SUBMIT = "approvalsFormSubmit"
export const APPROVALS_FORM_TOTALS = "approvalsFormTotals"
export const APPROVALS_INFO_MODEL = "approvalsInfoModal"
export const COMMON = "common"
export const DASHBOARD = "dashboard"
export const FLEET_IMPERSONATION = "fmcImpersonation"
export const FMC_DASHBOARD = "fmcDashboard"
export const FMC_USERS = "fmcUsers"
export const MAINTENANCE_HISTORY = "maintenanceHistory"
export const NO_INFORMATION = "noInformation"
export const ORDER_SERVICE_COMMENT = "orderServiceComment"
export const ORDER_SERVICE_MODAL_INFO = "orderServiceModalInfo"
export const PAYMENT = "payment"
export const PRICE_EXPLANATION = "priceExplanation"
export const PRICE_INFO_DISPLAY = "priceInfoDisplay"
export const PRIVACY_POLICY_AND_TERMS = "privacyPolicyAndTerms"
export const SCHEDULE_REQUEST = "scheduleRequest"
export const SERVICES = "services"
export const SETTINGS = "settings"
export const SHOP_ORDERS = "shopOrders"
export const SHOPS = "shops"
export const SITE_HEADER = "siteHeader"
export const VEHICLE = "vehicle"
export const VEHICLE_INDEX = "vehicleIndex"
export const FLEETS = "fleets"
export const MEMBERSHIP_CARD = "membershiCard"
export const APPROVAL_HISTORY = "approvalHistory"
export const GEOTAB = "geotab"
export const NOTIFICATIONS_REMINDER = "notificationsReminder"
