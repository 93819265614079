import { constants } from "caradvise_shared_components"
const { BRAINTREE, WEX, FLEETCOR } = constants.paymentProviders

export function formattedName(user) {
  return `${user.firstName} ${user.lastName}`
}

export function selectOptions(users, opts = {}) {
  let options = []

  if (opts.semantic)
    users.forEach((user) => {
      options.push({
        value: user.id,
        key: `user-${user.id}`,
        text: formattedName(user)
      })
    })
  else
    users.forEach((user) => {
      options.push({
        label: formattedName(user),
        value: user.id
      })
    })

  return options
}

export function selectAccountOptions(accounts, options = {}) {
  let accountOptions = []
  if (accounts != undefined) {
    accounts.forEach((account) => {
      accountOptions.push({
        text: account.name,
        value: account.id,
        key: `account-${account.id}`
      })
    })
  }

  return accountOptions
}

export function selectAccountFleetOptions(fleets, options = {}) {
  let accountFleetOptions = []
  if (fleets != undefined) {
    fleets.forEach((fleet) => {
      accountFleetOptions.push({
        text: fleet.name,
        value: fleet.id,
        key: `fleet-${fleet.id}`
      })
    })
  }

  return accountFleetOptions
}

export function mapPreferencesToDefaults(preferences, defaults) {
  delete preferences.id
  delete preferences.customer_id
  Object.keys(preferences).forEach(
    (k) => (preferences[k] = { ...defaults[k], value: preferences[k] })
  )
}

export const DEFAULT_TEXT_NOTIFICATION_PREFERENCE = {
  appointment_requested: { value: false, label: "appointmentRequestedLabel" },
  appointment_confirmed: { value: false, label: "appointmentConfirmedLabel" },
  approvals_required: { value: true, label: "approvalsRequiredLabel" },
  approvals_submitted: { value: true, label: "approvalsSubmittedLabel" },
  services_completed: { label: "ordersCompletedLabel", value: false },
  services_canceled: { label: "ordersCancelledLabel", value: false },
  payment_received: { value: false, hidden: true, label: "paymentReceivedLabel" },
  twenty_four_hour_reminder: { value: false, label: "24HourReminderLabel" },
  one_hour_reminder: { value: false, label: "1HourReminderLabel" }
}

export const DEFAULT_EMAIL_NOTIFICATION_PREFERENCE = {
  appointment_requested: { value: false, label: "appointmentRequestedLabel" },
  approvals_required: { value: false, label: "approvalsRequiredLabel" },
  services_canceled: { label: "ordersCancelledLabel", value: false },
  payment_received: { value: false, label: "paymentReceivedLabel" },
  twenty_four_hour_reminder: { value: false, label: "24HourReminderLabel" }
}

const TBC_AFFILIATION_ID = 3
export function isTBC(user) {
  const affiliation = (user && user.affiliation) || {}
  const { id, parent_id } = affiliation

  return (id && id === 3) || (parent_id && parent_id === 3)
}

export function isBraintree(currentFleet) {
  return (
    currentFleet &&
    currentFleet.fleet_payment_providers &&
    currentFleet.fleet_payment_providers.includes(BRAINTREE)
  )
}

export function isWex(currentFleet) {
  return (
    currentFleet &&
    currentFleet.fleet_payment_providers &&
    currentFleet.fleet_payment_providers.includes(WEX)
  )
}

export function isFleetcor(currentFleet) {
  return (
    currentFleet &&
    currentFleet.fleet_payment_providers &&
    currentFleet.fleet_payment_providers.includes(FLEETCOR)
  )
}
