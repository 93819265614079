// NOTE: Use of vehicle(s)DataType prevents components that require extended data from trying to render stale "standard" data from the store and crashing.
// NOTE: vehicle(s)DataType:
// 'standard' => Loaded without "all" option (vehicles index)
// 'extended' => Loaded with "all" option (dashboard)

export const initialState = {
  promotionalCodes: {}
}

export default function (state = initialState, action) {
  const { payload, type } = action

  switch (type) {
    case "PROMOTIONAL_CODE_LOAD_SUCCEEDED":
      return {
        ...state,
        promotionalCodes: payload.promotionalCodes
      }
    default:
      return state
  }
}
