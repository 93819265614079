export const initialState = {
  paymentActivity: {},
  subscriptionActivity: {},
  paymentActivities: [],
  subscriptionActivities: []
}

export default function (state = initialState, action) {
  const { payload, type } = action

  switch (type) {
    case "PAYMENT_ACTIVITIES_LOAD_SUCCEEDED":
      return {
        ...state,
        paymentActivities: action.payload.paymentActivities
      }
    case "PAYMENT_ACTIVITY_LOAD_SUCCEEDED":
      return {
        ...state,
        paymentActivity: action.payload.paymentActivity
      }
    case "SUBSCRIPTION_ACTIVITIES_LOAD_SUCCEEDED":
      return {
        ...state,
        subscriptionActivities: action.payload.subscriptionActivities
      }
    case "SUBSCRIPTION_ACTIVITY_LOAD_SUCCEEDED":
      return {
        ...state,
        subscriptionActivity: action.payload.subscriptionActivity
      }
    default:
      return state
  }
}
