import React, { Component } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import moment from "moment"
import { sortBy } from "lodash"
import { Segment, Header } from "semantic-ui-react"

import CollapsibleList from "../../components/shared/CollapsibleList"
import MiniLoader from "../../components/shared/MiniLoader"
import { withTranslation } from "react-i18next"
import { Date_Format } from "../../constants/years"

const ApprovalNotes = ({ item, t }) => {
  const actor = item.created_by ? `${item.created_by} ` : ""

  return (
    <div key={`approval-item-${item.id}`} style={{ paddingBottom: "10px" }}>
      <strong>{`${moment(item.created_at).format(Date_Format.MM_DD_YY_HMA)}: `}</strong>
      {`${actor}`}

      <div style={{ paddingLeft: "10px" }}>{`${item.comment}`}</div>
    </div>
  )
}

class ApproversNotesHistory extends Component {
  static propTypes = {
    isLoading: PropTypes.bool.isRequired,
    shopOrderId: PropTypes.number
  }

  componentDidMount() {
    const { dispatch, shopOrderId } = this.props

    dispatch({
      type: "SHOP_ORDER_APPROVERS_NOTES_LOAD_SAGA",
      payload: {
        shopOrderId: shopOrderId
      }
    })
  }

  render() {
    const { approvalNotes, isLoading, t } = this.props

    return (
      <div style={{ padding: "20px" }}>
        <Header size="small" className={"dash-hd"}>
          {t("approversNotesLabel")}
        </Header>
        <Segment>
          {isLoading ? (
            <MiniLoader inverted={false} />
          ) : approvalNotes && approvalNotes.length === 0 ? (
            <em>{t("noApproversNotesLabel")}</em>
          ) : (
            <CollapsibleList
              items={sortBy(approvalNotes, "created_at")
                .reverse()
                .map((item) => (
                  <ApprovalNotes item={item} t={t} />
                ))}
              applySort={false}
              t={t}
            />
          )}
        </Segment>
      </div>
    )
  }
} // class ApproversNotesHistoryHistory

const mapStateToProps = (state) => {
  const { shopOrderActivityItems } = state
  return {
    isLoading: shopOrderActivityItems.isApproverNotesLoading,
    approvalNotes: shopOrderActivityItems.approvalsNotes
  }
}

export default connect(mapStateToProps)(withTranslation("approvalHistory")(ApproversNotesHistory))
