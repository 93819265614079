import React, { Component } from "react"

import { navigationPaths } from "../../constants/paths"
import { connect } from "react-redux"

import { getSubdomain, getStyles } from "./StylesheetInjector"
import ReactSVG from "react-svg"
import { Grid, Menu } from "semantic-ui-react"
import NavItem from "./NavItem"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faHome,
  faCar,
  faTools,
  faMapMarkerAlt,
  faCog,
  faUserFriends,
  faTachometer,
  faUserClock,
  faFileInvoiceDollar,
  faCars
} from "@fortawesome/pro-light-svg-icons"
import { FLEET, FMC } from "../../constants/application"
import { isFmcPath } from "../../helpers/pathHelpers"
import { isFMCUser } from "../../helpers/userRoleHelpers"
import {
  isFleetMaintenanceHub,
  isCarvana,
  isFleetMaintenanceHubCanada,
  isFleetcor,
  isFuelman,
  isNTWTBC
} from "../../helpers/affiliationHelpers"
import ManageAccount from "./ManageAccount"
const styles = getStyles()

class Nav extends Component {
  accountTabIsActive() {
    const pathName = this.pathName()

    if (/\/account/.test(pathName)) {
      return true
    }

    return false
  }

  dashboardTabIsActive() {
    const pathName = this.pathName()
    if (pathName === navigationPaths.dashboard()) {
      return true
    }

    return false
  }

  fmcDashboardTabIsActive() {
    const pathName = this.pathName()

    if (pathName === navigationPaths.fmcDashboard()) {
      return true
    }

    return false
  }

  fmcUsersTabIsActive() {
    const pathName = this.pathName()

    if (pathName === navigationPaths.fmcUsers()) {
      return true
    }

    return false
  }

  fmcOrdersTabIsActive() {
    const pathName = this.pathName()

    if (pathName === navigationPaths.fmcActiveOrders()) {
      return true
    }

    return false
  }

  transferVehiclesTabIsActive() {
    const pathName = this.pathName()

    if (pathName === navigationPaths.transferVehicles()) {
      return true
    }

    return false
  }

  fmcTransactionsTabIsActive() {
    const pathName = this.pathName()

    if (pathName === navigationPaths.fmcTransactions()) {
      return true
    }

    return false
  }

  fleetTransactionsTabIsActive() {
    const pathName = this.pathName()

    if (pathName === navigationPaths.fleetTransactions()) {
      return true
    }

    return false
  }

  pathName() {
    return window.location.pathname
  }

  shopsTabIsActive() {
    const pathName = this.pathName()

    if (/\/service_request/.test(pathName)) {
      return true
    }
    if (/\/shops/.test(pathName)) {
      return true
    }

    return false
  }

  servicesTabIsActive() {
    const pathName = this.pathName()

    if (/\/services/.test(pathName)) {
      return true
    }

    return false
  }

  settingsTabIsActive() {
    const pathName = this.pathName()

    if (/\/settings/.test(pathName)) {
      return true
    }

    return false
  }

  vehiclesTabIsActive() {
    const pathName = this.pathName()

    if (/\/vehicles/.test(pathName)) {
      return true
    }

    return false
  }

  /* TAK affiliation id(DEV) = 222
   N.J. Malin and Associates, LLC affiliation id(PROD) = 1011
   Unity Point Health affiliation id(PROD) = 957
   Alsco affiliation id(PROD) = 720 */

  render() {
    const { isFMC, isFMCMode, user, account_fleets, currentUser, fleet } = this.props
    const activeColor = styles.iconColorActive
    const inactiveColor = styles.iconColorInactive
    let sub = getSubdomain()
    let size = 86
    if (isFleetMaintenanceHub() || isFleetMaintenanceHubCanada()) size = 60
    if (isFleetcor()) size = 150
    if (isFuelman()) size = 90
    let extraStyles = {}
    // Add white background to the Carvana logo
    if (isCarvana()) extraStyles = { backgroundColor: "white", padding: "9px 7px" }

    return (
      <Grid.Column
        className={isFleetcor() ? "navbar fleetcor" : " navbar"}
        only="computer"
        centered
      >
        <Menu borderless vertical className="borderless-outline">
          <div style={{ backgroundColor: "white !important" }}>
            <Menu.Header className={isFleetcor() || isFuelman() || isNTWTBC() ? "fleetcor" : ""}>
              <ReactSVG
                src={`/images/${sub}_logo.svg`}
                svgStyle={{ ...extraStyles, width: size, height: size }}
              />
            </Menu.Header>
          </div>

          {isFleetcor() && !isFMCUser(user) && currentUser && fleet.account && (
            <ManageAccount
              currentUser={currentUser}
              fleet={fleet}
              account_fleets={account_fleets}
            />
          )}

          {this.props.isFleetMode && (
            <React.Fragment>
              <NavItem
                active={this.dashboardTabIsActive()}
                content={
                  <FontAwesomeIcon
                    icon={faHome}
                    color={this.dashboardTabIsActive() ? activeColor : inactiveColor}
                    size="3x"
                    className={isFleetcor() || isFuelman() ? "icon-hover" : ""}
                  />
                }
                linkPath={navigationPaths.dashboard()}
              />

              <NavItem
                active={this.vehiclesTabIsActive()}
                content={
                  <FontAwesomeIcon
                    icon={faCar}
                    color={this.vehiclesTabIsActive() ? activeColor : inactiveColor}
                    size="3x"
                    className={isFleetcor() || isFuelman() ? "icon-hover" : ""}
                  />
                }
                linkPath={navigationPaths.vehicleIndex()}
              />

              <NavItem
                active={this.servicesTabIsActive()}
                content={
                  <FontAwesomeIcon
                    icon={faTools}
                    color={this.servicesTabIsActive() ? activeColor : inactiveColor}
                    size="3x"
                    className={isFleetcor() || isFuelman() ? "icon-hover" : ""}
                  />
                }
                linkPath={navigationPaths.servicesIndex()}
              />

              <NavItem
                active={this.shopsTabIsActive()}
                content={
                  <FontAwesomeIcon
                    icon={faMapMarkerAlt}
                    color={this.shopsTabIsActive() ? activeColor : inactiveColor}
                    size="3x"
                    className={isFleetcor() || isFuelman() ? "icon-hover" : ""}
                  />
                }
                linkPath={navigationPaths.shopIndex()}
              />

              <NavItem
                active={this.settingsTabIsActive()}
                content={
                  <FontAwesomeIcon
                    icon={faCog}
                    color={this.settingsTabIsActive() ? activeColor : inactiveColor}
                    size="3x"
                    className={isFleetcor() || isFuelman() ? "icon-hover" : ""}
                  />
                }
                linkPath={navigationPaths.settingsIndex()}
              />

              <NavItem
                active={this.fleetTransactionsTabIsActive()}
                content={
                  <FontAwesomeIcon
                    icon={faFileInvoiceDollar}
                    color={this.fleetTransactionsTabIsActive() ? activeColor : inactiveColor}
                    size="3x"
                  />
                }
                linkPath={navigationPaths.fleetTransactions()}
              />
            </React.Fragment>
          )}

          {isFMC && isFMCMode && (
            <React.Fragment>
              <NavItem
                active={this.fmcDashboardTabIsActive()}
                content={
                  <FontAwesomeIcon
                    icon={faTachometer}
                    color={this.fmcDashboardTabIsActive() ? activeColor : inactiveColor}
                    size="3x"
                    className={isFleetcor() ? "icon-hover" : ""}
                  />
                }
                linkPath={navigationPaths.fmcDashboard()}
              />
              <NavItem
                active={this.fmcUsersTabIsActive()}
                content={
                  <FontAwesomeIcon
                    icon={faUserFriends}
                    color={this.fmcUsersTabIsActive() ? activeColor : inactiveColor}
                    size="3x"
                    className={isFleetcor() ? "icon-hover" : ""}
                  />
                }
                linkPath={navigationPaths.fmcUsers()}
              />
              <NavItem
                active={this.fmcOrdersTabIsActive()}
                content={
                  <FontAwesomeIcon
                    icon={faUserClock}
                    color={this.fmcOrdersTabIsActive() ? activeColor : inactiveColor}
                    size="3x"
                    className={isFleetcor() ? "icon-hover" : ""}
                  />
                }
                linkPath={navigationPaths.fmcActiveOrders()}
              />
              <NavItem
                active={this.fmcTransactionsTabIsActive()}
                content={
                  <FontAwesomeIcon
                    icon={faFileInvoiceDollar}
                    color={this.fmcTransactionsTabIsActive() ? activeColor : inactiveColor}
                    size="3x"
                    className={isFleetcor() ? "icon-hover" : ""}
                  />
                }
                linkPath={navigationPaths.fmcTransactions()}
              />
              <NavItem
                active={this.transferVehiclesTabIsActive()}
                content={
                  <ReactSVG
                    src={`/images/transfer_vehicles.svg`}
                    svgStyle={{ ...extraStyles, width: 70, height: 70 }}
                    className={
                      this.transferVehiclesTabIsActive()
                        ? isFleetcor()
                          ? "transfer_vehicles_active icon-hover"
                          : "transfer_vehicles_active"
                        : isFleetcor()
                        ? "transfer_vehicles_inactive icon-hover"
                        : "transfer_vehicles_inactive"
                    }
                  />
                }
                linkPath={navigationPaths.transferVehicles()}
              />
            </React.Fragment>
          )}
        </Menu>
      </Grid.Column>
    )
  }
} // class Nav

const mapStateToProps = (state) => {
  const user = state.application.userSessionData || (state.users && state.users.currentUser)
  const isFMC =
    (state.application.userSessionData && state.application.userSessionData.isFMC) ||
    isFMCUser(user)
  const isPathFMC = isFmcPath()
  return {
    isFMC: isFMC,
    isFMCMode: state.application.mode === FMC && isFMC && isPathFMC,
    isFleetMode: state.application.mode === FLEET || (isFMC && !isPathFMC),
    fleet: state.application.fleetSessionData || state.fleets.fleet,
    account_fleets: state.users.account_fleets,
    currentUser: user
  }
}

export default connect(mapStateToProps)(Nav)
