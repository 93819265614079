import { call, put, takeEvery } from "redux-saga/effects"

import api from "../../utilities/api"
import * as apiErrorHelpers from "../../helpers/apiErrorHelpers"
import * as sessionHelpers from "../../helpers/sessionHelpers"
import moment from "moment"
import { Date_Format } from "../../constants/years"

export function* loadDashboard(action) {
  try {
    yield put({ type: "SET_LOADING" })

    yield put({ type: "UNSET_LOADING_ERROR" })

    api.setAuthApi({
      Authorization: sessionHelpers.getApplicationCookie("authentication_token")
    })

    ///////////////////////
    //  LOAD USERS DATA  //
    ///////////////////////
    // yield put({ type: "USERS_LOAD_REQUESTED" })

    // const usersRequest = {
    //   url: "/fleet_users",
    //   body: {}
    // }
    //
    // const usersResponse = yield call(api.utility.get, api.path(usersRequest.url), {
    //   body: usersRequest.body
    // })
    //
    // apiErrorHelpers.catchErrors(usersResponse.body)
    // yield put({
    //   type: "USERS_LOAD_SUCCEEDED",
    //   payload: {
    //     users: usersResponse.body
    //   }
    // })
    ///////////////////////

    ////////////////////////
    // LOAD VEHICLES DATA //
    ////////////////////////
    yield put({ type: "VEHICLES_LOAD_REQUESTED" })

    const vehiclesRequest = {
      url: "/vehicles",
      body: {
        request: "active_order"
      }
    }

    const vehiclesResponse = yield call(api.utility.get, api.path(vehiclesRequest.url), {
      body: vehiclesRequest.body
    })

    apiErrorHelpers.catchErrors(vehiclesResponse.body)

    yield put({
      type: "VEHICLES_LOAD_SUCCEEDED",
      payload: {
        vehicles: vehiclesResponse.body
      }
    })

    yield put({
      type: "SET_VEHICLES_DATA_TYPE",
      payload: { dataType: "extended" }
    })
    ///////////////////////

    ////////////////////////
    // LOAD SERVICE COSTS DATA //
    ////////////////////////
    yield put({ type: "SERVICE_COSTS_LOAD_REQUESTED" })

    const serviceCostsRequest = {
      url: "/orders/aggregate",
      body: {
        start_date: moment().subtract("years", 1).format("YYYY-MM-01"),
        end_date: moment().endOf("month").format(Date_Format.YYYY_MM_DD),
        grouping: "month"
      }
    }

    const serviceCostsResponse = yield call(api.utility.get, api.path(serviceCostsRequest.url), {
      body: serviceCostsRequest.body
    })

    apiErrorHelpers.catchErrors(serviceCostsResponse.body)

    yield put({
      type: "SERVICE_COSTS_LOAD_SUCCEEDED",
      payload: {
        serviceCosts: serviceCostsResponse.body
      }
    })

    yield put({ type: "UNSET_LOADING" })

    /////////////////////////
    //  LOAD INTERVAL DATA ASYNC //
    /////////////////////////
    yield put({ type: "MAINTENANCE_INTERVAL_SAGA" })
  } catch (errors) {
    // eslint-disable-next-line
    errors = apiErrorHelpers.formatInternalErrors(errors)

    yield put({ type: "LOADING_FAILED" })
    yield put({ type: "SET_LOADING_ERROR" })
    yield put({ type: "UNSET_LOADING" })

    apiErrorHelpers.consoleLog(errors)
  }
} // function* loadDashboard

export default function* dashboardSaga() {
  yield takeEvery("DASHBOARD_LOAD_SAGA", loadDashboard)
}
