import { call, put, takeEvery } from "redux-saga/effects"

import api from "../../utilities/api"
import * as apiErrorHelpers from "../../helpers/apiErrorHelpers"
import * as sessionHelpers from "../../helpers/sessionHelpers"
import moment from "moment"

export function* toggleMobileMenu(action) {
  yield put({ type: "TOGGLE_MOBILE_MENU" })
} // function* toggleMobileMenu

export function* fmcFlip(action) {
  const { payload, callback } = action
  yield put({ type: "FMC_FLIP", payload: payload || {} })
  if (callback) {
    callback()
  }
} // function* fmcFlip

export default function* applicationSaga() {
  yield takeEvery("TOGGLE_MOBILE_MENU_SAGA", toggleMobileMenu)
  yield takeEvery("FMC_FLIP_SAGA", fmcFlip)
}
