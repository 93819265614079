import React, { PureComponent } from "react"
import { Link } from "react-router-dom"

import VehicleModal from "./VehicleModal"
import { agGridColumnDefs, agGridRowData } from "../../constants/vehicleSchedule"
import { navigationPaths } from "../../constants/paths"
import LoadingThrobber from "../../components/shared/LoadingThrobber"

import { AgGridReact } from "ag-grid-react"
import { Segment, Checkbox as SematicCheckbox } from "semantic-ui-react"
import { withRouter } from "react-router-dom"
import debounce from "debounce"
import { withTranslation } from "react-i18next"
import frGrid from "../../locales/fr-ca/grid.json"
import enGrid from "../../locales/en/grid.json"
import { FR_LOCALE } from "../../constants/users"
import CustomNoRowsOverlay from "../shared/CustomNoRowsOverlay"
import CustomLoadingOverlay from "../shared/CustomLoadingOverlay"

class VehicleMaintenceSchedule extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      sizeToFit: false,
      showGrid: true,
      page: 1,
      prevBottomDisplayedRowIndex: 0
    }
    this.debouncedOnFilterChange = debounce(this.onFilterChange, 300)
    this.debouncedOnSizeToFitChange = debounce(this.onSizeToFitChange, 100)
  }

  editCellRenderer = (cellParams) => {
    const vehicle = cellParams.value
    const { loadVehicles, t } = this.props
    return (
      <VehicleModal completionCallback={loadVehicles} vehicle={vehicle}>
        <span className="link">{t("common:editLabel")}</span>
      </VehicleModal>
    )
  }

  deleteCellRenderer = (cellParams) => {
    const vehicle = cellParams.value
    return (
      <span className="link" onClick={() => this.props.onDeleteVehicle(vehicle.id)}>
        {this.props.t("deleteLabel")}
      </span>
    )
  }

  deactivateCellRenderer = (cellParams) => {
    const vehicle = cellParams.value
    return (
      <div
        role="presentation"
        onClick={() => this.props.onDeactivateVehicle(vehicle.is_active, vehicle.id)}
      >
        <SematicCheckbox toggle checked={vehicle.is_active} />
      </div>
    )
  }

  callToActionRenderer = (cellParams) => {
    const vehicle = cellParams.data.vehicle
    const vehicleId = cellParams.data.id
    const ctaValsPresent = cellParams.value
    const vehicleDetailsLink = (
      <Link className={"ag-grid-link-column"} to={navigationPaths.vehicleShow(vehicleId)}>
        <div>{cellParams.value}</div>
      </Link>
    )
    const { t, loadVehicles } = this.props
    const ctaValsModal = (
      <VehicleModal editYmmOnly={true} completionCallback={loadVehicles} vehicle={vehicle}>
        <span class="link">{t("EditAddYMMLabel")}</span>
      </VehicleModal>
    )

    return <div>{ctaValsPresent ? vehicleDetailsLink : ctaValsModal}</div>
  }

  // NOTE: This is used over linking from ag-grid's onRowSelected, because clicking a column button (i.e. Edit), will
  // fire both the row and column click handlers. This will take you to the expected edit page, but polutes the
  // navigation stack, and breaks expected back button functionality.
  // NOTE: This is not needed without linking, as it just wraps the ag-grid default render with a link.
  defaultCellRenderer(cellParams) {
    const vehicleId = cellParams.data.id

    return (
      // <Link className={"ag-grid-link-column"} to={navigationPaths.vehicleShow(vehicleId)}>
      <div>{cellParams.value}</div>
      // </Link>
    )
  }

  onFilterChange = (_, event) => {
    this.setState({ quickFilterText: event.value })
  }

  onGridReady = (params) => {
    this.api = params.api
    this.columnApi = params.columnApi
    if (this.state.sizeToFit) params.api.sizeColumnsToFit()
    window.addEventListener("resize", () => this.state.sizeToFit && this.api.sizeColumnsToFit())
  }

  resetFiltering = () => this.api.setFilterModel([])

  setFilteredItems = () =>
    this.setState({ filteredItems: this.api.getModel().rowsToDisplay.map((r) => r.data) })

  onSizeToFitChange = (event, syntheticEvent) => {
    this.setState({ sizeToFit: syntheticEvent.checked })
    if (syntheticEvent.checked) this.api.sizeColumnsToFit()
    else this.redrawGrid()
  }

  redrawGrid() {
    this.setState({ showGrid: false })
    setTimeout(() => this.setState({ showGrid: true }), 500)
  }

  handlePageChange = async ({ api: pageApi, newPage }) => {
    const { page, prevBottomDisplayedRowIndex } = this.state
    const {
      dispatch,
      vehicle: { id: vehicleId }
    } = this.props
    const bottomDisplayedRowIndex = pageApi.paginationProxy.bottomDisplayedRowIndex / 10
    if (newPage && page <= 7 && bottomDisplayedRowIndex > prevBottomDisplayedRowIndex) {
      this.api.showLoadingOverlay()
      await this.setState({ page: page + 1, prevBottomDisplayedRowIndex: bottomDisplayedRowIndex })
      await dispatch({
        type: "VEHICLE_LOAD_SERVICES_SAGA",
        payload: {
          vehicleId,
          page: page + 1
        },
        callback: this.afterLoadServices
      })
    }
  }

  afterLoadServices = (message) => {
    if (message === "success") {
      this.api.hideOverlay()
    }
  }

  render() {
    const {
      language,
      t,
      vehiclesDetails,
      vehicle: { id: vehicleId },
      isMaintenanceScheduleLoading
    } = this.props

    return (
      <div>
        <span />
        <Segment
          className={"vehicle-maintenance ag-theme-material"}
          style={{
            height: "40rem",
            width: "55rem"
          }}
        >
          {!isMaintenanceScheduleLoading ? (
            <AgGridReact
              columnDefs={agGridColumnDefs(this.defaultCellRenderer, t)}
              onPaginationChanged={this.handlePageChange}
              defaultColDef={{
                sortable: true,
                filter: true
              }}
              rowData={agGridRowData(vehiclesDetails.vehicleServices, vehicleId)}
              rowSelection={"single"}
              onGridReady={this.onGridReady}
              gridOptions={{
                onFilterChanged: this.setFilteredItems
              }}
              frameworkComponents={{
                customNoRowsOverlay: CustomNoRowsOverlay,
                customLoadingOverlay: CustomLoadingOverlay
              }}
              noRowsOverlayComponent={"customNoRowsOverlay"}
              noRowsOverlayComponentParams={{
                translationFile: "vehicleIndex",
                label: "noVehiclesInFleetLabel"
              }}
              localeText={language === FR_LOCALE ? frGrid : enGrid}
              quickFilterText={this.state.quickFilterText}
              cacheQuickFilter={true}
              pagination={true}
              paginationPageSize={10}
              loadingOverlayComponent={"customLoadingOverlay"}
              loadingOverlayComponentParams={{
                loadingMessage: t("oneMomentPleaseLabel")
              }}
              suppressMenuHide={true}
              suppressMovableColumns={false}
              suppressCellSelection={true}
            ></AgGridReact>
          ) : (
            <LoadingThrobber visible />
          )}
        </Segment>
      </div>
    )
  }
} // class VehicleMaintenceSchedule

export default withRouter(withTranslation("schedule")(VehicleMaintenceSchedule))
