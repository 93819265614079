import React from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faSpinner } from "@fortawesome/pro-solid-svg-icons"

export default (props) => {
  return (
    <div className="ag-custom-loading-cell" style={{ paddingLeft: "10px", lineHeight: "25px" }}>
      <FontAwesomeIcon icon={faSpinner} className="fa-spin" />
      <span>&nbsp;{props.loadingMessage}</span>
    </div>
  )
}
