import React, { Component } from "react"
import PropTypes from "prop-types"
import { Link } from "react-router-dom"

import { navigationPaths } from "../../constants/paths"
import * as shopHelpers from "../../helpers/shopHelpers"

import SemanticButton from "../../elements/Button"
import { Table, Icon, Button } from "semantic-ui-react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import * as faSolid from "@fortawesome/pro-solid-svg-icons"
import * as faLight from "@fortawesome/pro-light-svg-icons"
import { getStyles } from "../shared/StylesheetInjector"
import { withTranslation } from "react-i18next"
import { isFleetMaintenanceHubCanada } from "../../helpers/affiliationHelpers"

const styles = getStyles()

class ShopListItem extends Component {
  static propTypes = {
    onFavoriteSubmit: PropTypes.func.isRequired,
    shop: PropTypes.object.isRequired,
    previousFormData: PropTypes.object
  }

  state = {
    formData: {
      id: this.props.shop.id
    }
  }

  formattedFormData() {
    const { shop } = this.props
    return {
      id: shop.id,
      value: !shop.is_favorite_shop
    }
  }

  toggleFavoriteState = () => {
    this.props.onFavoriteSubmit(this.formattedFormData())
  }

  render() {
    const {
      shop,
      searchMode,
      previousFormData,
      handleSelectShop,
      asSubForm,
      disableSubmit,
      language,
      t
    } = this.props
    const formData = previousFormData || {}

    return (
      <Table.Row style={{ borderBottom: "1px solid rgba(0, 0, 0, 0.1)" }}>
        <Table.Cell>
          <FontAwesomeIcon
            style={{ cursor: "pointer", color: styles.rechartsSecondaryColor }}
            className={"fas"}
            icon={shop.is_favorite_shop ? faSolid.faStar : faLight.faStar}
            size="2x"
            onClick={this.toggleFavoriteState}
          />
        </Table.Cell>
        <Table.Cell>
          <img style={{ width: "30px", margin: "0 auto" }} src={shop.logo_url} />
        </Table.Cell>
        <Table.Cell>{shop.name}</Table.Cell>
        {asSubForm && <Table.Cell>{shop.shopPriceText}</Table.Cell>}
        {searchMode &&
          (isFleetMaintenanceHubCanada() ? (
            <Table.Cell>
              {shop.kilometers == null ? "--" : `${shop.kilometers.toFixed(2)} km`}
            </Table.Cell>
          ) : (
            <Table.Cell>
              {shop.distance == null ? "--" : `${shop.distance.toFixed(2)} mi`}
            </Table.Cell>
          ))}
        <Table.Cell>
          <a
            className={"shop__address_link link"}
            href={shopHelpers.googleMapLink(shop)}
            target={"_blank"}
          >
            {shopHelpers.formattedAddress(shop)}
          </a>
        </Table.Cell>
        {!asSubForm && (
          <>
            <Table.Cell>
              <Link
                to={{
                  pathname: navigationPaths.MaintenanceHistoryNew(),
                  state: {
                    previousFormData: { ...formData, shop_id: shop.id, shop: shop },
                    shops: [shop]
                  }
                }}
              >
                <Button size="tiny">
                  {t("addMaintenanceHistoryLabel")} <FontAwesomeIcon icon={faLight.faPlus} />
                </Button>
              </Link>
            </Table.Cell>
            <Table.Cell>
              <Link
                to={{
                  pathname: navigationPaths.serviceRequestNew(),
                  state: {
                    previousFormData: { ...formData, shop_id: shop.id, shop: shop },
                    shops: [shop]
                  }
                }}
              >
                <Button size="tiny">{t("scheduleServiceLabel")}</Button>
              </Link>
            </Table.Cell>
          </>
        )}
        {asSubForm && (
          <Table.Cell>
            <SemanticButton
              disabled={disableSubmit}
              label={t("scheduleAppointmentLabel")}
              onClick={() => handleSelectShop(shop)}
            />
          </Table.Cell>
        )}
      </Table.Row>
    )
  }
} // class ShopListItem

export default withTranslation("common")(ShopListItem)
