import React, { Component } from "react"
import PropTypes from "prop-types"
import Button from "../../elements/Button"
import { Form, Message } from "semantic-ui-react"
import { elements } from "caradvise_shared_components"
import { withTranslation } from "react-i18next"
import { GeotabDatabaseUrlDescription } from "../geotab_telematics/GeotabTelematicsData"

const { ModalDialog, PrivacyPolicyAndTerms } = elements
const EMAIL_REGEX = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

class ShellNewUserForm extends Component {
  static propTypes = {
    onSubmit: PropTypes.func.isRequired,
    isSubmitting: PropTypes.bool,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    cellPhone: PropTypes.string,
    geotabDatabaseName: PropTypes.string,
    email: PropTypes.string,
    fleetName: PropTypes.string,
    fleetNameEditable: PropTypes.bool
  }

  static defaultProps = {
    isSubmitting: false
  }

  state = {
    formData: {
      firstName: this.props.firstName || "",
      lastName: this.props.lastName || "",
      email: this.props.email || "",
      cellPhone: this.props.cellPhone || "",
      geotabDatabaseName: this.props.geotabDatabaseName || "",
      fleetName: this.props.fleetName || ""
    },
    errors: {}
  }

  validateAndComplete = () => {
    const {
      firstName,
      lastName,
      email,
      cellPhone,
      geotabDatabaseName,
      fleetName
    } = this.state.formData
    const { fleetNameEditable } = this.props

    const errors = {}
    if (!email.match(EMAIL_REGEX)) errors.email = "Email format invalid"

    if (fleetNameEditable && fleetName.length === 0)
      errors.fleetName = "Company name must be present"

    if (firstName.length === 0) errors.first = "First name must be present"

    if (lastName.length === 0) errors.last = "Last name must be present"

    if (cellPhone.replace(/[^\d]/g, "").length !== 10)
      errors.cell = "Mobile Number must be 10 digits, only numbers"

    if (Object.keys(errors).length === 0) {
      this.props.onSubmit({
        fleetName: fleetName,
        email: email,
        firstName: firstName,
        lastName: lastName,
        cellPhone: cellPhone.replace(/[^\d]/g, ""),
        geotabDatabaseName: geotabDatabaseName
      })
    } else {
      this.setState({ errors: errors })
    }
  }

  disableSubmit = () => {
    return this.props.isSubmitting
  }

  onFieldChange = (field, event) => {
    let updatedFormData = { ...this.state.formData }
    updatedFormData[field] = event.target.value
    this.setState({ formData: updatedFormData })
  }

  onKeyDown = (event) => {
    if (event.key === "Enter") this.validateAndComplete()
  }

  render() {
    const {
      firstName,
      lastName,
      email,
      cellPhone,
      geotabDatabaseName,
      fleetName
    } = this.state.formData
    const { fleetNameEditable } = this.props
    const { errors } = this.state

    return (
      <div className={"left-aligned-form"} onKeyDown={(event) => this.onKeyDown(event)}>
        <h1 style={{ textAlign: "left", paddingBottom: "20px" }}>Sign Up</h1>

        <Form error>
          {fleetNameEditable && (
            <Form.Input
              value={fleetName}
              onChange={(e) => this.onFieldChange("fleetName", e)}
              fluid
              label="Company Name"
              error={errors.fleetName}
            />
          )}
          <Form.Group widths="equal">
            <Form.Input
              value={firstName}
              onChange={(e) => this.onFieldChange("firstName", e)}
              fluid
              label="First Name"
              error={errors.firstName}
            />
            <Form.Input
              value={lastName}
              onChange={(e) => this.onFieldChange("lastName", e)}
              fluid
              label="Last Name"
              error={errors.lastName}
            />
          </Form.Group>
          <Form.Input
            value={email}
            onChange={(e) => this.onFieldChange("email", e)}
            fluid
            label="Email"
            error={errors.email}
          />
          <Form.Input
            value={cellPhone}
            onChange={(e) => this.onFieldChange("cellPhone", e)}
            fluid
            error={errors.cellPhone}
            label={
              <div className="label-and-link">
                <label>
                  <strong style={{ color: "black", fontSize: "13px" }}>Mobile Number</strong>
                </label>
                <ModalDialog linkText="Why do we need this?" className="primary-button-color">
                  Your smartphone number is required for order updates and to approve work
                  electronically through FleetMaintenanceHub.
                </ModalDialog>
              </div>
            }
          />

          {/*
          <Form.Input
            value={geotabDatabaseName}
            onChange={(e) => this.onFieldChange("geotabDatabaseName", e)}
            fluid
            error={errors.geotabDatabaseName}
            label={
              <div className="label-and-link">
                <label>
                  <strong style={{ color: "black", fontSize: "13px" }}>
                    Shell Telematics Database Name
                  </strong>
                </label>
                <ModalDialog linkText="Why do we need this?" className="primary-button-color">
                  Fill this in if you have a Shell-Geotab account and want your vehicle data synced.
                  <br />
                  <br />
                  <GeotabDatabaseUrlDescription />
                </ModalDialog>
              </div>
            }
          />
          */}

          {Object.values(errors).length > 0 && (
            <Message error>
              <Message.Header style={{ padding: 0 }}>Errors:</Message.Header>
              <Message.List>
                {Object.values(errors).map((e) => {
                  return <Message.Item>{e}</Message.Item>
                })}
              </Message.List>
            </Message>
          )}
        </Form>

        <div className={"left-aligned-field left-aligned-submit"}>
          <Button
            className={"button--submit"}
            disabled={this.disableSubmit()}
            label={"Sign Up"}
            onClick={this.validateAndComplete}
          />
        </div>

        <PrivacyPolicyAndTerms
          className="left-align-privacy-terms"
          actionText={"signing up"}
          t={this.props.t}
        />
      </div>
    )
  }
} // class ShellNewUserForm

export default withTranslation("privacyPolicyAndTerms")(ShellNewUserForm)
