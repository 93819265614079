import React, { Component } from "react"
import { connect } from "react-redux"
import { withTranslation } from "react-i18next"
import { Modal, Header, Segment, Grid, Label } from "semantic-ui-react"

class SubscriptionActivityModal extends Component {
  constructor(props) {
    super(props)

    this.state = {
      modalOpen: false
    }
  }

  loadSubscriptionPaymentMethod = () => {
    const { subscription_id } = this.props
    this.props.dispatch({
      type: "SUBSCRIPTION_ACTIVITY_LOAD_SAGA",
      payload: {
        options: {
          subscription_id: subscription_id
        }
      },
      callback: this.afterRequestSuccess
    })
  }

  afterRequestSuccess = () => {
    this.setState({ modalOpen: true })
  }

  handleOpen = async () => {
    this.loadSubscriptionPaymentMethod()
  }

  handleClose = () => {
    this.setState({ modalOpen: false })
  }

  renderView = () => {
    const { subscriptionActivity, t } = this.props

    return (
      <React.Fragment>
        <Header textAlign="center" className="primary-color">
          {t("subscriptionDetails")}
        </Header>
        <Segment>
          <Grid.Row>
            <Label> {t("fleetName")} </Label>{" "}
            {(subscriptionActivity && subscriptionActivity.fleet_name) || "N/A"}
          </Grid.Row>
          <Grid.Row>
            <Label> {t("methodOfPayment")} </Label>{" "}
            {(subscriptionActivity && subscriptionActivity.method_of_payment) || "N/A"}
          </Grid.Row>
        </Segment>
      </React.Fragment>
    )
  }

  render() {
    const { children } = this.props
    return (
      <Modal
        size="mini"
        trigger={
          React.Children.map(children, (c) =>
            React.cloneElement(c, { onClick: this.handleOpen })
          )[0]
        }
        open={this.state.modalOpen}
        closeIcon
        onClose={this.handleClose}
      >
        <Modal.Content>{this.renderView()}</Modal.Content>
      </Modal>
    )
  }
} // class SubscriptionActivityModal

const mapStateToProps = (state) => {
  return {
    subscriptionActivity: state.paymentActivities.subscriptionActivity.subscription_activity
  }
}

export default connect(mapStateToProps)(withTranslation("services")(SubscriptionActivityModal))
