import React from "react"

const Switch = ({ name, isOn, handleToggle, onColor }) => {
  return (
    <div className="react-switch-container">
      <input
        checked={isOn}
        onChange={handleToggle}
        className="react-switch-checkbox"
        id={name}
        type="checkbox"
      />
      <label style={{ background: isOn && onColor }} className="react-switch-label" htmlFor={name}>
        <span className={`react-switch-button`} />
      </label>
    </div>
  )
}

export default Switch
