import React, { Component } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"

import Alert from "../../components/shared/Alert"
import ApplicationLayout from "../../layouts/ApplicationLayout"
import LoadingError from "../../components/shared/LoadingError"
import LoadingThrobber from "../../components/shared/LoadingThrobber"
import VehicleList from "../../components/vehicles/VehicleList"
import Stats from "../../components/vehicles/Stats"
import { withTranslation } from "react-i18next"
import AlertModal from "../../components/shared/AlertModal"
import { VEHICLE_INDEX } from "../../constants/application"
import * as sessionHelpers from "../../helpers/sessionHelpers"
import { DRIVER, MANAGER } from "../../constants/roles"

class VehicleIndex extends Component {
  static propTypes = {
    isLoading: PropTypes.bool.isRequired,
    isLoadingError: PropTypes.bool.isRequired,
    users: PropTypes.array.isRequired,
    vehicles: PropTypes.array.isRequired,
    vehiclesDataType: PropTypes.string.isRequired,
    fleet: PropTypes.object.isRequired
  }

  constructor(props) {
    super(props)

    // NOTE: locationStateData is instantiated as a variable here rather than referencing this.props.location.state.someObj directly because the state key may not be present when navigating to this page from a <Link> that does not explicitly set it.
    const locationStateData = this.props.location.state || {}

    this.state = {
      alert: locationStateData.alert || "",
      alertType: locationStateData.alertType || "default",
      openAlertModal: false,
      vehicleIdToDelete: null
    }

    this.loading = true
  }

  onDeleteVehicle = (vehicleId, t) => {
    const confirmed = window.confirm(t("deleteConfirmLabel"))

    if (confirmed) {
      this.props.dispatch({
        type: "VEHICLE_DELETE_SAGA",
        payload: {
          vehicleId: vehicleId
        },
        callback: this.afterDeleteVehicle.bind(this)
      })
    }
  }

  onDeactivateVehicle = (vehicleStatus, vehicleId, t) => {
    var confirmed
    if (vehicleStatus == true) {
      confirmed = window.confirm(t("deactivateConfirmLabel"))
    } else {
      confirmed = window.confirm(t("activateConfirmLabel"))
    }

    if (confirmed) {
      this.props.dispatch({
        type: "VEHICLE_DEACTIVATE_SAGA",
        payload: {
          vehicleId: vehicleId
        },
        callback: this.afterDeleteVehicle.bind(this)
      })
    }
  }

  handleModalState = () => {
    this.setState({ openAlertModal: false })
  }

  onDeleteVehicleSuccess(data) {
    this.props.history.push({
      state: {
        alertMessage: data.alertMessage,
        alertType: data.alertType
      }
    })
    this.loadAllVehicles()
  }

  loadAllVehicles = async () => {
    const { id } = this.props.fleet
    const { currentUser } = this.props
    this.loading = true
    await this.props.dispatch({
      type: "VEHICLES_LOAD_SAGA",
      payload: {
        options: {
          fleet_id: !currentUser.roles.find((role) => role === MANAGER || role === DRIVER) && id
        }
      }
    })
    this.loading = false
  }

  afterDeleteVehicle = (status, data) => {
    if (status === "success") {
      this.onDeleteVehicleSuccess(data)
    } else {
      this.onRequestFailure(data)
    }
  }

  onRequestFailure = (data) => {
    this.setState({
      alertMessage: data.alertMessage,
      alertType: data.alertType
    })
  }

  componentDidMount() {
    this.loadAllVehicles()
  }

  shouldRenderContent() {
    const { isLoading, isLoadingError } = this.props

    // NOTE: vehiclesDataType check is not required for the current version of the vehicles index. However, if the vehicles data loaded by the dashboard should change in the future and break this view, than it can be enabled.
    // NOTE: Enabling the vehiclesDataType check will cause an issue where if the vehicles index is rendered and the VEHICLES_LOAD_SAGA completes before the DASHBOARD_LOAD_SAGA completes, the DASHBOARD_LOAD_SAGA will continue to run in the background, and wipe out the results on the vehicles index when it completes and sets the vehiclesDataType to "extended".
    // Steps to reproduce:
    // 1. Go to dashboard tab.
    // 2. While dashboard is still loading, click on vehicles tab. You should see index of vehicles.
    // 3. Wait, the vehicles list should disappear (You can watch redux tools for the DASHBOARD_LOAD_SAGA to complete).
    return !isLoading && !isLoadingError && !this.loading // && vehiclesDataType === 'standard'
  }

  renderContent() {
    const { users, vehicles, fleet, currentUser, language, dispatch, t } = this.props

    if (this.shouldRenderContent()) {
      return (
        <div>
          <Stats fleet={fleet} vehicles={vehicles} language={language} />
          <VehicleList
            users={users}
            vehicles={vehicles}
            onDeleteVehicle={this.onDeleteVehicle}
            onDeactivateVehicle={this.onDeactivateVehicle}
            language={language}
            dispatch={dispatch}
            currentUser={currentUser}
          />
        </div>
      )
    } else {
      return null
    }
  }

  render() {
    const { isLoading, isLoadingError } = this.props

    const { alert, alertType, openAlertModal, vehicleIdToDelete } = this.state

    return (
      <ApplicationLayout>
        <Alert message={alert} type={alertType} visible={alert !== ""} />

        <LoadingThrobber visible={isLoading} />

        <LoadingError visible={!isLoading && isLoadingError} />

        {this.renderContent()}
        {openAlertModal ? (
          <AlertModal
            openAlertModal={openAlertModal}
            hideModal={this.handleModalState}
            idToDelete={vehicleIdToDelete}
            afterDelete={this.afterDeleteVehicle}
            that={this}
            modelFrom={VEHICLE_INDEX}
          />
        ) : null}
      </ApplicationLayout>
    )
  }
} // class VehicleIndex

const mapStateToProps = (state) => ({
  isLoading: state.application.isLoading,
  isLoadingError: state.application.isLoadingError,
  users: state.users.users,
  vehicles: state.vehicles.vehicles,
  vehiclesDataType: state.vehicles.vehiclesDataType,
  fleet: state.application.fleetSessionData || state.fleets.fleet,
  language:
    (state.application.userSessionData && state.application.userSessionData.language) ||
    (state.users.currentUser && state.users.currentUser.language),
  currentUser: state.application.userSessionData || state.users.currentUser
})

export default connect(mapStateToProps)(withTranslation("vehicle")(VehicleIndex))
