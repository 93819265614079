const USERS = "users"
const POLICIES = "policies"
const REMINDERS = "reminders"
const GEOTAB_TELEMATICS_DATA = "geotabTelematicsData"
const WEX = "wex"
const BRAINTREE = "braintree"
const PAYMENTS = "payments"

export const SETTINGS_MENU_ITEMS = {
  USERS,
  POLICIES,
  REMINDERS,
  GEOTAB_TELEMATICS_DATA,
  WEX,
  BRAINTREE,
  PAYMENTS
}
