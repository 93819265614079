import React from "react"

import { Icon, Header, Checkbox } from "semantic-ui-react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faWrench } from "@fortawesome/free-solid-svg-icons"
import { useTranslation } from "react-i18next"

const SectionTitle = (props) => {
  const {
    title,
    count,
    bgColor = "#000",
    completed,
    checked,
    addAllServices,
    markAllCompleted,
    showSelectAllCheckbox
  } = props
  const { t } = useTranslation("maintenance")
  return (
    <div className="manufacturer-title">
      <Header as="h4">{title}</Header>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "center"
        }}
      >
        <div
          className="wrench-icon-container"
          style={{ backgroundColor: bgColor, marginRight: "10px" }}
        >
          <FontAwesomeIcon icon={faWrench} />
          <span style={{ padding: "4px" }}>{count}</span>
        </div>
        {!completed && !markAllCompleted && showSelectAllCheckbox && (
          <>
            <div>
              <Checkbox checked={checked} onChange={() => addAllServices()} />
            </div>
          </>
        )}
      </div>
    </div>
  )
}

export default SectionTitle
