import React, { Component } from "react"
import { connect } from "react-redux"
import PropTypes from "prop-types"
import { Button, Table } from "semantic-ui-react"
import { withTranslation } from "react-i18next"
import { isFleetMaintenanceHubCanada } from "../../helpers/affiliationHelpers"
import MaintenanceHistoryRecordSummary from "./MaintenanceHistoryRecordSummary"

class MaintenanceHistorySummary extends Component {
  static propTypes = {
    vehicle: PropTypes.object.isRequired
  }
  constructor(props) {
    super(props)
    this.state = {
      pageMH: 1,
      pageMMH: 1,
      isSeeMoreEnabled: true,
      isLoading: this.props.isVehicleMHComplete ? false : true
    }
  }

  componentDidMount() {
    if (!this.props.isVehicleMHComplete) {
      this.handleMoreButtonClick()
    }
  }

  handleMoreButtonClick = () => {
    const { dispatch, vehicle } = this.props
    const { pageMH, pageMMH, prevPageMH, prevPageMMH } = this.state
    const payload = {}
    payload.pageMH = prevPageMH !== pageMH ? pageMH : null
    payload.pageMMH = prevPageMMH !== pageMMH ? pageMMH : null
    payload.vehicleId = vehicle.id
    this.setState({ isLoading: true })
    dispatch({
      type: "MAINTENANCE_HISTORIES_LOAD_SAGA",
      payload,
      callback: this.afterMaintenanceHistoryLoad
    })
  }

  afterMaintenanceHistoryLoad = (totalPageMMH, totalPageMH) => {
    const { pageMH, pageMMH } = this.state
    let currentPageMH = pageMH
    let currentPageMMH = pageMMH
    if (pageMH < totalPageMH) {
      currentPageMH += 1
    }
    if (pageMMH < totalPageMMH) {
      currentPageMMH += 1
    }
    if (pageMH !== currentPageMH || pageMMH !== currentPageMMH) {
      this.setState({
        pageMH: currentPageMH,
        pageMMH: currentPageMMH,
        prevPageMH: pageMH,
        prevPageMMH: pageMMH,
        isLoading: false
      })
    } else {
      this.setState({ isSeeMoreEnabled: false, isLoading: false })
      this.props.dispatch({
        type: "VEHICLE_MAINTENANCE_HISTORIES_LOAD_COMPLETE"
      })
    }
  }

  renderHistoryRecords() {
    const { maintenance_histories } = this.props

    return maintenance_histories.map((record, index) => {
      return <MaintenanceHistoryRecordSummary key={index} record={record} {...this.props} />
    })
  }

  render() {
    const { isVehicleMHComplete, t } = this.props
    const { isLoading, isSeeMoreEnabled } = this.state
    if (isLoading) {
      return (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center"
          }}
        >
          <div className={"spinner"}></div>
        </div>
      )
    }
    return (
      <React.Fragment>
        <Table className="borderless">
          <Table.Header>
            <Table.Row>
              {[
                t("dateLabel"),
                t("shopLabel"),
                isFleetMaintenanceHubCanada() ? t("dashboard:kilometersLabel") : "Miles",
                t("completedServicesLabel"),
                t("savedServicesLabel"),
                t("costLabel")
              ].map((s) => (
                <Table.HeaderCell singleLine>{s}</Table.HeaderCell>
              ))}
            </Table.Row>
          </Table.Header>
          <Table.Body style={{ overflow: "auto" }}>{this.renderHistoryRecords()}</Table.Body>
        </Table>
        {isSeeMoreEnabled && (
          <Button
            onClick={this.handleMoreButtonClick}
            disabled={!isSeeMoreEnabled || isVehicleMHComplete}
          >
            {t("seeMoreLbl")}
          </Button>
        )}
      </React.Fragment>
    )
  }
} // class MaintenanceHistory

const mapStateToProps = (state) => {
  const { vehicle, isVehicleMHComplete } = state.vehicles
  return {
    currentUser: state.users.currentUser,
    maintenance_histories: (vehicle.maintenance_histories || []).concat(
      vehicle.manualMaintenanceHistories || []
    ),
    isVehicleMHComplete
  }
}

export default connect(mapStateToProps)(withTranslation("vehicle")(MaintenanceHistorySummary))
