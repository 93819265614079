import React from "react"
import { SCHEDULED_STATUSES, AWAITING_APPROVAL, APPROVAL } from "../../constants/statuses"
import { Segment, Grid, Container, Header, Image, Divider, Button, Label } from "semantic-ui-react"
import { navigationPaths } from "../../constants/paths"
import { Link } from "react-router-dom"
import * as vehicleHelpers from "../../helpers/vehicleHelpers"
import * as maintenanceHelpers from "../../helpers/maintenanceHelpers"
import VehicleModal from "../../components/vehicles/VehicleModal"
import DueStatusLabel from "../../components/shared/DueStatusLabel"
import { useTranslation } from "react-i18next"
import { isFleetcor } from "../../helpers/affiliationHelpers"

export default function VehiclesWidget({ vehicles, maintenanceIntervals }) {
  const { t } = useTranslation("dashboard")

  let sorted = maintenanceHelpers
    .upcomingMaintenanceData({
      vehicles,
      serviceSchedulesByVehicle: maintenanceIntervals.service_schedules_by_vehicle
    })
    .sort((a, b) => {
      // TODO Is interval the correct thing to be sorting by?
      // Does interval alone communicate which vehicles will
      // most likely have maintenance due soonest?
      const nextDueA = a.maintenance.interval
      const nextDueB = b.maintenance.interval
      if (nextDueB > nextDueA) return -1
      else if (nextDueA > nextDueB) return 1
      else return 0
    })
    .slice(0, 5)
    .map((v) => v.vehicle)

  if (sorted.length === 0 && vehicles.length > 0)
    sorted = vehicles.filter((v) => v.fleetcor_enrolled === true).slice(0, 5)

  return (
    <React.Fragment>
      <span />
      <Header size="small" className={"dash-hd"}>
        {t("common:vehiclesLabel")}
      </Header>
      <Segment>
        <Divider hidden />
        {sorted.length > 0 ? (
          <React.Fragment>
            <Grid style={{ flexDirection: "column" }} divided="vertically">
              {sorted.map((v) => (
                <Grid.Row className="slim-row">
                  <Grid.Column width={4} verticalAlign="middle">
                    <Link to={navigationPaths.vehicleShow(v.id)}>
                      <Image src={v.dashboard_mobile_url || "images/no_car.svg"} />
                    </Link>
                  </Grid.Column>
                  <Grid.Column width={9}>
                    <DueStatusLabel
                      vehicle={v}
                      serviceSchedulesByVehicle={maintenanceIntervals.service_schedules_by_vehicle}
                    />
                    <p>{vehicleHelpers.formattedName(v)}</p>
                    <p>
                      {isFleetcor() && v.license_plate_number && v.license_plate_state ? (
                        <>
                          {" "}
                          {t("plateLabel")}: {vehicleHelpers.formattedLicensePlateNumber(v)}{" "}
                        </>
                      ) : (
                        <></>
                      )}
                    </p>
                    <p>
                      {!isFleetcor() ? (
                        <>
                          {" "}
                          {t("otherIdLabel")}: {v.other_id || "-"}{" "}
                        </>
                      ) : (
                        <>
                          {" "}
                          {t("vehicleNameLabel")}: {v.name_of_vehicle || "-"}{" "}
                        </>
                      )}
                    </p>
                  </Grid.Column>
                </Grid.Row>
              ))}
            </Grid>
            <Divider hidden />
            <Link to={navigationPaths.vehicleIndex()}>
              <Container textAlign="center">
                <span className="link">{t("viewAllVehiclesLabel")}</span>
              </Container>
            </Link>
          </React.Fragment>
        ) : (
          <Container textAlign="center">
            <Header>
              {isFleetcor()
                ? vehicles.length == 0
                  ? t("noVehiclesAddedLabel")
                  : "Enrolled Vehicles Not Present"
                : t("noVehiclesAddedLabel")}
            </Header>
            <Divider hidden />
            {!isFleetcor() ? (
              <VehicleModal>
                <Button>{t("addAVehicleLabel")}</Button>
              </VehicleModal>
            ) : (
              ""
            )}
            <Divider hidden />
          </Container>
        )}
      </Segment>
    </React.Fragment>
  )
}
