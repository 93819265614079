import React, { Component } from "react"
import PropTypes from "prop-types"
import { Link } from "react-router-dom"
import { getStyles } from "./StylesheetInjector"
import ReactSVG from "react-svg"
const styles = getStyles()

class ButtonLink extends Component {
  static propTypes = {
    content: PropTypes.node.isRequired,
    linkPath: PropTypes.string.isRequired
  }

  render() {
    const { linkPath, content, icon, onClickFunction } = this.props
    return (
      <Link
        to={linkPath}
        style={{ color: styles.primaryColor }}
        onClick={(e) => onClickFunction && onClickFunction(e)}
        className="shared-button-link"
      >
        {icon && (
          <div className="shared-button-link_icon">
            <ReactSVG src={icon} />
          </div>
        )}
        {content && (
          <div className="shared-button-link_content">
            <span>{content}</span>
          </div>
        )}
      </Link>
    )
  }
} // class ButtonLink

export default ButtonLink
