import * as arrayHelpers from "../../helpers/arrayHelpers"
import { preparedMaintenanceHistories } from "../../helpers/maintenanceHistoryHelpers"
import _ from "lodash"
// NOTE: Use of vehicle(s)DataType prevents components that require extended data from trying to render stale "standard" data from the store and crashing.
// NOTE: vehicle(s)DataType:
// 'standard' => Loaded without "all" option (vehicles index)
// 'extended' => Loaded with "all" option (dashboard)

export const initialState = {
  vehicle: {},
  vehicles: [],
  vehicles1: [],
  vehicles2: [],
  basicVehicles: [],
  vehicleDataType: "",
  vehiclesDataType: "",
  geotabBatteryLifeInfo: {},
  geotabEngineOilLifeInfo: {},
  geotabTirePressureInfo: {},
  geotabFaultCodesInfo: {}
}

export default function (state = initialState, action) {
  const { payload, type } = action

  switch (type) {
    case "SET_VEHICLE_DATA_TYPE":
      return {
        ...state,
        vehicleDataType: payload.dataType
      }
    case "SET_VEHICLES_DATA_TYPE":
      return {
        ...state,
        vehiclesDataType: payload.dataType
      }
    case "VEHICLE_CREATE_SUCCEEDED":
      return {
        ...state,
        vehicles: arrayHelpers.append(state.vehicles, payload.vehicle)
      }
    case "VEHICLE_DELETE_SUCCEEDED":
      return {
        ...state,
        vehicles: arrayHelpers.removeObjectById(state.vehicles, payload.vehicleId)
      }
    case "VEHICLE_LOAD_SUCCEEDED":
      return {
        ...state,
        vehicle: {
          ...state.vehicle,
          ...payload.vehicle
        }
      }
    case "VEHICLE_UPDATE_SUCCEEDED":
      return {
        ...state,
        vehicles: arrayHelpers.replaceObjectById(
          state.vehicles,
          payload.vehicle,
          payload.vehicle.id
        )
      }
    case "VEHICLE_SERVICES_SUCCEEDED":
      const vehicleServices = state.vehicleServices || {}
      return {
        ...state,
        vehicleServices: { ...vehicleServices, ...payload.vehicleServices }
      }
    case "UNLOAD_VEHICLE_SERVICES":
      return {
        ...state,
        vehicleServices: {}
      }
    case "VEHICLES_LOAD_SUCCEEDED":
      let section = parseInt(payload.section)
      if (!section) {
        return {
          ...state,
          vehicles: payload.vehicles
        }
      } else if (section == 1) {
        return {
          ...state,
          vehicles1: payload.vehicles
        }
      } else if (section == 2) {
        return {
          ...state,
          vehicles2: payload.vehicles
        }
      }
    case "VEHICLES_BASIC_LOAD_SUCCEEDED":
      return {
        ...state,
        basicVehicles: payload.basicVehicles
      }
    case "VEHICLES_UNLOAD_DATA":
      return {
        ...state,
        vehicles: []
      }
    case "VEHICLE_MAINTENANCE_HISTORIES_LOAD_COMPLETE":
      return {
        ...state,
        isVehicleMHComplete: true
      }
    case "RESET_VEHICLE_MAINTENANCE_HISTORIES":
      return {
        ...state,
        isVehicleMHComplete: false,
        vehicle: {}
      }
    case "VEHICLE_MAINTENANCE_HISTORIES_LOAD_SUCCEEDED":
      const result = {}
      if (payload.maintenanceHistories) {
        const modifiedMaintenanceHistories = preparedMaintenanceHistories(
          payload.maintenanceHistories
        )
        result.manualMaintenanceHistories = state.vehicle.manualMaintenanceHistories
          ? [...state.vehicle.manualMaintenanceHistories, ...modifiedMaintenanceHistories]
          : modifiedMaintenanceHistories
      }
      if (payload.maintenance_history) {
        result.maintenance_histories = state.vehicle.maintenance_histories
          ? [...state.vehicle.maintenance_histories, ...payload.maintenance_history]
          : payload.maintenance_history
      }
      return {
        ...state,
        vehicle: {
          ...state.vehicle,
          ...result,
          manualHistoriesTotalPages: payload.manualHistoriesTotalPages,
          maintenanceHistoryTotalPage: payload.maintenanceHistoryTotalPage
        }
      }
    case "VEHICLE_MAINTENANCE_HISTORY_DELETE_SUCCEEDED":
      return {
        ...state,
        vehicle: {
          ...state.vehicle,
          manualMaintenanceHistories: arrayHelpers.removeObjectById(
            state.vehicle.manualMaintenanceHistories,
            payload.manualMaintenanceHistory.id
          )
        }
      }
    case "YEARS_LOAD_SUCCEEDED":
      return {
        ...state,
        years: payload.years
      }
    case "MAKES_LOAD_SUCCEEDED":
      return {
        ...state,
        makes: payload.makes
      }
    case "MODELS_LOAD_SUCCEEDED":
      return {
        ...state,
        models: payload.models
      }
    case "ENGINES_LOAD_SUCCEEDED":
      return {
        ...state,
        engines: payload.engines
      }
    case "VEHICLE_GLOVEBOX_UPDATE_SUCCEEDED":
      return {
        ...state,
        gloveBoxDocuments: {
          ...state.gloveBoxDocuments,
          gloveBox: payload.glovebox
        }
      }
    case "SAVE_OR_UPDATE_UPLOADED_DOCUMENT_SUCCEEDED":
      return {
        ...state,
        gloveBoxDocuments: {
          ...state.gloveBoxDocuments,
          uploadMessage: payload.alertMessage,
          uploadMessageStatus: payload.alertType
        }
      }
    case "CLEAR_DOCUMENT_UPLOAD_MESSAGE":
      return {
        ...state,
        gloveBoxDocuments: {
          ...state.gloveBoxDocuments,
          uploadMessage: "",
          uploadMessageStatus: ""
        }
      }
    case "DELETE_UPLOADED_DOCUMENT_SUCCEEDED":
      return {
        ...state,
        gloveBoxDocuments: {
          ...state.gloveBoxDocuments,
          uploadMessage: payload.alertMessage,
          uploadMessageStatus: payload.alertType
        }
      }
    case "VDELETE_UPLOADED_DOCUMENT_FAILED":
      return {
        ...state,
        gloveBoxDocuments: {
          ...state.gloveBoxDocuments,
          uploadMessage: payload.alertMessage,
          uploadMessageStatus: payload.alertType
        }
      }
    case "SAVE_OR_UPDATE_UPLOADED_DOCUMENT_FAILED":
      return {
        ...state,
        gloveBoxDocuments: {
          ...state.gloveBoxDocuments,
          uploadMessage: payload.alertMessage,
          uploadMessageStatus: payload.alertType
        }
      }
    case "VEHICLE_GEOTAB_ENGINE_OIL_INFO_REQUEST_SUCCEEDED":
      return {
        ...state,
        geotabEngineOilLifeInfo: {
          engineOilLifeData: payload.engineOilLifeInfo
        }
      }
    case "VEHICLE_GEOTAB_BATTERY_LIFE_INFO_REQUEST_SUCCEEDED":
      return {
        ...state,
        geotabBatteryLifeInfo: {
          batteryLifeData: payload.batteryLifeInfo
        }
      }
    case "VEHICLE_GEOTAB_TIRE_PRESSURE_INFO_REQUEST_SUCCEEDED":
      return {
        ...state,
        geotabTirePressureInfo: {
          tirePressureData: payload.tirePressureInfo
        }
      }
    case "VEHICLE_GEOTAB_FAULT_CODES_INFO_REQUEST_SUCCEEDED":
      return {
        ...state,
        geotabFaultCodesInfo: {
          faultCodesData: payload.faultCodesInfo
        }
      }
    case "PULL_OEM_MAINTENANCE_SCHEDULES_SUCCEEDED":
      const updatedVehicle = state.vehicle
      const getUniqoOemServices = _.map(payload.oemServices, (service) => ({
        ...service,
        services: _.uniqBy(service.services, "service_id")
      }))
      updatedVehicle.oemServices = getUniqoOemServices
      return {
        ...state,
        vehicle: {
          ...state.vehicle,
          ...updatedVehicle
        }
      }

    default:
      return state
  }
}
