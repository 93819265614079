import React, { Component } from "react"
import PropTypes from "prop-types"
import { Table, Select, Checkbox as SematicCheckbox, Image } from "semantic-ui-react"
import * as userHelpers from "../../helpers/userHelpers"
import { withTranslation } from "react-i18next"
import { FMC, FMC_FLEET_ADMIN, OWNER, MANAGER, FMC_FLEET_MANAGER } from "../../constants/roles"
import ReactSVG from "react-svg"

class PaymentMethod extends Component {
  static propTypes = {
    onUpdate: PropTypes.func.isRequired,
    onDelete: PropTypes.func.isRequired,
    paymentMethod: PropTypes.object.isRequired,
    setEditingId: PropTypes.func.isRequired,
    usersOnPaymentMethod: PropTypes.array.isRequired,
    allUsers: PropTypes.array.isRequired,
    isEditing: PropTypes.bool.isRequired
  }

  state = {
    formData: {
      customer_ids: this.props.usersOnPaymentMethod.map((u) => u.id)
    }
  }

  onChange(field, value) {
    let updatedFormData = { ...this.state.formData }
    updatedFormData[field] = value
    this.setState({ formData: updatedFormData })
  }

  renderUsers() {
    const {
      usersOnPaymentMethod,
      setEditingId,
      onDelete,
      onSetDefault,
      paymentMethod,
      currentUserId,
      t,
      currentUser
    } = this.props
    const { id, owner_id, has_active_orders, is_default } = paymentMethod

    return (
      <React.Fragment>
        <Table.Cell>{usersOnPaymentMethod.map((u) => u.name).join(", ")}</Table.Cell>
        {!currentUser.roles.includes(MANAGER) && !currentUser.roles.includes(FMC_FLEET_MANAGER) && (
          <Table.Cell textAlign="center">
            <div role="is_default" onClick={!is_default ? () => onSetDefault(id) : ""}>
              <SematicCheckbox toggle checked={is_default} disabled={is_default} />
            </div>
          </Table.Cell>
        )}
        <Table.Cell textAlign="center">
          {(currentUser.roles.includes(FMC) ||
            currentUser.roles.includes(FMC_FLEET_ADMIN) ||
            currentUser.roles.includes(OWNER) ||
            owner_id === currentUserId) && (
            <span style={{ display: "flex", justifyContent: "center" }}>
              <span
                className="link"
                onClick={() => setEditingId(id)}
                style={{ paddingRight: "5px" }}
              >
                <ReactSVG src={`images/edit_circle.svg`} />
              </span>
              <span className="link" onClick={() => onDelete(id, has_active_orders, is_default)}>
                <ReactSVG src={`images/delete_circle.svg`} />
              </span>
            </span>
          )}
        </Table.Cell>
      </React.Fragment>
    )
  }

  renderEditUsers() {
    const { isEditing, allUsers, onUpdate, setEditingId, paymentMethod, t } = this.props
    const { id } = paymentMethod
    const { formData } = this.state

    return (
      <React.Fragment>
        <Table.Cell>
          <span style={{ display: "flex", alignItems: "center" }}>
            <Select
              onChange={(_, data) => this.onChange("customer_ids", data.value)}
              options={userHelpers.selectOptions(allUsers, { semantic: true })}
              placeholder={"Drivers"}
              value={formData.customer_ids}
              multiple
            />
            <span
              className="link"
              onClick={() => onUpdate(paymentMethod.id, formData)}
              style={{ padding: "0 10px 0 15px" }}
            >
              <ReactSVG src={`images/check_circle.svg`} />
            </span>
            <span className="link" onClick={() => setEditingId(undefined)}>
              <ReactSVG src={`images/cancel_circle.svg`} />
            </span>
          </span>
        </Table.Cell>
        <Table.Cell></Table.Cell>
      </React.Fragment>
    )
  }

  render() {
    const { paymentMethod, isEditing } = this.props
    const {
      card_type,
      expiration_date,
      last_4,
      email,
      type,
      customer_name,
      customer_ids,
      cardholder_name
    } = paymentMethod

    const logo = type === "credit_card" ? card_type : "pay_pal_account"
    return (
      <Table.Row>
        <Table.Cell>
          <ReactSVG src={`images/${logo.replace(/\s+/g, "")}.svg`} />
        </Table.Cell>
        <Table.Cell singleLine>
          {type === "credit_card" ? `${card_type} *-${last_4}` : `PayPal ${email}`}
        </Table.Cell>
        <Table.Cell>{cardholder_name != null ? cardholder_name : "-"}</Table.Cell>
        <Table.Cell>{customer_name}</Table.Cell>
        <Table.Cell>{expiration_date}</Table.Cell>
        {isEditing ? this.renderEditUsers() : this.renderUsers()}
      </Table.Row>
    )
  }
} // PaymentMethod

export default withTranslation("common")(PaymentMethod)
