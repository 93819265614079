import { call, put, takeEvery } from "redux-saga/effects"

import api from "../../utilities/api"
import * as apiErrorHelpers from "../../helpers/apiErrorHelpers"
import * as sessionHelpers from "../../helpers/sessionHelpers"

export function* create(action) {
  try {
    yield put({
      type: "MAINTENANCE_POLICY_CREATE_REQUESTED"
    })

    api.setAuthApi({
      Authorization: sessionHelpers.getApplicationCookie("authentication_token")
    })

    const request = {
      url: "/policies",
      body: {
        ...action.payload.formData
      }
    }

    const response = yield call(api.utility.post, api.path(request.url), { body: request.body })

    apiErrorHelpers.catchErrors(response.body)

    yield put({
      type: "MAINTENANCE_POLICY_CREATE_SUCCEEDED"
    })

    const afterRequestData = {
      alertMessage: action.payload.t("policyCreatedLabel"),
      alertType: "success"
    }

    yield call(action.callback, "success", afterRequestData)
  } catch (errors) {
    // eslint-disable-next-line
    errors = apiErrorHelpers.formatInternalErrors(errors)

    yield put({
      type: "MAINTENANCE_POLICY_CREATE_FAILED"
    })

    const afterRequestData = {
      alertMessage: apiErrorHelpers.toString(errors),
      alertType: "error"
    }

    yield call(action.callback, "failure", afterRequestData)

    apiErrorHelpers.consoleLog(errors)
  }
} // create()

export function* destroy(action) {
  try {
    yield put({
      type: "MAINTENANCE_POLICY_DELETE_REQUESTED"
    })

    api.setAuthApi({
      Authorization: sessionHelpers.getApplicationCookie("authentication_token")
    })

    const policyId = action.payload.policyId

    const request = {
      url: `/policies/${policyId}`,
      body: {}
    }

    const response = yield call(api.utility.del, api.path(request.url), { body: request.body })

    apiErrorHelpers.catchErrors(response.body)

    yield put({
      type: "MAINTENANCE_POLICY_DELETE_SUCCEEDED"
    })

    const afterRequestData = {
      alertMessage: action.payload.t("policyDeletedLabel"),
      alertType: "success"
    }

    yield call(action.callback, "success", afterRequestData)
  } catch (errors) {
    // eslint-disable-next-line
    errors = apiErrorHelpers.formatInternalErrors(errors)

    yield put({
      type: "MAINTENANCE_POLICY_DELETE_FAILED"
    })

    const afterRequestData = {
      alertMessage: apiErrorHelpers.toString(errors),
      alertType: "error"
    }

    yield call(action.callback, "failure", afterRequestData)

    apiErrorHelpers.consoleLog(errors)
  }
} // destroy()

export function* loadPolicies(action) {
  try {
    yield put({ type: "SET_LOADING" })
    yield put({ type: "UNSET_LOADING_ERROR" })

    api.setAuthApi({
      Authorization: sessionHelpers.getApplicationCookie("authentication_token")
    })

    ////////////////////////////
    //   LOAD POLICIES DATA   //
    ////////////////////////////

    yield put({ type: "MAINTENANCE_POLICIES_LOAD_REQUESTED" })

    const maintenancePoliciesRequest = {
      url: "/policies",
      body: {}
    }

    const maintenancePoliciesResponse = yield call(
      api.utility.get,
      api.path(maintenancePoliciesRequest.url),
      { body: maintenancePoliciesRequest.body }
    )

    apiErrorHelpers.catchErrors(maintenancePoliciesResponse.body)

    yield put({
      type: "MAINTENANCE_POLICIES_LOAD_SUCCEEDED",
      payload: {
        policies: maintenancePoliciesResponse.body
      }
    })

    ////////////////////////////

    ////////////////////////////
    //   LOAD SERVICES DATA   //
    ////////////////////////////
    yield put({ type: "SERVICES_LOAD_REQUESTED" })

    const servicesRequest = {
      url: "/services",
      body: { basic: true }
    }

    const servicesResponse = yield call(api.utility.get, api.path(servicesRequest.url), {
      body: servicesRequest.body
    })

    apiErrorHelpers.catchErrors(servicesResponse.body)

    yield put({
      type: "SERVICES_LOAD_SUCCEEDED",
      payload: {
        services: servicesResponse.body
      }
    })
    ////////////////////////////

    ////////////////////////////
    //   LOAD VEHICLES DATA   //
    ////////////////////////////

    yield put({ type: "VEHICLES_LOAD_REQUESTED" })

    const vehiclesRequest = {
      url: "/vehicles",
      body: {}
    }

    const vehiclesResponse = yield call(api.utility.get, api.path(vehiclesRequest.url), {
      body: vehiclesRequest.body
    })

    apiErrorHelpers.catchErrors(vehiclesResponse.body)

    yield put({
      type: "VEHICLES_LOAD_SUCCEEDED",
      payload: {
        vehicles: vehiclesResponse.body
      }
    })

    yield put({
      type: "SET_VEHICLES_DATA_TYPE",
      payload: { dataType: "standard" }
    })

    //yield put({ type: "USER_VEHICLES_GLOVEBOX_SAGA"})

    ////////////////////////////

    ////////////////////////////
    //   LOAD USERS DATA   //
    ////////////////////////////

    // yield put({ type: "USERS_LOAD_SAGA" })

    ////////////////////////////

    /////////////////////////
    //   LOAD FLEET DATA   //
    /////////////////////////

    // yield put({ type: "FLEET_LOAD_SAGA" })

    /////////////////////////

    yield put({ type: "UNSET_LOADING" })
  } catch (errors) {
    // eslint-disable-next-line
    errors = apiErrorHelpers.formatInternalErrors(errors)

    yield put({ type: "LOADING_FAILED" })
    yield put({ type: "SET_LOADING_ERROR" })
    yield put({ type: "UNSET_LOADING" })

    apiErrorHelpers.consoleLog(errors)
  }
}

export function* update(action) {
  try {
    yield put({
      type: "MAINTENANCE_POLICY_UPDATE_REQUESTED"
    })

    api.setAuthApi({
      Authorization: sessionHelpers.getApplicationCookie("authentication_token")
    })

    const policyId = action.payload.formData.id

    const request = {
      url: `/policies/${policyId}`,
      body: {
        ...action.payload.formData
      }
    }

    const response = yield call(api.utility.put, api.path(request.url), { body: request.body })

    apiErrorHelpers.catchErrors(response.body)

    yield put({
      type: "MAINTENANCE_POLICY_UPDATE_SUCCEEDED"
    })

    const afterRequestData = {
      alertMessage: action.payload.t("common:policyUpdatedLabel"),
      alertType: "success"
    }

    yield call(action.callback, "success", afterRequestData)
  } catch (errors) {
    // eslint-disable-next-line
    errors = apiErrorHelpers.formatInternalErrors(errors)

    yield put({
      type: "MAINTENANCE_POLICY_UPDATE_FAILED"
    })

    const afterRequestData = {
      alertMessage: apiErrorHelpers.toString(errors),
      alertType: "error"
    }

    yield call(action.callback, "failure", afterRequestData)

    apiErrorHelpers.consoleLog(errors)
  }
} // update()

export default function* maintenancePolicySaga() {
  yield takeEvery("MAINTENANCE_POLICIES_LOAD_SAGA", loadPolicies)
  yield takeEvery("MAINTENANCE_POLICY_CREATE_SAGA", create)
  yield takeEvery("MAINTENANCE_POLICY_DELETE_SAGA", destroy)
  yield takeEvery("MAINTENANCE_POLICY_UPDATE_SAGA", update)
}
