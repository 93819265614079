import React, { useEffect, useState } from "react"
import ReactSVG from "react-svg"
import EngineSVG from "../../assets/images/engine.svg"
import { Grid, Segment } from "semantic-ui-react"
import moment from "moment"
import { useSwipeable } from "react-swipeable"
import { Date_Format } from "../../constants/years"

export const CarouselItem = ({ children, width }) => {
  const { id, dateTime, diagnostic } = children
  const { name } = diagnostic
  return (
    <div className="carousel-items" style={{ width: width }}>
      <div className="carousel-item">
        <div className="carousel-header">
          <div className="item-content-code">
            <span style={{ width: 32 }}>
              {" "}
              <ReactSVG src={EngineSVG} />{" "}
            </span>
            <span>{id.toUpperCase()}</span>
          </div>
          <div className="item-content-interval">
            <span style={{ fontSize: "14px" }}>
              {moment(dateTime).format(Date_Format.MM_DD_YY)}{" "}
            </span>
          </div>
        </div>
        <div className="item-content">
          <span title={name}>{name}</span>
        </div>
      </div>
    </div>
  )
}

const Carousel = ({ children }) => {
  const [activeIndex, setActiveIndex] = useState(0)
  const [paused, setPaused] = useState(false)

  const updateIndex = (newIndex) => {
    if (newIndex < 0) {
      newIndex = React.Children.count(children) - 1
    } else if (newIndex >= React.Children.count(children)) {
      newIndex = 0
    }

    setActiveIndex(newIndex)
  }

  useEffect(() => {
    const interval = setInterval(() => {
      if (!paused) {
        updateIndex(activeIndex + 1)
      }
    }, 3000)

    return () => {
      if (interval) {
        clearInterval(interval)
      }
    }
  })

  const handlers = useSwipeable({
    onSwipedLeft: () => updateIndex(activeIndex + 1),
    onSwipedRight: () => updateIndex(activeIndex - 1)
  })

  return (
    <div
      {...handlers}
      className="carousel-container"
      onMouseEnter={() => setPaused(true)}
      onMouseLeave={() => setPaused(false)}
    >
      <div className="inner" style={{ transform: `translateX(-${activeIndex * 100}%)` }}>
        {React.Children.map(children, (child, index) => {
          return React.cloneElement(child, { width: "100%" })
        })}
      </div>
      <div className="indicators">
        {/* <button
                onClick={() => {
                    updateIndex(activeIndex - 1);
                }}
            >
                Prev
            </button> */}
        {React.Children.map(children, (child, index) => {
          return (
            <div
              className={`${index === activeIndex ? "active" : ""}`}
              onClick={() => {
                updateIndex(index)
              }}
            >
              {/* {index + 1} */}
            </div>
          )
        })}
        {/* <button
                onClick={() => {
                    updateIndex(activeIndex + 1);
                }}
            >
                Next
            </button> */}
      </div>
    </div>
  )
}

export default Carousel
