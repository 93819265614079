import React, { Component } from "react"
import { connect } from "react-redux"
import PropTypes, { bool } from "prop-types"
import {
  EMAIL_REGEX,
  ZIP_CODE_REGEX,
  POSTAL_CODE_REGEX,
  PHONE_NUMBER_REGEX
} from "../../utilities/stringHelpers"
import TextField from "../../elements/TextField"
import PasswordField from "../../elements/PasswordField"
import Button from "../../elements/Button"
import Checkbox from "../../elements/Checkbox"
import Alert from "../../components/shared/Alert"
import ErrorField from "../../elements/ErrorField"
import { Link } from "react-router-dom"
import "../../assets/stylesheets/components/sessions/_sign_up_form.scss"
import { getSubdomain } from "../shared/StylesheetInjector"
import { withTranslation } from "react-i18next"
import {
  isFleetMaintenanceHubCanada,
  isFleetMaintenanceHub
} from "../../helpers/affiliationHelpers"
import { FLEET_MAINTENANCE_HUB, FLEET_MAINTENANCE_HUB_CANADA } from "../../constants/application"
import ReactSVG from "react-svg"
import { Dropdown } from "semantic-ui-react"
import Pdf from "../../Documents/Plantype.pdf"
import StepperComponent from "../shared/Stepper"
import PaymentMethodScreen from "../shared/PaymentMethodScreen"
import { setupSplitFlags, ON } from "../config/SplitClient"
import PrivacyPolicyPage from "../../views/sessions/PrivacyPolicyPage"
let sub = getSubdomain()

class SignUpForm extends Component {
  static propTypes = {
    onSubmit: PropTypes.func.isRequired,
    isSubmitting: PropTypes.bool
  }

  static defaultProps = {
    isSubmitting: false
  }
  constructor(props) {
    super(props)
    this.paymentMethodScreen = React.createRef()
    this.state = {
      formData: {
        firstName: {
          value: ""
        },
        lastName: {
          value: ""
        },
        email: {
          value: ""
        },
        password: {
          value: "",
          passwordInvisible: true
        },
        confirmPassword: {
          value: "",
          passwordInvisible: true
        },
        phoneNumber: {
          value: ""
        },
        companyName: {
          value: ""
        },
        carCount: {
          value: ""
        },
        zipCode: {
          value: ""
        },
        acceptedTermsAndConditions: {
          value: "true"
        }
      },
      displayError: {
        //to display appropriate errors for the different fields
        confirmPassword: false,
        password: false,
        minLengthPassword: false,
        carCount: false,
        email: false,
        firstName: false,
        lastName: false,
        zipCode: false,
        companyName: false,
        phoneNumber: false
      }, //displayError
      checkboxChecked: false,
      communicationPreferencesInFrench: false,
      promotionalCheckboxChecked: false,
      premiumCheckboxChecked: false,
      formError: false,
      typingTimer: null,
      modalOpen: false
    } //state
  } //constructor

  async componentDidMount(nextProps, nextState) {
    await setupSplitFlags.bind(this)({ sub_domain: sub })
    if (this.state.isFuelmanMastercardFlag && this.state.isFuelmanMastercardFlag === ON)
      this.props.dispatch({ type: "BRAINTREE_CLIENT_TOKEN_LOAD_SAGA" })
  }

  onFirstName = (event) => {
    this.onFieldChange("firstName", event.target.value)
  }
  onLastName = (event) => {
    this.onFieldChange("lastName", event.target.value)
  }
  onEmail = (event) => {
    this.onFieldChange("email", event.target.value)
  }
  onPassword = (event) => {
    this.onFieldChange("password", event.target.value)
  }
  onConfirmPassword = (event) => {
    this.onFieldChange("confirmPassword", event.target.value)
  }
  onPhoneNumber = (event) => {
    this.onFieldChange("phoneNumber", event.target.value)
  }
  onCompanyName = (event) => {
    this.onFieldChange("companyName", event.target.value)
  }
  onCarCount = (event) => {
    this.onFieldChange("carCount", event.target.value)
  }
  onZipCode = (event) => {
    this.onFieldChange("zipCode", event.target.value)
  }
  onTermsCheckbox = (event) => {
    this.onFieldChange("acceptedTermsAndConditions", event.target.value)
    this.setState({ checkboxChecked: !this.state.checkboxChecked })
  }
  onPreferencesCheckbox = (event) => {
    this.setState({
      communicationPreferencesInFrench: !this.state.communicationPreferencesInFrench
    })
  }
  onPromotionalCheckbox = (event) => {
    this.setState({ promotionalCheckboxChecked: !this.state.promotionalCheckboxChecked })
  }
  onPreimumCheckbox = (event) => {
    this.setState({ premiumCheckboxChecked: !this.state.premiumCheckboxChecked })
  }
  onFieldChange = (field, value) => {
    let updatedFormData = { ...this.state.formData }
    let updatedFormField = { ...updatedFormData[field] }
    updatedFormField.value = value
    updatedFormData[field] = { ...updatedFormField }
    this.setState({ formData: updatedFormData })
    clearTimeout(this.state.typingTimer)
    this.state.typingTimer = setTimeout(() => {
      if (value) {
        this.isFieldValueValid(field, value)
      }
    }, 500)
  }

  isFieldValueValid = (field, value) => {
    let formError = this.state.formError
    switch (field) {
      case "firstName":
        if (this.state.formData.firstName.value === "") {
          this.setDisplayError("firstName", true)
          formError = true
        } else {
          this.setDisplayError("firstName", false)
        }
        break
      case "lastName":
        if (this.state.formData.lastName.value === "") {
          this.setDisplayError("lastName", true)
          formError = true
        } else {
          this.setDisplayError("lastName", false)
        }
        break
      case "email":
        if (this.state.formData.email.value === "" || !this.validateEmail()) {
          this.setDisplayError("email", true)
          formError = true
        } else {
          this.setDisplayError("email", false)
        }
        break
      case "password":
        if (this.state.formData.password.value === "" || !this.passwordMinLength()) {
          if (!this.passwordMinLength()) {
            //if user enters password less than length 6.
            this.setDisplayError("minLengthPassword", true)
            formError = true
          } else {
            this.setDisplayError("password", true)
            formError = true
          }
        } else if (
          this.state.formData.password.value != "" &&
          this.state.formData.confirmPassword.value != "" &&
          !this.validatePassword()
        ) {
          this.setDisplayError("confirmPassword", true)
          formError = true
        } else {
          this.setDisplayError("minLengthPassword", false)
          this.setDisplayError("password", false)
        }
        break
      case "confirmPassword":
        if (this.state.formData.confirmPassword.value === "" || !this.validatePassword()) {
          this.setDisplayError("confirmPassword", true)
          formError = true
        } else {
          this.setDisplayError("confirmPassword", false)
        }
        break
      case "phoneNumber":
        if (this.state.formData.phoneNumber.value === "" || !this.validatePhoneNumber()) {
          this.setDisplayError("phoneNumber", true)
          formError = true
        } else {
          this.setDisplayError("phoneNumber", false)
        }
        break
      case "companyName":
        if (this.state.formData.companyName.value === "") {
          this.setDisplayError("companyName", true)
          formError = true
        } else {
          this.setDisplayError("companyName", false)
        }
        break
      case "carCount":
        if (this.state.formData.carCount.value === "" || !this.validateCarCount()) {
          this.setDisplayError("carCount", true)
          formError = true
        } else {
          this.setDisplayError("carCount", false)
        }
        break
      case "zipCode":
        if (this.state.formData.zipCode.value === "" || !this.validateZipCode()) {
          this.setDisplayError("zipCode", true)
          formError = true
        } else {
          this.setDisplayError("zipCode", false)
        }
        break
    }
  }
  componentWillUnmount() {
    clearTimeout(this.state.typingTimer)
  }

  onEyeIconClick = (field) => {
    let updatedFormData = { ...this.state.formData }
    let updatedFormField = { ...updatedFormData[field] }
    updatedFormField.passwordInvisible = !updatedFormField.passwordInvisible
    updatedFormData[field] = { ...updatedFormField }
    this.setState({ formData: updatedFormData })
  }

  onSignupButtonClick = (payment_method_nonce = null, preferencesInFrench = false) => {
    const subDomain = getSubdomain()
    const { communicationPreferencesInFrench } = this.state

    let communicationPreferences
    if (this.state.isFuelmanMastercardFlag && this.state.isFuelmanMastercardFlag === ON)
      communicationPreferences = preferencesInFrench
    else communicationPreferences = communicationPreferencesInFrench

    this.submitTheData(
      this.state.formData,
      subDomain,
      payment_method_nonce,
      communicationPreferences
    )
  }

  submitTheData = (formData, subDomain, payment_method_nonce, communicationPreferences) => {
    if (this.isFormValid()) {
      let data = {
        firstName: formData.firstName.value,
        lastName: formData.lastName.value,
        email: formData.email.value.toLowerCase(),
        password: formData.password.value,
        confirmPassword: formData.confirmPassword.value,
        phoneNumber: formData.phoneNumber.value,
        companyName: formData.companyName.value,
        carCount: formData.carCount.value,
        zipCode: formData.zipCode.value,
        acceptedTermsAndConditions: formData.acceptedTermsAndConditions.value,
        subdomain: subDomain,
        payment_method_nonce: payment_method_nonce,
        communicationPreferencesInFrench: communicationPreferences
      }

      this.props.onSubmit(data)
    }
  }

  isAllDataEntered = () => {
    for (let field in this.state.formData) {
      if (this.state.formData[field].value === "") return false
    }
    return true
  }

  isFormValid = () => {
    const { t } = this.props
    let formError = this.state.formError
    if (this.state.formData.firstName.value === "") {
      this.setDisplayError("firstName", true)
      formError = true
    } else {
      this.setDisplayError("firstName", false)
    }
    if (this.state.formData.email.value === "" || !this.validateEmail()) {
      this.setDisplayError("email", true)
      formError = true
    } else {
      this.setDisplayError("email", false)
    }
    if (this.state.formData.companyName.value === "") {
      this.setDisplayError("companyName", true)
      formError = true
    } else {
      this.setDisplayError("companyName", false)
    }
    if (this.state.formData.lastName.value === "") {
      this.setDisplayError("lastName", true)
      formError = true
    } else {
      this.setDisplayError("lastName", false)
    }
    if (this.state.formData.carCount.value === "" || !this.validateCarCount()) {
      this.setDisplayError("carCount", true)
      formError = true
    } else {
      this.setDisplayError("carCount", false)
    }
    if (this.state.formData.password.value === "" || !this.passwordMinLength()) {
      if (!this.passwordMinLength()) {
        //if user enters password less than length 6.
        this.setDisplayError("minLengthPassword", true)
        formError = true
      } else {
        this.setDisplayError("password", true)
        formError = true
      }
    } else {
      this.setDisplayError("minLengthPassword", false)
      this.setDisplayError("password", false)
    }
    if (this.state.formData.phoneNumber.value === "" || !this.validatePhoneNumber()) {
      this.setDisplayError("phoneNumber", true)
      formError = true
    } else {
      this.setDisplayError("phoneNumber", false)
    }
    if (this.state.formData.confirmPassword.value === "" || !this.validatePassword()) {
      this.setDisplayError("confirmPassword", true)
      this.makeConfirmPasswordEmpty()
      formError = true
    } else {
      this.setDisplayError("confirmPassword", false)
    }
    if (this.state.formData.zipCode.value === "" || !this.validateZipCode()) {
      this.setDisplayError("zipCode", true)
      formError = true
    } else {
      this.setDisplayError("zipCode", false)
    }
    if (this.state.isFuelmanMastercardFlag && this.state.isFuelmanMastercardFlag != ON) {
      if (formError == false && !this.state.checkboxChecked) {
        alert(t("acceptTermsLabel"))
        formError = true
      }
      if (
        isFleetMaintenanceHubCanada() &&
        formError == false &&
        !this.state.promotionalCheckboxChecked
      ) {
        alert(t("acceptPromotionalTermsLabel"))
        formError = true
      }
    }

    return !formError
  } //isFormValid

  setDisplayError = (field, booleanValue) => {
    this.setState((state) => ({
      displayError: this.getUpdatedDisplayError(state, field, booleanValue)
    }))
  } //setDisplayError

  getUpdatedDisplayError = (state, field, booleanValue) => {
    let updatedDisplayerror = { ...state.displayError }
    updatedDisplayerror[field] = booleanValue === true ? true : false
    return updatedDisplayerror
  }

  validatePassword = () => {
    const password = this.state.formData.password.value
    const confirmPassword = this.state.formData.confirmPassword.value
    return password === confirmPassword
  }
  passwordMinLength = () => {
    const password = this.state.formData.password.value
    return password.length >= 6
  }

  validateCarCount = () => {
    const carCount = this.state.formData.carCount.value
    return carCount % 1 === 0 && carCount >= 0
  }

  validateTermsAndConditions = () => {
    return this.state.formData.acceptedTermsAndConditions.value
  }

  validateEmail = () => {
    const email = this.state.formData.email.value
    return EMAIL_REGEX.test(email)
  }

  validatePhoneNumber = () => {
    const phoneNumber = this.state.formData.phoneNumber.value
    return PHONE_NUMBER_REGEX.test(phoneNumber)
  }

  validateZipCode = () => {
    const zipCode = this.state.formData.zipCode.value
    if (isFleetMaintenanceHubCanada()) return POSTAL_CODE_REGEX.test(zipCode)
    else return ZIP_CODE_REGEX.test(zipCode)
  }

  makeConfirmPasswordEmpty = () => {
    this.setState((state) => ({
      formData: this.getUpdatedFormData(state)
    }))
  }

  getUpdatedFormData = (state) => {
    let updatedFormData = { ...state.formData }
    let updatedConfirmPassword = { ...updatedFormData.confirmPassword }
    updatedConfirmPassword.value = ""
    updatedFormData.confirmPassword = { ...updatedConfirmPassword }
    return updatedFormData
  }

  allowSubmit = () => {
    const { isSubmitting } = this.props
    return !isSubmitting
  }

  handleKeyPress = (event, fieldName) => {
    if (event.key === "Enter") {
      this.onEyeIconClick(fieldName)
    }
  }

  loadSignUpForm = () => {
    const {
      displayError,
      formData,
      checkboxChecked,
      communicationPreferencesInFrench,
      promotionalCheckboxChecked,
      premiumCheckboxChecked
    } = this.state
    const {
      confirmPassword: displayConfirmPasswordError,
      password: displayPasswordError,
      minLengthPassword: displayMinLengthPasswordError,
      carCount: displayCarCountError,
      zipCode: displayZipCodeError,
      firstName: displayFirstNameError,
      lastName: displayLastNameError,
      companyName: displayCompanyNameError,
      phoneNumber: displayPhoneNumberError,
      email: displayEmailError,
      acceptedTermsAndConditions: displayTermsError
    } = displayError
    const {
      firstName: { value: firstName },
      lastName: { value: lastName },
      email: { value: email },
      password: { value: password, passwordInvisible },
      confirmPassword: { value: confirmPassword, passwordInvisible: confirmPasswordInvisible },
      phoneNumber: { value: phoneNumber },
      carCount: { value: carCount },
      acceptedTermsAndConditions: { value: acceptedTermsAndConditions },
      zipCode: { value: zipCode },
      companyName: { value: companyName }
    } = formData
    const { t } = this.props
    const subDomain = getSubdomain()
    const isDomainShellCanada = isFleetMaintenanceHubCanada()
    const inputClass = "placeholder-color-change"
    const shellColor = [FLEET_MAINTENANCE_HUB, FLEET_MAINTENANCE_HUB_CANADA].includes(subDomain)
      ? "#696969"
      : "black"
    const labelColor = [FLEET_MAINTENANCE_HUB, FLEET_MAINTENANCE_HUB_CANADA].includes(subDomain)
      ? "#666666"
      : "black"
    return (
      <>
        <span className={"already-have-customer"}>
          {t("alreadyCustomerLabel?")}
          {
            <button className={"singInLink"} onClick={this.props.navigateToSignIn}>
              {t("signInLabel")}
            </button>
          }
        </span>
        <div
          className={"sign-in-form"}
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            marginTop: "10px"
          }}
        >
          <div className={"signup-fields"}>
            <label style={{ color: `${labelColor}` }} className={"field-labels"}>
              {t("enterCompanyNameLabel")}*
            </label>
            <TextField
              className={`sign-in-input width ${inputClass} padding-0`}
              onChange={this.onCompanyName}
              placeholder={t("enterCompanyNameLabel")}
              value={companyName}
              error={displayCompanyNameError}
            />
            <ErrorField
              displayError={displayCompanyNameError}
              className={"error-field"}
              label={t("requiredFieldLabel")}
            />

            <label style={{ color: `${labelColor}` }} className={"field-labels"}>
              {t("carCountLabel")}*
            </label>
            <TextField
              className={`sign-in-input width ${inputClass} padding-0`}
              onChange={this.onCarCount}
              placeholder={t("enterCarCountLabel")}
              value={carCount}
              error={displayCarCountError}
            />
            <ErrorField
              displayError={displayCarCountError}
              className={"error-field"}
              label={t("carCountValidationLabel")}
            />

            <label style={{ color: `${labelColor}` }} className={"field-labels"}>
              {t("firstNameLabel")}*
            </label>
            <TextField
              className={`sign-in-input width ${inputClass} padding-0`}
              onChange={this.onFirstName}
              placeholder={t("firstNameLabel")}
              value={firstName}
              error={displayFirstNameError}
            />
            <label style={{ color: `${labelColor}` }} className={"field-labels"}>
              {t("lastNameLabel")}*
            </label>
            <TextField
              className={`sign-in-input width ${inputClass} padding-0`}
              onChange={this.onLastName}
              placeholder={t("lastNameLabel")}
              value={lastName}
              error={displayLastNameError}
            />
            <ErrorField
              displayError={displayFirstNameError || displayLastNameError}
              className={"error-field"}
              label={t("nameValidationLabel")}
            />
            <label style={{ color: `${labelColor}` }} className={"field-labels"}>
              {t("emailLabel")}*
            </label>
            <TextField
              className={`sign-in-input width ${inputClass} padding-0`}
              onChange={this.onEmail}
              placeholder={t("emailPlaceholderLabel")}
              value={email}
              error={displayEmailError}
            />
            <ErrorField
              displayError={displayEmailError}
              className={"error-field"}
              label={t("emailValidateLabel")}
            />

            <label style={{ color: `${labelColor}` }} className={"field-labels"}>
              {t("phoneNumberLabel")}*
            </label>
            <TextField
              type={"tel"}
              className={`sign-in-input width ${inputClass} padding-0`}
              onChange={this.onPhoneNumber}
              placeholder={`${t("phoneNumberLabel")}`}
              value={phoneNumber}
              error={displayPhoneNumberError}
            />
            <ErrorField
              displayError={displayPhoneNumberError}
              className={"error-field"}
              label={t("phoneValidateLabel")}
            />

            <label style={{ color: `${labelColor}` }} className={"field-labels"}>
              {isDomainShellCanada ? t("codePostalLabel") : t("zipCodeLabel")}*
            </label>
            <TextField
              className={`sign-in-input width ${inputClass} padding-0`}
              onChange={this.onZipCode}
              placeholder={`${
                isDomainShellCanada ? `${t("codePostalLabel")}` : `${t("zipCodeLabel")}`
              }`}
              value={zipCode}
              error={displayZipCodeError}
            />
            <ErrorField
              displayError={displayZipCodeError}
              className={"error-field"}
              label={
                isDomainShellCanada ? t("postalCodeValidationLabel") : t("zipCodeValidationLabel")
              }
            />

            <label style={{ color: `${labelColor}` }} className={"field-labels"}>
              {t("passwordLabel")}*
            </label>
            <PasswordField
              className={`sign-in-input width ${inputClass} padding-0`}
              onChange={this.onPassword}
              placeholder={t("passwordPlaceholderLabel")}
              value={password}
              obfuscated={passwordInvisible}
              onIconClick={() => this.onEyeIconClick("password")}
              error={displayPasswordError || displayMinLengthPasswordError}
              onKeyPress={(event) => this.handleKeyPress(event, "password")}
            />
            <ErrorField
              displayError={displayMinLengthPasswordError}
              className={"error-field"}
              label={t("passwordValidationLabel")}
            />

            <label style={{ color: `${labelColor}` }} className={"field-labels"}>
              {t("confirmPasswordLabel")}*
            </label>
            <PasswordField
              className={`sign-in-input width ${inputClass} padding-0`}
              onChange={this.onConfirmPassword}
              placeholder={t("confirmPasswordPlaceholderLabel")}
              value={confirmPassword}
              error={displayConfirmPasswordError}
              obfuscated={confirmPasswordInvisible}
              onIconClick={() => this.onEyeIconClick("confirmPassword")}
              onKeyPress={(event) => this.handleKeyPress(event, "confirmPassword")}
            />
            <ErrorField
              displayError={displayConfirmPasswordError}
              className={"error-field"}
              label={t("passwordMatchFailLabel")}
            />
          </div>
          {this.state.isFuelmanMastercardFlag && this.state.isFuelmanMastercardFlag != ON ? (
            <React.Fragment>
              <div className={"checkbox-signupbutton signup-payment"}>
                <div>
                  <div className={"terms-and-conditions"}>
                    <Checkbox
                      className={"checkbox t-and-c-checkbox"}
                      onChange={this.onTermsCheckbox}
                      checked={checkboxChecked}
                    />
                    <PrivacyPolicyPage t={t} />
                  </div>
                  {isDomainShellCanada && (
                    <div className={"terms-and-conditions"}>
                      <Checkbox
                        className={"checkbox t-and-c-checkbox"}
                        onChange={this.onPromotionalCheckbox}
                        checked={promotionalCheckboxChecked}
                        style={{ marginRight: "3px" }}
                      ></Checkbox>
                      <div className={"t-and-c-container"} style={{ display: "contents" }}>
                        <p style={{}}>
                          {t("readAcceptTermLabel")}
                          <Link
                            className="privacy-terms-link"
                            to={{
                              pathname: t("promotionalTermsLink")
                            }}
                            target="_blank"
                            aria-label="Terms and Conditions open in new window"
                            style={{
                              textDecoration: "underline",
                              color: "unset",
                              marginRight: "20px"
                            }}
                          >
                            <u>{t("promotionalTermsConditionsLabel")}</u>
                          </Link>
                        </p>
                      </div>
                    </div>
                  )}
                  {isDomainShellCanada && (
                    <div className={"language"} style={{ justifyContent: "flex-start" }}>
                      <Checkbox
                        className={"checkbox"}
                        onChange={this.onPreferencesCheckbox}
                        checked={communicationPreferencesInFrench}
                      />
                      <div className={"language-french"} style={{ width: "510px" }}>
                        <p style={{}}>{t("communicationPreferencesInFrench")}</p>
                      </div>
                    </div>
                  )}
                  {isDomainShellCanada && (
                    <div className="canadian-partners-logo">
                      <ReactSVG src={`images/canadian_partners.svg`} style={{ width: "50%" }} />
                    </div>
                  )}
                </div>
                <div>
                  <Button
                    type="primary"
                    className={"full-length"}
                    label={t("signUpLabel")}
                    onClick={() => this.onSignupButtonClick()}
                  />
                </div>
              </div>
            </React.Fragment>
          ) : (
            ""
          )}
        </div>
      </>
    )
  }

  loadPaymentScreen = () => {
    const { t } = this.props
    return (
      <PaymentMethodScreen
        ref={this.paymentMethodScreen}
        clientToken={this.props.clientToken}
        modalOpen={this.state.modalOpen}
        t={t}
      />
    )
  }

  openModal = async () => {
    await this.setState({ modalOpen: true })
    this.paymentMethodScreen.current.setupBraintree()
  }

  render() {
    let checkValidation =
      Object.keys(this.state.displayError).every((k) => !this.state.displayError[k]) &&
      this.isAllDataEntered()
    const subDomain = getSubdomain()
    const { t } = this.props
    const shellColor = [FLEET_MAINTENANCE_HUB, FLEET_MAINTENANCE_HUB_CANADA].includes(subDomain)
      ? "#696969"
      : "black"

    return (
      <>
        <h1 className={"signup-text"} style={{ color: `${shellColor}` }}>
          {t("signUpLabel")}
        </h1>
        {this.state.isFuelmanMastercardFlag && this.state.isFuelmanMastercardFlag === ON ? (
          <StepperComponent
            step1={this.loadSignUpForm()}
            step2={this.loadPaymentScreen()}
            createBraintreeInstance={this.openModal}
            ref={this.paymentMethodScreen}
            step1_label={t("generalInformationLabel")}
            step2_label={t("paymentDetailsLabel")}
            formError={checkValidation}
            clientToken={this.props.clientToken}
            modalOpen={true}
            t={this.props.t}
            onSubmit={this.onSignupButtonClick}
            userSelectedLanguage={this.props.userSelectedLanguage}
          />
        ) : (
          this.loadSignUpForm()
        )}
      </>
    )
  } //render
} //class SignUpForm

const mapStateToProps = (state) => ({
  clientToken: state.paymentMethods.clientToken,
  currentUser: state.users.currentUser
})

export default connect(mapStateToProps)(withTranslation("common")(SignUpForm))
