export const FMC = "FMC"
export const FMC_FLEET_ADMIN = "FMC Fleet Admin"
export const FMC_FLEET_MANAGER = "FMC Fleet Manager"
export const SUPER_ADMIN = "Super Admin"
export const OWNER = "Owner"
export const DRIVER = "Driver"
export const MANAGER = "Manager"
export const ADMIN = "Admin"
export const ACCOUNT_OWNER = "Account Owner"
export const FLEET_MANAGER = "Fleet Manager"

export const FMC_FR = "FMC"
export const FMC_FLEET_ADMIN_FR = "Administrateur de flotte FMC"
export const FMC_FLEET_MANAGER_FR = "Gestionnaire de flotte FMC"
export const SUPER_ADMIN_FR = "Super administrateur"
export const OWNER_FR = "Propriétaire"
export const DRIVER_FR = "Driver"
export const MANAGER_FR = "Directeur"
export const ADMIN_FR = "Administrateur"
export const ACCOUNT_OWNER_FR = "Propriétaire du compte"
export const FLEET_MANAGER_FR = "Gestionnaire de flotte"
