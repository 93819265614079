import React, { Component } from "react"
import { connect } from "react-redux"
import { Checkbox } from "semantic-ui-react"
import Button from "../../elements/Button"
import TextField from "../../elements/TextField"
import { navigateTo } from "../../helpers/navigationHelpers"

import Alert from "../../components/shared/Alert"
import MultiFactorAuthentication from "../../components/shared/MultiFactorAuthentication"
import NoSessionLayout from "../../layouts/NoSessionLayout"
import SignInForm from "../../components/sessions/SignInForm"
import LoadingThrobber from "../../components/shared/LoadingThrobber"
import {
  FLEET,
  FLEET_MAINTENANCE_HUB,
  FLEET_MAINTENANCE_HUB_CANADA,
  ZUBIE,
  FLEETCOR
} from "../../constants/application"
import { FMC, FMC_FLEET_ADMIN, FMC_FLEET_MANAGER } from "../../constants/roles"
import { getSubdomain } from "../../components/shared/StylesheetInjector"
import { isFleetMaintenanceHubCanada, isFuelman, isNTWTBC } from "../../helpers/affiliationHelpers"
import { setLanguage } from "../../helpers/translationHelpers"
import { EN_LOCALE, FR_LOCALE, CANDA_COUNTRY_CODE } from "../../constants/users"
import { withRouter } from "react-router"
import { withTranslation } from "react-i18next"
import { OTP_REGEX } from "../../utilities/stringHelpers"
import ErrorField from "../../elements/ErrorField"
import { setupSplitFlags, ON } from "../../components/config/SplitClient"
import * as sessionHelpers from "../../helpers/sessionHelpers"
let sub = getSubdomain()

class SessionNew extends Component {
  constructor(props) {
    super(props)

    const locationStateData = this.props.location.state || {}

    this.state = {
      alertMessage: locationStateData.alertMessage || "",
      alertType: locationStateData.alertType || "default",
      isSubmitting: false,
      toggle: false,
      sendOTP: false,
      otpVerifyError: false,
      formData: {}
    }
    this.navigateTo = navigateTo.bind(this)
    this.navigateToForgotPassword = navigateTo.bind(this, "forgotPassword")
    this.navigateToSignUp = navigateTo.bind(this, "signUp")
    this.onFormSubmit = this.onFormSubmit.bind(this)

    const params = window.location.href.match(/key=([\w\d-]+)?&?/)
    const key = (params && params[1]) || locationStateData.key
    const path = locationStateData.path

    this.state.redirectPath = path

    if (key) {
      this.state.isSubmitting = true
      this.onFormSubmit({ key })
    }
  }

  toggleButton = () => {
    this.setState((prevState) => ({ toggle: !prevState.toggle }))
    const preferences = this.state.toggle
      ? { language: EN_LOCALE, country: CANDA_COUNTRY_CODE }
      : { language: FR_LOCALE, country: CANDA_COUNTRY_CODE }
    setLanguage(preferences["language"], preferences["country"])
    this.props.dispatch({ type: "SET_USER_LANGUAGE", payload: { language: preferences.language } })
  }

  async componentDidMount() {
    await setupSplitFlags.bind(this)({ sub_domain: sub })
    const { dispatch, userSelectedLanguage } = this.props
    const url = new URL(window.location.href)
    const params = new URLSearchParams(url.search)
    const message = params.get("error_message")

    if (message) this.setState({ alertMessage: message, alertType: "error" })
    if (userSelectedLanguage === FR_LOCALE) {
      dispatch({ type: "SET_USER_LANGUAGE", payload: { language: FR_LOCALE } })
      setLanguage(FR_LOCALE, CANDA_COUNTRY_CODE)
      this.setState((prevState) => ({ toggle: true }))
    } else {
      dispatch({ type: "SET_USER_LANGUAGE", payload: { language: EN_LOCALE } })
      setLanguage(EN_LOCALE, CANDA_COUNTRY_CODE)
      this.setState((prevState) => ({ toggle: false }))
    }
  }

  async afterFormSubmit(status, data) {
    const { dispatch, userSelectedLanguage, match, vehicle } = this.props
    this.setState({ isSubmitting: false })

    if (status === "success") {
      this.onFormSubmitSuccess(data)
      await dispatch({
        type: "USER_CREATE_OR_UPDATE_SAGA",
        payload: { ...data, ...{ language: userSelectedLanguage } },
        callback: async () => {
          if (match.path === "/vehicles/:id") {
            await dispatch({
              type: "NEXT_MAINTENANCE_INTERVAL_SAGA",
              payload: { vehicleIds: [vehicle.id] }
            })
          }
        }
      })
    } else {
      this.onFormSubmitFailure(data)
    }
  }

  onSendOTP = (formData) => {
    this.setState({ sendOTP: true, formData: formData })
  }

  onFormSubmit(formData, otp = null) {
    const { dispatch, userSelectedLanguage } = this.props
    const { isMultifactorAuthenticationFlag } = this.state
    if (Object.keys(formData).length === 0) {
      formData["email"] = sessionHelpers.getApplicationCookie("current_user_email")
      formData["password"] = sessionHelpers.getApplicationCookie("current_user_password")
    }
    let split_flag =
      sessionHelpers.getApplicationCookie("remember_me_cookie") != formData.email &&
      isMultifactorAuthenticationFlag &&
      isMultifactorAuthenticationFlag == ON
        ? true
        : false
    this.setState({ isSubmitting: true, otpVerifyError: true })
    dispatch({
      type: "SIGN_IN_SAGA",
      payload: {
        formData: formData,
        t: this.props.t,
        subdomain: getSubdomain(),
        otp: otp,
        is_split_flag: split_flag,
        language: userSelectedLanguage
      },
      callback: this.afterFormSubmit.bind(this)
    })
  }

  onFormSubmitFailure(data) {
    this.setState({
      alertMessage: data.alertMessage || "",
      alertType: data.alertType
    })
  }

  onFormSubmitSuccess(data) {
    if (this.state.redirectPath) this.props.history.push({ pathname: this.state.redirectPath })
    else if ([FMC, FMC_FLEET_ADMIN, FMC_FLEET_MANAGER].some((role) => data.roles.includes(role))) {
      if (this.props.isFleetMode)
        this.props.dispatch({
          type: "FMC_FLIP_SAGA",
          callback: null
        })
      this.navigateTo("fmcDashboard")
    } else {
      if (!this.props.isFleetMode)
        this.props.dispatch({
          type: "FMC_FLIP_SAGA",
          callback: null
        })
      this.navigateTo("dashboard")
    }
  }

  setError = (data, status, resend = false) => {
    if (!resend) {
      if (data) {
        this.setState({ sendOTP: false })
        this.setState({
          alertMessage: data.alertMessage || "",
          alertType: data.alertType
        })
      } else if (status == "success") {
        this.setState({ alertMessage: "OTP sent successfully", alertType: "success" })
      } else {
        this.setState({ alertMessage: "" })
      }
    } else if (resend && status == "success") {
      this.setState({ alertMessage: "OTP resend successfully", alertType: "success" })
    }
  }

  render() {
    const {
      alertMessage,
      alertType,
      isSubmitting,
      toggle,
      sendOTP,
      formData,
      otpVerifyError,
      isMultifactorAuthenticationFlag,
      isFleetCaptchaFlag
    } = this.state
    const { t } = this.props
    const subDomain = getSubdomain()
    return (
      <NoSessionLayout page="signinPage">
        <div className={"no-session-content"}>
          {isFleetMaintenanceHubCanada() && (
            <div className={"sign-in-submit"}>
              EN &nbsp;
              <Checkbox toggle checked={toggle} onClick={this.toggleButton} />
              &nbsp; FR
            </div>
          )}
          <Alert
            message={t(`common:${alertMessage}`)}
            type={alertType}
            visible={alertMessage !== ""}
          />

          <LoadingThrobber visible={isSubmitting} />

          {!isSubmitting && (
            <>
              {isMultifactorAuthenticationFlag &&
              isMultifactorAuthenticationFlag === ON &&
              sendOTP ? (
                <>
                  <MultiFactorAuthentication
                    sendOTP={sendOTP}
                    otpVerifyError={otpVerifyError}
                    formData={formData}
                    onFormSubmit={this.onFormSubmit}
                    setError={this.setError}
                  />{" "}
                </>
              ) : (
                <>
                  <SignInForm
                    isSubmitting={isSubmitting}
                    onSubmit={this.onFormSubmit}
                    onSendOTP={this.onSendOTP}
                    mfa={isMultifactorAuthenticationFlag && isMultifactorAuthenticationFlag === ON}
                    navigateToForgotPassword={this.navigateToForgotPassword}
                    isFleetCaptchaFlag={isFleetCaptchaFlag === ON}
                  />
                  {subDomain != FLEET_MAINTENANCE_HUB &&
                  subDomain != ZUBIE &&
                  subDomain != FLEETCOR ? (
                    <Alert message={""} type="notice" visible={true /* No sign up page yet.*/}>
                      <span>
                        {subDomain === FLEET_MAINTENANCE_HUB_CANADA
                          ? t("newShellCanadaLabel")
                          : isFuelman()
                          ? t("newFuelmanLabel")
                          : isNTWTBC()
                          ? t("newNtwTbcLabel")
                          : t("newFleetAdviseLabel")}
                        {
                          <button className={"link"} onClick={this.navigateToSignUp}>
                            {t("signUpLabel")}
                          </button>
                        }
                      </span>
                    </Alert>
                  ) : (
                    ""
                  )}
                </>
              )}
            </>
          )}
        </div>
      </NoSessionLayout>
    )
  }
} // class SessionNew
const mapStateToProps = (state) => ({
  isFleetMode: state.application.mode === FLEET || state.application.mode === undefined,
  userSelectedLanguage: state.users ? state.users.userSelectedLanguage : "",
  vehicle: state.vehicles.vehicle,
  currentUser: state.users.currentUser
})
export default connect(mapStateToProps)(withRouter(withTranslation("common")(SessionNew)))
