import React, { Component } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"

import ApplicationLayout from "../../layouts/ApplicationLayout"
import LoadingError from "../../components/shared/LoadingError"
import LoadingThrobber from "../../components/shared/LoadingThrobber"
import UserForm from "../../components/users/UserForm"
import { navigationPaths } from "../../constants/paths"
import { Loader, Grid } from "semantic-ui-react"

class UserPreferences extends Component {
  static propTypes = {
    isLoading: PropTypes.bool.isRequired,
    isLoadingError: PropTypes.bool.isRequired,
    currentUser: PropTypes.object.isRequired
  }

  componentDidMount() {
    // this.loadUser()
  }

  loadUser = async () => {
    await this.props.dispatch({
      type: "CURRENT_USER_LOAD_SAGA"
    })
  }

  shouldRenderContent() {
    const { isLoading, isLoadingError } = this.props

    return !isLoading && !isLoadingError
  }

  onSave = () => {
    this.props.history.push({
      pathname: navigationPaths.dashboard()
    })
  }

  renderContent() {
    const { isLoading, currentUser } = this.props
    if (isLoading) return <Loader />

    if (this.shouldRenderContent()) {
      return (
        <Grid centered>
          <Grid.Column largeScreen={8} mobile={16} tablet={16} computer={8}>
            <UserForm user={currentUser} completionCallback={this.onSave} />
          </Grid.Column>
        </Grid>
      )
    } else {
      return null
    }
  }

  render() {
    const { isLoading, isLoadingError } = this.props

    return (
      <ApplicationLayout>
        <LoadingThrobber visible={isLoading} />

        <LoadingError visible={!isLoading && isLoadingError} />

        {this.renderContent()}
      </ApplicationLayout>
    )
  }
} // class UserPreferences

const mapStateToProps = (state) => ({
  isLoading: state.application.isLoading,
  isLoadingError: state.application.isLoadingError,
  currentUser: state.application.userSessionData || state.users.currentUser
})

export default connect(mapStateToProps)(UserPreferences)
