import React, { Component } from "react"
import PropTypes from "prop-types"

import * as maintenanceHelpers from "../../helpers/maintenanceHelpers"
import { Segment, Grid } from "semantic-ui-react"
import * as numberHelpers from "../../helpers/numberHelpers"
import { withTranslation } from "react-i18next"

class Stats extends Component {
  static propTypes = {
    fleets: PropTypes.array.isRequired
  }

  formattedTotalFleets() {
    // return this.props.fleets.length
    return this.props.currentUser.total_fleet_number
  }

  formattedTotalVehicles() {
    // return this.props.fleets.reduce((total, fleet) => (total += fleet.vehicles_total), 0)
    return this.props.currentUser.total_vehicles
  }

  formattedTotalSpend() {
    let totalSpend = this.props.currentUser.total_maintenance_cost
    // for (let fleet of this.props.fleets) {
    //   if (fleet.total_maintenance_cost != null)
    //     totalSpend += parseFloat(fleet.total_maintenance_cost)
    // }

    if (totalSpend > 0) {
      return numberHelpers.formattedPrice(totalSpend)
    } else {
      return "-"
    }
  }

  render() {
    const { t } = this.props
    return (
      <Grid columns="equal" stretched stackable>
        <Grid.Row>
          <Grid.Column>
            <Segment vertical className={"dash-stats-item"}>
              {this.formattedTotalFleets()}
              <span>{t("totalFleetsLabel")}</span>
            </Segment>
          </Grid.Column>
          <Grid.Column>
            <Segment vertical className={"dash-stats-item"}>
              {this.formattedTotalVehicles()}
              <span>{t("totalVehiclesLabel")}</span>
            </Segment>
          </Grid.Column>
          <Grid.Column>
            <Segment vertical className={"dash-stats-item"}>
              {this.formattedTotalSpend()}
              <span>{t("totalSpendLabel")}</span>
            </Segment>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    )
  }
} // class Stats

export default withTranslation("fmcDashboard")(Stats)
