import React, { Component } from "react"
import PropTypes from "prop-types"
import { sortBy } from "lodash"
import UserListItem from "./UserListItem"
import { Table, Button, Input, Grid } from "semantic-ui-react"
import UserModal from "./UserModal"
import debounce from "debounce"
import * as vehicleHelpers from "../../helpers/vehicleHelpers"
import * as arrayHelpers from "../../helpers/arrayHelpers"
import { isOwnerManagerOrAdmin } from "../../helpers/activeMaintenanceHelpers"
import InfoLabel from "../shared/InfoLabel"
import { withTranslation } from "react-i18next"
import { withRouter } from "react-router-dom"
import { ON } from "../config/SplitClient"

class UserList extends Component {
  static propTypes = {
    users: PropTypes.array.isRequired,
    loadUsers: PropTypes.func.isRequired
  }

  constructor(props) {
    super(props)
    this.debouncedSetQuickFilterText = debounce(this.setQuickFilterText, 300)
  }

  state = {}

  setQuickFilterText = (event) => {
    const escapedText = event.target.value.match(/[^\\]+/)
    this.setState({ quickFilterText: (escapedText || [])[0] })
  }

  filteredUsers = (users) => {
    const { quickFilterText } = this.state
    const regexp = new RegExp(quickFilterText, "i")
    if (quickFilterText)
      return arrayHelpers.uniq(
        users
          .filter((u) =>
            Object.keys(u).find((key) => u[key] && u[key].match && u[key].match(regexp))
          )
          .concat(
            users.filter((u) =>
              u.vehicles
                .filter((v) => v.fleetcor_enrolled == true)
                .find(
                  (v) =>
                    vehicleHelpers.formattedName(v) && vehicleHelpers.formattedName(v).match(regexp)
                )
            )
          )
          .concat(
            users.filter((u) => {
              return u.roles.find((r) => (r.name ? r.name.match(regexp) : r.match(regexp)))
            })
          )
      )
    else return users
  }

  renderListItems() {
    const {
      users,
      loadUsers,
      onDelete,
      onDeactivate,
      currentUser,
      isFMC,
      userRoles,
      states,
      isGloveboxFeatureFlag
    } = this.props

    return sortBy(this.filteredUsers(users), "email").map((user, index) => {
      return (
        <UserListItem
          key={index}
          user={user}
          loadUsers={loadUsers}
          onDelete={onDelete}
          onDeactivate={onDeactivate}
          currentUser={currentUser}
          isFMC={isFMC}
          userRoles={userRoles}
          states={states}
          isGloveboxFeatureFlag={isGloveboxFeatureFlag}
        />
      )
    })
  }

  handleDownloadClick = async () => {
    const { dispatch, match, language } = this.props
    if (match.url.includes("/fmc_users")) {
      await dispatch({
        type: "FMC_USERS_LOAD_SAGA",
        payload: {
          download: true,
          language: language
        }
      })
    } else {
      await dispatch({
        type: "USERS_LOAD_SAGA",
        payload: {
          download: true,
          language: language
        }
      })
    }
  }

  render() {
    const { loadUsers, user, currentUser, isFMC, t, users = [], isGloveboxFeatureFlag } = this.props
    const fleetsLabel = t("fleetsLabel")
    // FMC user list doesn't list Vehicles but instead Fleets which the user can approve orders for
    const headers = [
      t("nameLabel"),
      t("rolesLabel"),
      t("emailLabel"),
      t("phoneLabel"),
      isFMC ? fleetsLabel : t("common:vehiclesLabel")
    ]
    const gloveboxHeaders = [
      !isFMC && t("driverLicenseLable"),
      !isFMC && t("vehicleInsuranceLable"),
      !isFMC && t("registrationLable"),
      !isFMC && t("extraDocumentsLable")
    ]

    return (
      <React.Fragment>
        <Grid columns={2}>
          <Grid.Column floated="left" mobile={5}>
            <Input
              className="search"
              icon="search"
              onChange={this.setQuickFilterText}
              placeholder={t("searchUsersLabel")}
            />
          </Grid.Column>
          <Grid.Column floated="right" mobile={8} tablet={8} computer={6}>
            {isOwnerManagerOrAdmin(currentUser) && (
              <UserModal
                completionCallback={loadUsers}
                user={user}
                isFMC={isFMC}
                currentUser={currentUser}
              >
                <Button tabIndex="1" floated="right" size="tiny">
                  {t("addNewUserLabel")}
                </Button>
              </UserModal>
            )}
            <Button
              tabIndex="0"
              floated="right"
              size="tiny"
              onClick={this.handleDownloadClick}
              disabled={users.length === 0}
            >
              {t("services:downloadLabel")}
            </Button>
          </Grid.Column>
        </Grid>
        <Table className="borderless">
          <Table.Header>
            <Table.Row>
              {headers.map((header) => (
                <Table.HeaderCell singleLine>
                  {header}
                  {header === fleetsLabel && (
                    <InfoLabel
                      field={{
                        helpModal: <div>{t("userDescLabel")}</div>
                      }}
                    />
                  )}
                </Table.HeaderCell>
              ))}
              {gloveboxHeaders.map(
                (gloveboxHeader) =>
                  isGloveboxFeatureFlag === ON && (
                    <Table.HeaderCell textAlign="center" width={1}>
                      {gloveboxHeader}
                    </Table.HeaderCell>
                  )
              )}
              <Table.HeaderCell>{t("actionLablel")}</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>{this.renderListItems()}</Table.Body>
        </Table>
      </React.Fragment>
    )
  }
} // class UserList

export default withRouter(withTranslation("fmcUsers")(UserList))
