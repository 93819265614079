import React, { Component } from "react"
import PropTypes from "prop-types"

import Button from "../../elements/Button"
import TextField from "../../elements/TextField"
import { getSubdomain } from "../shared/StylesheetInjector"
import ReCAPTCHA from "react-google-recaptcha"
const recaptchaRef = React.createRef()

class ChangePasswordForm extends Component {
  static propTypes = {
    onSubmit: PropTypes.func.isRequired,

    isSubmitting: PropTypes.bool
  }

  static defaultProps = {
    isSubmitting: false
  }

  state = {
    formData: {
      email: ""
    },
    isRecaptchaChecked: false
  }

  allowSubmit() {
    const { isSubmitting } = this.props

    const { email } = this.state.formData

    return !isSubmitting && email.length > 0
  }

  onFieldChange(field, value) {
    let updatedFormData = { ...this.state.formData }
    updatedFormData[field] = value
    this.setState({ formData: updatedFormData })
  }

  changedRecaptcha(val) {
    if (val.length > 0) {
      this.props.setState({ isRecaptchaChecked: true })
    } else {
      this.props.setState({ isRecaptchaChecked: false })
    }
  }

  onKeyDown(event) {
    const { onSubmit } = this.props

    if (event.key === "Enter") {
      onSubmit(this.state)
    }
  }

  onClick = (t) => {
    const { isRecaptchaChecked, formData } = this.state
    const { onSubmit, isFleetCaptchaFlag } = this.props
    if (isRecaptchaChecked == false && isFleetCaptchaFlag) {
      alert(t("common:recaptchaCheck"))
      return false
    } else {
      onSubmit(formData)
    }
  }

  render() {
    const { onSubmit, t, language, isFleetCaptchaFlag } = this.props
    const { email } = this.state.formData

    const subDomain = getSubdomain()

    return (
      <div className={"forgot_password_form"} onKeyDown={(event) => this.onKeyDown(event)}>
        <div className={"row"}>
          <div className={"column--12"}>
            <TextField
              className={"text_field"}
              onChange={(event) => this.onFieldChange("email", event.target.value)}
              placeholder={t("emailLabel")}
              value={email}
            />
          </div>
        </div>
        {isFleetCaptchaFlag && (
          <ReCAPTCHA
            ref={recaptchaRef}
            className="g-recaptcha"
            sitekey={process.env.REACT_APP_CAPTCHA_SITE_KEY}
            onChange={this.changedRecaptcha}
            hl={language}
            style={{ padding: "1em" }}
            props={this}
          />
        )}
        <div>
          <div className={"column--12"}>
            <Button
              disabled={subDomain !== "shell" && !this.allowSubmit()}
              className={
                !this.allowSubmit() && subDomain === "shell"
                  ? "disabled-button-shell button--primary forgot_password_form__button"
                  : (this.allowSubmit() && subDomain === "shell") || subDomain !== "shell"
                  ? "button--primary forgot_password_form__button"
                  : ""
              }
              disabled={!this.allowSubmit()}
              label={t("resetPasswordLabel")}
              onClick={() => this.onClick(t)}
            />
          </div>
        </div>
      </div>
    )
  }
} // class ChangePasswordForm

export default ChangePasswordForm
