import { isEmpty } from "lodash"

export function agGridColumnDefs(defaultCellRenderer, t) {
  const widths = {
    verySmall: 125,
    small: 150,
    medium: 175,
    large: 200,
    veryLarge: 225,
    xxLarge: 300,
    xxxLarge: 400
  }

  return [
    {
      headerName: t("serviceNameLabel"),
      field: "name",
      width: widths.xxxLarge,
      cellRendererFramework: defaultCellRenderer
    },
    {
      headerName: t("serviceIntervalLabel"),
      field: "interval",
      width: widths.xxLarge,
      cellRendererFramework: defaultCellRenderer
    }
  ]
}

export function agGridRowData(vehicleServices, vehicleId) {
  const serviceSchedule = []
  const serviceIntervals = !isEmpty(vehicleServices) ? Object.keys(vehicleServices) : []
  if (serviceIntervals.length && vehicleServices) {
    serviceIntervals.forEach((interval) => {
      if (vehicleServices[interval][vehicleId] && vehicleServices[interval][vehicleId].length) {
        vehicleServices[interval][vehicleId].forEach((service) => {
          serviceSchedule.push(service)
        })
      }
    })
    return (serviceSchedule || []).map((service) => {
      return {
        name: service.name,
        interval: service.interval
      }
    })
  } else {
    return []
  }
}
