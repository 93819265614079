import React from "react"
import { Accordion, Divider, Grid, Header, Icon, Image, Loader } from "semantic-ui-react"
import { connect } from "react-redux"
import { withTranslation } from "react-i18next"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faFolder } from "@fortawesome/free-solid-svg-icons"
import PageTitle from "./components/PageTitle"

class MaintenanceDetails extends React.Component {
  state = { activeIndex: -1, carAdviceServices: [], loading: false }
  handleClick = (_, titleProps) => {
    const { index } = titleProps
    const { activeIndex } = this.state
    const newIndex = activeIndex === index ? -1 : index

    this.setState({ activeIndex: newIndex })
  }

  componentDidMount() {
    window.addEventListener("resize", this.handleResize)
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleResize)
  }

  handleResize = () => {
    const windowInnerWidth = window.innerWidth
    const windowOuterWidth = window.outerWidth
    this.props.handleResize(windowInnerWidth, windowOuterWidth)
  }

  async getCarAdviseRecommendedServices(data) {
    await this.props.dispatch({
      type: "GET_CARADVISE_RECOMMENDED_SERVICES",
      payload: { data },
      callback: this.afterReceivedRecommended
    })
  }
  // const carAdviseRecommendedResponse = this.props.fetchCarAdviseRecemendedServices(data)

  afterReceivedRecommended = (status, data) => {
    if (status == "success" && Array.isArray(data)) {
      this.setState({
        carAdviceServices: data,
        loading: false
      })
    } else {
      this.setState({ loading: false, carAdviceServices: [] })
    }
  }
  render() {
    const { activeIndex, loading, carAdviceServices } = this.state
    const { vehicle, t, showMaintenanceHistory, showMaintenanceHistoryScreen } = this.props
    const mm = (vehicle && vehicle.make) + " " + vehicle.model
    const vehicleYear = (vehicle && vehicle.year) || ""
    const isCompleted =
      vehicle &&
      vehicle.oemServices &&
      vehicle.oemServices.find((item) => {
        if (item.is_completed) {
          return {
            ...item
          }
        }
      })
    return (
      <>
        <Grid.Row>
          <PageTitle
            showBack={showMaintenanceHistory}
            onBackClick={(e) => showMaintenanceHistoryScreen(e, null)}
            vehicle={vehicle}
            t={t}
            goToHistory={showMaintenanceHistoryScreen}
            showButtons={false}
          />
        </Grid.Row>
        <Grid.Column mobile={4} tablet={8} computer={7}>
          <div>
            {vehicle &&
              vehicle.oemServices &&
              vehicle.oemServices.map((item, i) => {
                if (item.is_completed) {
                  return (
                    <div
                      style={{
                        padding: "6px",
                        position: "relative"
                      }}
                    >
                      <Grid.Row>
                        <Accordion
                          fluid
                          styled
                          onClick={async () => {
                            const data = {
                              vehicleId: vehicle.id,
                              interval: item.interval,
                              interval_id: item.interval_id
                            }
                            this.setState({ loading: true })
                            this.getCarAdviseRecommendedServices(data)
                          }}
                        >
                          <Accordion.Title
                            active={activeIndex === i}
                            index={i}
                            onClick={this.handleClick}
                          >
                            <Icon
                              name="angle right"
                              style={{
                                position: "absolute",
                                right: 30,
                                color: "black",
                                top: 30
                              }}
                            />
                            <div className="color">
                              {t("mileage")}:{item.interval} mi
                            </div>

                            <div style={{ marginTop: "6px" }}>
                              <Image src="../../../../images/completedIcon.png" size={20} />
                            </div>
                          </Accordion.Title>
                          <Accordion.Content active={activeIndex === i}>
                            <div>
                              {item.services.length ? (
                                item.services.map((item) => {
                                  return (
                                    <>
                                      <Divider fitted />
                                      <ul className="color">{item.service_name}</ul>
                                      <Divider fitted hidden />
                                    </>
                                  )
                                })
                              ) : (
                                <ul className="color">{t("noRecordFound")}</ul>
                              )}
                              {carAdviceServices &&
                                carAdviceServices.length > 0 &&
                                carAdviceServices.map((item) => {
                                  return (
                                    <>
                                      <Divider fitted />
                                      <ul className="color">{item.name}</ul>
                                      <Divider fitted hidden />
                                    </>
                                  )
                                })}
                              <div style={{ textAlign: "center" }}>
                                {loading ? <Loader active={loading} /> : null}
                              </div>
                            </div>
                          </Accordion.Content>
                        </Accordion>
                      </Grid.Row>
                    </div>
                  )
                }
              })}
            {!isCompleted ? (
              <div
                style={{
                  padding: "15px",
                  display: "flex",
                  textAlign: "center",
                  height: "40px",
                  alignItems: "center",
                  fontSize: "18px",
                  backgroundColor: "white"
                }}
              >
                <div>
                  <div
                    style={{
                      display: "inline",
                      margin: "10px",
                      // background: mileStatusBackground,
                      borderRadius: "42px"
                    }}
                  >
                    <FontAwesomeIcon icon={faFolder} />
                    <span style={{ marginLeft: "6px" }}>{t("noRecordFound")}</span>
                  </div>
                </div>
              </div>
            ) : (
              ""
            )}
          </div>
        </Grid.Column>
      </>
    )
  }
}
function mapStateToProps(state, props) {
  let vehicle = state.vehicles.vehicle || {}

  return {
    vehicle: vehicle || {}
  }
}
export default connect(mapStateToProps)(
  withTranslation("maintenaceHistoryDetails")(MaintenanceDetails)
)
